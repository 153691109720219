import { getVehicles } from "apis";
import { Button, DataTable, Intro, Pill, PopOverMenu } from "components/commons";
import { FleetContext, AnalyticsContext, UserContext } from "contexts";
import { Path, VehicleStatus, FleetStatus, Page, UserAccess, Role } from "enums";
import { useApi, useFilter, useModal, useMount } from "hooks";
import React, { useCallback, useContext, useMemo } from "react";
import { prettifyVehicleStatus } from "utils";
import { vehicleColumns } from "./vehicle-columns";
import VehicleFilter from "./vehicle-filter";
import { vehicleFilterState } from "./vehicle-filter.state";
import styles from "./vehicle.module.scss";
import VehicleForm from "./vehicle-form";
import VehicleStatusModal from "./vehicle-status-modal";
import locale from "localization";
import { useHistory } from "react-router-dom";
import ImportVehicleModal from "./import-vehicle";
import { importVehicle } from "apis/vehicle.api";

const VehicleModule = () => {
  const { page } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { userAccess } = useContext(UserContext);
  const { fleetId, status } = fleet;
  const deactivated = status === FleetStatus.Deactivated;
  const statusModal = useModal();
  const history = useHistory();
  const importVehicleModal = useModal();

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.Vehicle;
  });

  const { modifyFilter, modifyFilters, filterState, requestState, submitFilter } = useFilter(
    vehicleFilterState(fleetId)
  );
  const vehicleFormModal = useModal();

  const importRequest = useApi({
    api: importVehicle,
  });

  const {
    request: getVehiclesRequest,
    loading: fetchingVehicles,
    result: getVehiclesResult = { vehicles: [], count: 0 },
  } = useApi({
    api: getVehicles,
    params: {
      fleetId: fleetId,
    },
    pageError: true,
  });

  useMount(() => {
    fetchVehicles(requestState);
    page({
      name: Page.ViewManageFleetVehicles,
    });
  });

  const fetchVehicles = useCallback(
    async (rs) => {
      await getVehiclesRequest(submitFilter({ ...rs }));
    },
    [getVehiclesRequest, submitFilter]
  );

  const changeStatusCb = useCallback(
    (vehicleId, plateNumber, status) => {
      statusModal.show({ status, vehicleId, plateNumber });
    },
    [statusModal]
  );

  const preparedVehiclesData = useMemo(() => {
    const { vehicles } = getVehiclesResult;

    if (vehicles.length > 0) {
      const preparedData = vehicles.map((v) => {
        const map = new Map();
        map.set("plateNumber", v.plateNumber);
        map.set("model", v.model);
        map.set("remarks", v.remarks);
        map.set(
          "status",
          <Pill
            grass={v.status === VehicleStatus.Active}
            cheddar={v.status === VehicleStatus.Inactive}
          >
            {prettifyVehicleStatus(v.status)}
          </Pill>
        );
        let viewRedemption;
        let editDetails;
        let deactivateUser;
        let options = [];
        if (getUserAccess?.fullAccess || getUserAccess?.viewAccess) {
          if (getUserAccess?.role !== Role.SeaoilAccountManager) {
            viewRedemption = {
              content: locale.viewRedemptions,
              onClick: () => {
                history.push(Path.Redemption, { searchKey: v.plateNumber });
              },
            };

            options.push(viewRedemption);
          }
        }

        if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
          editDetails = {
            content: locale.editDetails,
            onClick: () => {
              vehicleFormModal.show({
                title: locale.editDetails,
                actionText: locale.save,
                create: false,
                status: v.status,
                vehicleId: v.vehicleId,
                initialState: {
                  plateNumber: {
                    value: v.plateNumber,
                    disabled: true,
                  },
                  model: {
                    value: v.model,
                  },
                  remarks: {
                    value: v.remarks,
                  },
                },
              });
            },
            disabled: deactivated,
          };

          options.push(editDetails);
        }

        if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
          deactivateUser = {
            content: v.status === VehicleStatus.Active ? locale.deactivate : locale.activate,
            onClick: () => {
              changeStatusCb(v.vehicleId, v.plateNumber, v.status);
            },
            disabled: deactivated,
          };

          options.push(deactivateUser);
        }

        map.set("options", <PopOverMenu options={options} />);
        return map;
      });
      return preparedData;
    }
    return [];
  }, [getVehiclesResult, changeStatusCb, vehicleFormModal, history, deactivated, getUserAccess]);

  const onStatusChangeCb = useCallback(
    (status) => {
      const { requestState } = modifyFilters({ status, page: 1 });
      getVehiclesRequest({ ...requestState });
    },
    [getVehiclesRequest, modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      fetchVehicles(requestState);
    },
    [fetchVehicles, modifyFilters]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchVehicles(requestState);
    },
    [fetchVehicles, modifyFilters]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchVehicles(requestState);
    },
    [fetchVehicles, modifyFilters]
  );

  return (
    <div>
      <VehicleForm
        {...vehicleFormModal}
        refreshPage={() => {
          getVehiclesRequest(requestState);
        }}
      />
      <div>
        <Intro
          title={locale.manageFleetVehicles}
          subtitle={locale.manageYourVehicle}
          actionText={
            getUserAccess?.fullAccess || getUserAccess?.modifyAccess ? locale.addVehicles : ""
          }
          actionOnClick={() => {
            vehicleFormModal.show({
              title: locale.addVehicles,
              actionText: locale.add,
              create: true,
            });
          }}
          actionDisabled={deactivated}
        />
      </div>
      <div className={styles.filters}>
        <div>
          <VehicleFilter
            filterState={filterState}
            onStatusChange={onStatusChangeCb}
            onSearchChange={modifyFilter}
            onSearch={onSearchCb}
          />
        </div>
        <div>
          {(getUserAccess?.fullAccess || getUserAccess?.modifyAccess) && (
            <Button
              exportButton
              onClick={() => {
                importVehicleModal.show({});
              }}
            >
              {locale.import}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingVehicles}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={vehicleColumns}
          data={preparedVehiclesData}
          dataCount={getVehiclesResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
      <ImportVehicleModal
        {...importVehicleModal}
        request={importRequest}
        refresh={() => {
          fetchVehicles({
            ...requestState,
            page: 1,
          });
        }}
      />
      <VehicleStatusModal refetchVehicles={() => fetchVehicles(requestState)} {...statusModal} />
    </div>
  );
};

export default VehicleModule;
