import { ApiPath } from "enums";
import { Get } from "utils";

export const getStations = async ({ fleetId, ...query }) => {
  let url = `${ApiPath.Station}`;
  if (fleetId) {
    url = `${ApiPath.GetFleet}/${fleetId}/station`;
  }
  const res = await Get(url, query);
  return res.data;
};
