import locale from "localization";

export const fuelHistoryColumns = [
  { key: "buyFuelId", text: locale.buyFuelId, width: "10%" },
  { key: "purchasedDate", text: locale.purchasedDate, width: "15%" },
  { key: "product", text: locale.product, width: "10%" },
  { key: "creditPrice", text: locale.creditPrice, width: "10%" },
  { key: "volume", text: locale.volume, width: "10%" },
  { key: "totalCreditAmount", text: locale.totalCreditAmount, width: "25%" },
];
