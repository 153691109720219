import { Field, Modal, Text, ActionButton, TextField, Autocomplete } from "components/commons";
import React from "react";
import locale from "localization";
import useForm from "hooks/useForm.v2";
import { useApi } from "hooks";
import { otpState } from "./otp.state";
import { getBpiAccount, initiateBpiPayment, processBpiPayment } from "apis/bpi.api";
import { getCashIn } from "apis";
import { formatAmount, parseNumber, prettifyCashInMethod } from "utils";
import { useHistory, useLocation } from "react-router-dom";
import { SuccessPurchase } from "images";
import { CashInMethod, Path } from "enums";
import { useEffect } from "react";
import { RefreshRounded } from "@material-ui/icons";
import { useState } from "react";

const BpiOtpModal = ({ notifModal, token, cashinId, ...modal }) => {
  const history = useHistory();
  const location = useLocation();
  const form = useForm(otpState);
  const { fields, initializeForm, modifyForm, isFormSubmittable } = form;
  const { otp = {}, account = {} } = fields;
  const [submittedToken, setSubmittedToken] = useState(false);

  const getCashInRequest = useApi({
    api: getCashIn,
    params: {
      cashInId: cashinId,
    },
  });

  const { result: cashIn } = getCashInRequest;

  const getAccountRequest = useApi({
    api: getBpiAccount,
    params: {
      token,
      cashinId,
    },
    handleOwnError: true,
  });

  const initiatePaymentRequest = useApi({
    api: initiateBpiPayment,
    params: {
      token,
      cashinId: parseNumber(cashinId),
    },
  });

  const processPaymentRequest = useApi({
    api: processBpiPayment,
    params: {
      token,
      cashinId,
      platformType: "plb",
    },
  });

  const { loading } = processPaymentRequest;
  const { result: accounts = [], error: errorAccounts } = getAccountRequest;
  const { result: transaction = {} } = initiatePaymentRequest;
  const { transactionId } = transaction;

  const init = async () => {
    getCashInRequest.request();
    getAccountRequest.request({
      token,
    });

    initializeForm({}, true);
  };

  useEffect(() => {
    if (modal.active) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.active]);

  return (
    <Modal
      {...modal}
      title={locale.cashInWithBPI}
      style={{
        width: "600px",
      }}
    >
      <Text className="text-center">
        <locale.Populate
          text={locale.youWillPay}
          items={[<b>{formatAmount(cashIn?.amount || 0)}</b>]}
        />
      </Text>
      <Field {...account}>
        {errorAccounts ? (
          <div
            onClick={() => {
              getAccountRequest.request({
                token,
              });
            }}
            className="flex pointer"
          >
            <Text>Failed to load.</Text>
            <RefreshRounded />
          </div>
        ) : (
          <Autocomplete
            {...account}
            options={accounts.map((account) => {
              return {
                value: account.accountNumberToken,
                label: `${account.accountNumber} (${account.accountType})`,
              };
            })}
            loading={initiatePaymentRequest.loading || getAccountRequest.loading}
            onChange={(name, value) => {
              modifyForm({
                account: value,
                otp: {
                  value: "",
                  isDirty: false,
                },
              });
              setSubmittedToken(false);
            }}
          />
        )}
      </Field>
      {account.value && submittedToken && (
        <Field
          childrenStyle={{
            minWidth: "400px",
            maxWidth: "400px",
          }}
          {...otp}
        >
          <TextField {...otp} />
        </Field>
      )}

      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              if (submittedToken) {
                modifyForm({
                  account: {
                    value: "",
                    isDirty: false,
                  },
                  otp: {
                    value: "",
                    isDirty: false,
                  },
                });
                setSubmittedToken(false);
              } else {
                modal.close();
                initializeForm({}, true);
                history.push(location.pathname, {});
              }
            },
          },
          {
            loading: loading || initiatePaymentRequest.loading,
            disabled: (!isFormSubmittable && submittedToken) || !account.value,
            text: submittedToken ? locale.payNow : locale.continue,
            onClick: async () => {
              if (account.value && !otp.value) {
                await initiatePaymentRequest.request({
                  accountNumberToken: account.value,
                });
                setSubmittedToken(true);
              } else {
                form.onSubmit(async ({ otp }) => {
                  await processPaymentRequest.request({
                    otp,
                    transactionId,
                  });
                  modal.close();
                  initializeForm({}, true);
                  notifModal.show({
                    content: locale.yourPaymentHasBeenConfirmed,
                    title: locale.successTitle,
                    secondary: {
                      text: locale.buyFuel,
                      onClick: () => {
                        history.push(Path.BuyFuel);
                      },
                    },
                    primaryText: locale.newCashIn,
                    image: SuccessPurchase,
                    actions: [
                      {
                        text: locale.goToCashInHistory,
                        onClick: () => {
                          history.push(Path.CashInHistory);
                        },
                      },
                    ],
                    cashInId: cashinId,
                    amount: cashIn?.amount,
                    paymentMethod: `${prettifyCashInMethod(CashInMethod.OnlineBanking)} (BPI)`,
                  });
                });
              }
            },
          },
        ]}
      />
    </Modal>
  );
};

export default BpiOtpModal;
