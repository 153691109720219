import locale from "localization";

export const initialState = (state = {}) => {
  const {
    savingFromFuelHedging = {
      value: "",
    },
    pricing = {
      value: "",
    },
    fuelCodesAreConvenient = {
      value: "",
    },
    fleetPortalWasEasyToUser = {
      value: "",
    },
    fastAndCashlessTransactions = {
      value: "",
    },
    realTimeTransactionsMonitoring = {
      value: "",
    },
    otherReasons = {
      value: "",
    },
    otherReasonsDescription = {
      value: "",
    },
  } = state;
  return {
    savingFromFuelHedging: {
      name: "savingFromFuelHedging",
      ...savingFromFuelHedging,
    },
    pricing: {
      name: "pricing",
      ...pricing,
    },
    fuelCodesAreConvenient: {
      name: "fuelCodesAreConvenient",
      ...fuelCodesAreConvenient,
    },
    fleetPortalWasEasyToUser: {
      name: "fleetPortalWasEasyToUser",
      ...fleetPortalWasEasyToUser,
    },
    fastAndCashlessTransactions: {
      name: "fastAndCashlessTransactions",
      ...fastAndCashlessTransactions,
    },
    realTimeTransactionsMonitoring: {
      name: "realTimeTransactionsMonitoring",
      ...realTimeTransactionsMonitoring,
    },
    otherReasons: {
      name: "otherReasons",
      ...otherReasons,
    },
    otherReasonsDescription: {
      name: "otherReasonsDescription",
      multiline: true,
      maxLength: 255,
      rowsMax: 4,
      placeholder: locale.tellUsMorePositiveExperience,
      required: true,
      ...otherReasonsDescription,
    },
  };
};
