import classNames from "classnames";
import { Field, Filter, SearchField, Button, GroupCheckbox } from "components/commons";
import { SelectProvince, SelectCity } from "components/field";
import { Products, UserAccess } from "enums";
import locale from "localization";
import React, { useCallback ,useContext} from "react";
import { prettifyProduct } from "utils/pretty.utils";
import styles from "./station-list-filter.module.scss";
import { UserContext } from "contexts";

const StationFilter = ({
  filterState,
  clearFilter,
  onSearchChange,
  onSearch,
  onFieldChange,
  resetFilter,
  submitFilter,
  filterCount,
  openMap,
  onChangeMapList,
  onProductChangeCb,
}) => {
  const { userAccess } = useContext(UserContext);
  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.StationList;
  });

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  const onChangeMapListCb = useCallback(() => {
    if (onChangeMapList) {
      return onChangeMapList();
    }
  }, [onChangeMapList]);

  const buttonModeIcon = openMap ? "icon-list" : "icon-map";

  return (
    <div className={styles.filter}>
      <Filter
        submit={submitFilter}
        close={resetFilter}
        clear={clearFilter}
        filterCount={filterCount}
      >
        <div className={styles.content}>
          <Field
            label={locale.province}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <SelectProvince
              name="province"
              {...filterState.province}
              onChange={onFieldChange}
              value={filterState.province || "all"}
            />
          </Field>
          <Field label={locale.city} labelPosition={"left"} childrenClassName={styles.fieldContent}>
            <SelectCity
              name="city"
              {...filterState.city}
              onChange={onFieldChange}
              value={filterState.city || "all"}
              filterState={filterState}
            />
          </Field>
        </div>
        <GroupCheckbox
          className={styles.productCode}
          title={locale.availableProducts}
          options={[
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.diesel)}></div>
                  {prettifyProduct(Products.Diesel)}
                </div>
              ),
              value: Products.Diesel,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas91)}></div>
                  {prettifyProduct(Products.Gas91)}
                </div>
              ),
              value: Products.Gas91,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas95)}></div>
                  {prettifyProduct(Products.Gas95)}
                </div>
              ),
              value: Products.Gas95,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas97)}></div>
                  {prettifyProduct(Products.Gas97)}
                </div>
              ),
              value: Products.Gas97,
            },
          ]}
          value={filterState.productCodes}
          onChange={(name, { value }) => {
            onProductChangeCb(value);
          }}
        />
      </Filter>

      <Field className={styles.search}>
        <SearchField
          placeholder={locale.stationName}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          restrict={false}
        />
      </Field>
      {(getUserAccess?.fullAccess || getUserAccess?.viewAccess) && (
         <Field className={styles.toggleMapButton}>
         <Button
           onClick={onChangeMapListCb}
           exportButton
           startIcon={<span className={buttonModeIcon} />}
         >
           {openMap ? locale.listView : locale.mapView}
         </Button>
       </Field>
      )}
    </div>
  );
};

export default StationFilter;
