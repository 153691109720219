import { ApiPath } from "enums";
import { Get } from "utils";

export const getPumpPrice = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.VirtualStation}/pump-price/fleet/${fleetId}`, query);
  return res.data;
};

export const getPriceHistory = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.PriceHistory}/fleet/${fleetId}`, query);
  return res.data;
};
