import { BackLink, Intro, DataTable, Product } from "components/commons";
import { FleetContext } from "contexts";
import { Path, Products } from "enums";
import { useApi, useFilter, useModal, useMount } from "hooks";
import React, { useCallback, useMemo, useContext } from "react";
import { priceHistoryColumns } from "./price-history-columns";
import FuelHistoryFilter from "./price-history-filter";
import { fuelHistoryState } from "./price-history-filter.state";
import styles from "./price-history.module.scss";
import { prettifyPriceMovementType, prettifyProduct } from "utils/pretty.utils";
import locale from "localization";
import { dateTimeFormat, formatAmount, formatPriceMovementPerLiter } from "utils";
import { getPriceHistory } from "apis";
import {} from "utils/text.utils";
import { SuccessModal } from "components/modals";
import { generatePricingReport } from "apis/generate-report.api";

const PriceHistoryModule = () => {
  const { fleet } = useContext(FleetContext);
  const successModal = useModal();

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    submitFilter,
    submittedFilter,
    clearFilter,
    filterCount,
  } = useFilter(fuelHistoryState(fleet.fleetId));

  const {
    request: getPriceHistoryRequest,
    loading: fetchingPriceHistory,
    result: getPriceHistoryResult = { data: [], count: 0 },
  } = useApi({
    api: getPriceHistory,
    pageError: true,
  });

  const { request: generateReportRequest, loading: fetchingReport } = useApi({
    api: generatePricingReport,
    pageError: true,
  });

  useMount(() => {
    fetchPriceHistory(requestState);
  });

  const fetchPriceHistory = useCallback(
    (rs) => {
      const val = submitFilter(rs);
      getPriceHistoryRequest({ ...val, productCodes: val.productCodes.join(",") });
    },
    [getPriceHistoryRequest, submitFilter]
  );

  const preparedPriceHistoryData = useMemo(() => {
    const { priceHistory } = getPriceHistoryResult;
    if (priceHistory && priceHistory.length > 0) {
      const preparedData = priceHistory.map((d) => {
        const map = new Map();
        map.set("effectiveAt", dateTimeFormat(d.effectiveAt));
        map.set(
          "product",
          <Product
            grass={d.productCode === Products.Gas91}
            salsa={d.productCode === Products.Gas95}
            deepBlue={d.productCode === Products.Gas97}
            cheddar={d.productCode === Products.Diesel}
          >
            {prettifyProduct(d.productCode)}
          </Product>
        );
        map.set("pumpPrice", formatAmount(d.pumpPrice));
        map.set("priceMovement", formatPriceMovementPerLiter(d.priceMovement));
        map.set("discount", formatAmount(d.discount));
        map.set("creditPrice", formatAmount(d.creditPrice));
        map.set("movementType", prettifyPriceMovementType(d.movementType));
        return map;
      });
      return preparedData;
    }
    return [];
  }, [getPriceHistoryResult]);

  const onDateRangeCb = useCallback(
    (value, dirty) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate });
      // fetchPriceHistory(requestState);
    },
    [modifyFilters]
  );

  const onProductChangeCb = useCallback(
    (productCodes) => {
      modifyFilters({ productCodes });
      // fetchPriceHistory(requestState);
    },
    [modifyFilters]
  );

  // const onSearchCb = useCallback(
  //   (searchKey) => {
  //     const { requestState } = modifyFilters({ searchKey, page: 1 });
  //     // fetchPriceHistory(requestState);
  //   },
  //   [modifyFilters, fetchPriceHistory]
  // );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchPriceHistory(requestState);
    },
    [modifyFilters, fetchPriceHistory]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchPriceHistory(requestState);
    },
    [modifyFilters, fetchPriceHistory]
  );
  const onDownloadFileCb = async () => {
    const { requestState } = modifyFilters({ reportType: "pricing-history", platformType: "plb" });
    delete requestState.perPage;
    delete requestState.page;
    const params = submitFilter(requestState);
    const generateResult = await generateReportRequest({
      ...params,
      productCodes: params.productCodes.join(","),
    });
    if (generateResult?.downloadUrl) {
      window.open(generateResult?.downloadUrl, "_blank");
    } else {
      successModal.show({
        title: locale.successTitle,
        content: locale.reportGenerated,
        // contentHighlight: [<strong>{user?.email}</strong>, <strong>business@pricelocq.com</strong>],
      });
    }
  };

  return (
    <div>
      <SuccessModal {...successModal} />
      <div>
        <BackLink text={locale.buyFuel} path={Path.BuyFuel} />
        <Intro title={locale.priceHistory} subtitle="" />
      </div>
      <div className={styles.filters}>
        <FuelHistoryFilter
          filterCount={filterCount}
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onProductChange={onProductChangeCb}
          onSearchChange={modifyFilter}
          // onSearch={onSearchCb}
          onDownloadFile={onDownloadFileCb}
          onMovementTypeChange={(movementType) => {
            modifyFilters({ movementType });
          }}
          fetchingReport={fetchingReport}
          resetFilter={() => {
            const val = {
              productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
              endDate: null,
              startDate: null,
              movementType: "all",
              ...submittedFilter,
            };
            modifyFilters(val);
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchPriceHistory(requestState);
          }}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchPriceHistory(requestState);
          }}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingPriceHistory}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={priceHistoryColumns}
          data={preparedPriceHistoryData}
          dataCount={getPriceHistoryResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
    </div>
  );
};

export default PriceHistoryModule;
