import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "./pop-over-menu.module.scss";

const PopOverMenu = ({ options = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option = null) => {
    if (option?.onClick) {
      option.onClick();
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-controls="pop-over-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon className={styles.icon} />
      </IconButton>
      <Menu
        id="pop-over-menu"
        anchorEl={anchorEl}
        className={styles.popoverContainer}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option, i) => (
          <MenuItem key={i} onClick={() => handleClose(option)} className={styles.content} disabled={option.disabled}>
            {option.content}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PopOverMenu;
