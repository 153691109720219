import { Autocomplete, Field, SearchField } from "components/commons";
import React, { useCallback } from "react";
import styles from "./vehicle-filter.module.scss";
import { VehicleStatus } from "enums";
import { prettifyVehicleStatus } from "utils";

const VehicleFilter = ({ filterState, onStatusChange, onSearchChange, onSearch }) => {
  const onStatusChangeCb = useCallback(
    (name, { value }) => {
      if (onStatusChange) return onStatusChange(value || "all");
    },
    [onStatusChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  return (
    <div className={styles.filter}>
      <Field className={styles.status} label="Status">
        <Autocomplete
          name="status"
          value={filterState.status}
          options={[
            { label: "All", value: "all" },
            { label: prettifyVehicleStatus(VehicleStatus.Active), value: VehicleStatus.Active },
            { label: prettifyVehicleStatus(VehicleStatus.Inactive), value: VehicleStatus.Inactive },
          ]}
          onChange={onStatusChangeCb}
        />
      </Field>
      <Field className={styles.search}>
        <SearchField
          placeholder="Plate Number"
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          restrict={false}
        />
      </Field>
    </div>
  );
};

export default VehicleFilter;
