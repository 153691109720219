import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const getCashIn = async ({ cashInId }) => {
  const res = await Get(`${ApiPath.GetCashIn}/${cashInId}`);
  return res.data;
};

export const getCashIns = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.GetCashIns}/${fleetId}`, query);
  return res.data;
};

export const createCashIn = async (query) => {
  const res = await Post(`${ApiPath.CashIn}`, query);
  return res.data;
};

export const createCashInVIPPoints = async (query) => {
  const res = await Post(`${ApiPath.VipBiz}/loyalty/conversion/fleet`, query);
  return res.data;
};

export const dragonPayProcessors = async (query) => {
  const res = await Get("ms-fleet/cashin/dragonpay/processors", query);
  return res.data;
};
