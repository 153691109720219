import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/color.scss";
import "./styles/font.scss";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";
import { AnalyticsContext } from "contexts";
import env from "environments/env";

const analytics = Analytics({
  app: "PriceLOCQ Business Fleet Portal",
  plugins: [
    segmentPlugin({
      writeKey: env.SEGMENT_WRITE_KEY,
    }),
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <AnalyticsContext.Provider
      value={{
        track: analytics.track,
        page: analytics.page,
        identify: analytics.identify,
      }}
    >
      <App />
    </AnalyticsContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
