import { ActionButton, Modal, Popper, Text } from "components/commons";
import locale from "localization";
import { pluralize } from "utils/text.utils";
import styles from "./confirm-generate-fuel-codes-modal.module.scss";

const ConfirmGenerateFuelCodesModal = ({
  generating,
  fuelCodeCount,
  drivers,
  onGenerate,
  deactivated,
  ...modalState
}) => {
  const { close, active } = modalState;

  const submit = async () => {
    await onGenerate();
  };

  // const driverInfo = useMemo(() => {
  //   let anyDriver = 0;
  //   let driverIds = 0;
  //   if (Array.isArray(fuelCodeData)) {
  //     fuelCodeData.forEach((d) => {
  //       if (d.fuelCodeType === FuelCodeType.AnyDriverAnyVehicle) {
  //         anyDriver = anyDriver + 1;
  //       } else {
  //         driverIds = driverIds + 1;
  //       }
  //     });
  //   }
  //   return { anyDriver, driverIds };
  // }, [fuelCodeData]);

  return (
    <Modal
      active={active}
      title={`${locale.generalFuelCodesCapitalized}?`}
      close={close}
      className={styles.modal}
    >
      <div>
        <locale.Populate
          text={
            drivers.length ? locale.youAreAboutToGenerateFuelCode : locale.youAreAboutToGenerate
          }
          items={[
            <span>
              {fuelCodeCount}{" "}
              {pluralize(fuelCodeCount, locale.fuelCodeLowerCase, locale.fuelCodesLowerCase)}
            </span>,
            <Popper
              inline
              element={
                <b className={styles.driverCount}>
                  {drivers.length} {pluralize(drivers.length, locale.driver, locale.drivers)}.
                </b>
              }
            >
              <div className={styles.driverPopperList}>
                <Text className={styles.title}>{locale.assignedDrivers}</Text>
                {drivers.map((d, i) => (
                  <Text className={styles.name} key={i}>
                    {d}
                  </Text>
                ))}
              </div>
            </Popper>,
          ]}
        />
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: () => {
              close();
            },
            text: locale.cancel,
          },
          {
            onClick: () => {
              submit(submit);
            },
            text: locale.generalFuelCodesCapitalized,
            loading: generating,
            disabled: deactivated,
          },
        ]}
      />
    </Modal>
  );
};

export default ConfirmGenerateFuelCodesModal;
