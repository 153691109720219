import { DropdownPaginated } from "components/commons";
import React, { useContext, useCallback } from "react";
import { getDrivers } from "apis";
import locale from "localization";
import { useApi } from "hooks";
import { FleetContext } from "contexts";
import { DriverStatus } from "enums";

const SelectDriver = ({ placeholder = locale.searchOrSelect, ...props }) => {
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;
  const {
    request,
    loading,
    result = { drivers: [], count: 0 },
  } = useApi({
    api: getDrivers,
    pageError: true,
  });

  const fetch = useCallback(
    async (params) => {
      return await request({
        fleetId,
        order: "asc",
        orderBy: "firstName",
        perPage: 10,
        status: DriverStatus.Active,
        ...params,
      });
    },
    [request, fleetId]
  );
  return (
    <DropdownPaginated
      {...props}
      request={async (params) => {
        return await fetch(params);
      }}
      total={result.count}
      loading={loading}
      formatRequest={(result) => {
        const { drivers = [] } = result || {};
        if (drivers.length > 0) {
          return drivers.map((d) => {
            return {
              label: `${d.firstName} ${d.lastName}`,
              value: d.driverId,
              driverMobileNumber: d.mobileNumber,
            };
          });
        }
        return [];
      }}
      placeholder={placeholder}
    />
  );
};

export default SelectDriver;
