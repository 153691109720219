import { updateFuelCode } from "apis";
import { ActionButton, Modal } from "components/commons";
import { useApi, useModal } from "hooks";
import React from "react";
import FuelCodeForm from "./fuel-code-form";
import locale from "localization";
import styles from "./fuel-code-form.module.scss";
const EditFuelCodeModal = ({ fuelCodeId, fuelCode, initialState, callback, ...modal }) => {
  const updateFuelCodeApi = useApi({
    api: updateFuelCode,
  });

  const notifEditFuelCodeModal = useModal();

  return (
    <div>
      <Modal {...modal} className={styles.modal}>
        <FuelCodeForm
          loading={updateFuelCodeApi.loading}
          actionText={locale.save}
          initialState={initialState}
          submit={async (formValues) => {
            await updateFuelCodeApi.request({
              ...formValues,
              fuelCodeId: fuelCodeId,
            });

            if (callback) {
              callback();
            }

            notifEditFuelCodeModal.close();

            notifEditFuelCodeModal.show({
              title: locale.overrideSuccessful,
              content: (
                <locale.Populate
                  text={locale.youHaveSuccessfullyOverridenFuelCode}
                  items={[<b>{fuelCode}</b>]}
                />
              ),
            });
            modal.close();
          }}
        />
      </Modal>
      <Modal {...notifEditFuelCodeModal}>
        <div className="text-center">{notifEditFuelCodeModal.content}</div>
        <div className="text-right">
          <ActionButton
            loading={updateFuelCodeApi.loading}
            center
            items={[
              {
                text: locale.gotIt,
                onClick: () => {
                  notifEditFuelCodeModal.close();
                },
                primary: true,
              },
            ]}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EditFuelCodeModal;
