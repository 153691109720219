import Validation from "utils/validation.utils";
import locale from "localization";

export const initialState = (state = {}) => {
  const {
    firstName = {
      value: "",
      disabled: false,
    },
    lastName = {
      value: "",
      disabled: false,
    },
    contactNumber = {
      value: "",
      disabled: false,
    },
    email = {
      value: "",
      disabled: false,
    },
    loanProduct = {
      value: "",
      disabled: false,
    },
    region = {
      value: "",
      disabled: false,
    },
    branch = {
      value: "",
      disabled: false,
    },
    dominionPrivacy = {
      value: "",
      disabled: false,
    },
    locqPrivacy = {
      value: "",
      disabled: false,
    },
  } = state;
  return {
    firstName: {
      name: "firstName",
      placeholder: locale.enterFirstNameHere,
      validations: [Validation.required({ noMessage: true }), Validation.name()],
      maxLength: 75,
      label: locale.firstName,
      required: true,
      ...firstName,
    },
    lastName: {
      name: "lastName",
      placeholder: locale.enterLastNameHere,
      validations: [Validation.required({ noMessage: true }), Validation.name()],
      maxLength: 75,
      label: locale.lastName,
      required: true,
      ...lastName,
    },
    email: {
      name: "email",
      placeholder: locale.enterEmailHere,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.emailAddress({ customMessage: locale.invalidEmailFormat }),
      ],
      maxLength: 75,
      label: locale.email,
      required: true,
      ...email,
    },
    contactNumber: {
      name: "contactNumber",
      placeholder: "09xxxxxxxxx",
      maxLength: 11,
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.numeric(),
        Validation.mobileNumber(),
      ],
      label: locale.contactNumber,
      ...contactNumber,
    },
    loanProduct: {
      name: "loanProduct",
      placeholder: locale.selectProduct,
      maxLength: 11,
      required: true,
      label: locale.loanProduct,
      ...loanProduct,
    },
    region: {
      name: "region",
      placeholder: locale.selectRegion,
      maxLength: 11,
      required: true,
      label: locale.region,
      ...region,
    },
    branch: {
      name: "branch",
      placeholder: locale.selectBranch,
      maxLength: 11,
      required: true,
      label: locale.branch,
      ...branch,
    },
    dominionPrivacy: {
      name: "dominionPrivacy",
      required: true,
      ...dominionPrivacy,
    },
    locqPrivacy: {
      name: "locqPrivacy",
      required: true,
      ...locqPrivacy,
    },
  };
};
