import { Modal, Text, ActionButton, Image } from "components/commons";
import locale from "localization";
import React from "react";
import styles from "./cashin-card-remove-card-modal.module.scss";
import { prettifyCardType } from "utils/pretty.utils";
import { SuccessTransaction } from "images";

const CashinCardRemoveCardSuccessModal = ({
  cardType,
  cardNumber,
  close,
  active,
  refetchFleetCardsRequest,
}) => {
  return (
    <Modal
      active={active}
      close={() => {
        refetchFleetCardsRequest();
        close();
      }}
      className={styles.modal}
    >
      <div className={styles.header}>
        <Text className={styles.title}>{locale.cardSuccessfullyDeleted}</Text>
      </div>
      <div className={styles.content}>
        <Image src={SuccessTransaction} />
        <Text className={styles.message} align="center">
          <locale.Populate
            text={locale.youHaveSuccesfullyDeletedFromYourSavedCardsOption}
            items={[<b>{`${prettifyCardType(cardType)} ${cardNumber}`}</b>]}
          />
        </Text>
      </div>
      <ActionButton
        primary
        className={styles.primary}
        center
        items={[
          {
            onClick: () => {
              refetchFleetCardsRequest();
              close();
            },
            text: locale.gotIt,
            primary: true,
          },
        ]}
      />
    </Modal>
  );
};

export default CashinCardRemoveCardSuccessModal;
