import { DateTime } from "enums";
import moment from "moment";

export const dateFormat = (date) => {
  return moment(date).format("DD MMM yyyy");
};

export const dateTimeFormat = (dateTime) => {
  return moment(dateTime).format("DD MMM yyyy hh:mma");
};

export const formatDate = (date, format = DateTime.A) => {
  return moment(date).format(format);
};

export const getDuration = (fromDate, toDate) => {
  let now = moment(fromDate);
  let end = moment(toDate);
  let duration = moment.duration(end.diff(now));
  let days = duration.asDays();
  return days;
};

export const shortDateTime = (dateTime) => {
  const dateTimeMoment = moment(dateTime);
  const isToday = moment().format("MMDDYYYY") === dateTimeMoment.format("MMDDYYYY");
  const isSameYear = moment().format("YYYY") === dateTimeMoment.format("YYYY");
  if (isToday) {
    return formatDate(dateTime, "hh:mm A");
  }
  if (isSameYear) {
    return formatDate(dateTime, "DD MMM");
  }
  return formatDate(dateTime, "DD MMM YYYY");
};
