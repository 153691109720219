import { GuyWithMagGlass } from "images";
import locale from "localization";
import React, { useCallback } from "react";
import Image from "../image/image";
import Loader from "../loader/loader";
import Pagination from "../pagination/pagination";
import Table from "../table/table";
import Text from "../text/text";
import Title from "../title/title";
import styles from "./data-table.module.scss";
import { Button } from "components/commons";

const DataTable = ({
  loading,
  page,
  pageSize,
  onChangePage,
  onChangePageSize,
  columns,
  data,
  errorMessage,
  errorTitle,
  errorMessageSub,
  dataCount,
  plbStationTable = false,
  onClear,
}) => {
  const onChangePageCb = useCallback(
    (page) => {
      if (onChangePage) {
        return onChangePage(page);
      }
    },
    [onChangePage]
  );

  const onChangePageSizeCb = useCallback(
    (pageSize) => {
      if (onChangePageSize) {
        return onChangePageSize(pageSize);
      }
    },
    [onChangePageSize]
  );

  const onClearCb = useCallback(() => {
    if (onClear) {
      onClear();
    }
  }, [onClear]);

  return (
    <div>
      <Loader open={loading} />
      {!data.length && !loading ? (
        <div className={styles.noSearchResultRender}>
          <div>
            <Image src={GuyWithMagGlass} />
            <Title small>{errorTitle || locale.sorryNoResultFound}</Title>
            {plbStationTable ? (
              <>
                <Text subtitle>{locale.theListIsUpdatedFrequentlyForPricelocqStation}</Text>
                <Button primary className={styles.okayGotIt} onClick={onClearCb}>
                  {locale.okayGotIt}
                </Button>
              </>
            ) : (
              <>
                <Text subtitle>{errorMessage || locale.weCouldNotFindAMatchForSearchCriteria}</Text>
                <Text subtitle>{errorMessageSub || locale.pleaseTryADifferentOne}</Text>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <Table columns={columns} data={data} />
          <Pagination
            page={page}
            pageSize={pageSize}
            className={styles.pagination}
            dataCount={dataCount}
            onChangePage={onChangePageCb}
            onChangePageSize={onChangePageSizeCb}
          />
        </>
      )}
    </div>
  );
};

export default DataTable;
