import { ApiPath } from "enums";
import { Get } from "utils";

export const getPriceMovements = async ({ ...body }) => {
  const res = await Get(ApiPath.PriceMovement, body);
  return res.data;
};

export const getLatestPriceMovements = async () => {
  const res = await Get(`${ApiPath.PriceMovement}/latest`);
  return res.data;
};

export const getUpcomingPriceMovements = async () => {
  const res = await Get(`${ApiPath.PriceMovement}/upcoming`);
  return res.data;
};
