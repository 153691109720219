import React, { useCallback, useContext } from "react";
import { Button as MaterialButton, CircularProgress } from "@material-ui/core";
import classNames from "classnames";
import styles from "./button.module.scss";
import { AnalyticsContext } from "contexts";

const Button = ({
  classNameContainer,
  className,
  variant = "contained",
  loading,
  disabled,
  children,
  onClick,
  primary,
  link,
  outline,
  startIcon,
  secondary,
  rounded,
  thin,
  tiny,
  tabSelectable,
  exportButton,
  eventName = "",
  style,
  survey,
}) => {
  const { track } = useContext(AnalyticsContext);

  const onClickCb = useCallback(
    (e) => {
      if (!loading && onClick) {
        onClick(e);
        if (eventName) {
          track(eventName);
        }
      }
    },
    [loading, onClick, eventName, track]
  );

  return (
    <div className={classNameContainer}>
      <MaterialButton
        style={style}
        startIcon={startIcon}
        className={classNames(className, styles.button, {
          [`${styles.disabled}`]: disabled,
          [`${styles.primary}`]: primary,
          [`${styles.link}`]: link,
          [`${styles.secondary}`]: secondary,
          [`${styles.outline}`]: outline,
          [`${styles.rounded}`]: rounded,
          [`${styles.thin}`]: thin,
          [`${styles.exportbutton}`]: exportButton,
          [`${styles.tabSelectable}`]: tabSelectable,
          [`${styles.tiny}`]: tiny,
          [`${styles.survey}`]: survey,
        })}
        variant={variant}
        disabled={disabled}
        onClick={onClickCb}
      >
        {loading ? (
          <CircularProgress
            className={classNames(styles.circular, { [`${styles.primary}`]: primary })}
          />
        ) : (
          children
        )}
      </MaterialButton>
    </div>
  );
};

export default Button;
