import { FuelType, DateTime, PriceMovementGraphType, Products } from "enums";
import moment from "moment";

export const priceMovementObject = ({ price, graphType }) => {
  let objectPriceMovement = {};
  const sortedByDate = Object.values(price).sort((a, b) => {
    const dateA = moment(a.effectiveAt).toDate(),
      dateB = moment(b.effectiveAt).toDate();
    return dateA - dateB;
  });
  const month = formatDateRange({ sortedByDate });
  if (graphType === PriceMovementGraphType.contractPrices) {
    const diesel = filterPerProductCode({ sortedByDate, productCode: Products.Diesel });
    const gas91 = filterPerProductCode({ sortedByDate, productCode: Products.Gas91 });
    const gas95 = filterPerProductCode({ sortedByDate, productCode: Products.Gas95 });
    const gas97 = filterPerProductCode({ sortedByDate, productCode: Products.Gas97 });
    objectPriceMovement = { ...objectPriceMovement, diesel, gas91, gas95, gas97, month };
  } else {
    const { Diesel, Gas } = FuelType;
    const diesel = filterPerFuelType({ sortedByDate, fuelType: Diesel, graphType });
    const gas = filterPerFuelType({ sortedByDate, fuelType: Gas, graphType });
    objectPriceMovement = { ...objectPriceMovement, diesel, gas, month };
  }

  return { ...objectPriceMovement };
};

const filterPerFuelType = ({ sortedByDate, fuelType, graphType }) => {
  let priceMovement = [];
  let cumulativeTotal = 0;
  // use counter, cant use index in forEach due to 2 different looping of fuelType in sortedByDate
  let counter = 0;

  sortedByDate.forEach((p) => {
    if (p.product === fuelType) {
      let initialMovement =
        graphType === PriceMovementGraphType.priceMovementsCumulative && counter === 0
          ? 0
          : p.movement;
      cumulativeTotal += Number(initialMovement);
      const yVal =
        graphType === PriceMovementGraphType.priceMovementsCumulative
          ? cumulativeTotal
          : p.movement;

      priceMovement.push({ y: yVal, x: moment(p.effectiveAt).format(DateTime.E) });
      counter++;
    }
  });

  // force 0 price start for cumulative price
  if (graphType === PriceMovementGraphType.priceMovementsCumulative && priceMovement.length > 0) {
    priceMovement[0].y = 0;
  }

  return priceMovement;
};

const filterPerProductCode = ({ sortedByDate, productCode }) => {
  let priceMovement = [];

  sortedByDate.forEach((p) => {
    p.productCode === productCode &&
      priceMovement.push({ y: p.creditPrice, x: moment(p.effectiveAt).format(DateTime.E) });
  });

  return priceMovement;
};

const formatDateRange = ({ sortedByDate }) => {
  const months = [
    ...new Set(
      sortedByDate.map((d) => {
        return moment(d.effectiveAt).format(DateTime.E);
      })
    ),
  ];
  return months;
};

export const latestPriceMovementObject = ({ priceMovements }) => {
  let latestPrice = {};
  Object.values(priceMovements).forEach((d, i) => {
    const movement = { [d.product]: d.movement };
    latestPrice = { ...latestPrice, ...movement };
    if (i === priceMovements.length - 1) {
      const date = { effectiveAt: d.effectiveAt };
      latestPrice = { ...latestPrice, ...date };
    }
  });

  return latestPrice;
};

export const upcomingPriceMovementObject = ({ priceMovements }) => {
  let upcomingPrice = {};
  Object.values(priceMovements).forEach((d, i) => {
    const movement = { [d.product]: d.movement };
    upcomingPrice = { ...upcomingPrice, ...movement };
    if (i === priceMovements.length - 1) {
      const date = { effectiveAt: d.effectiveAt };
      upcomingPrice = { ...upcomingPrice, ...date };
    }
  });
  return upcomingPrice;
};

export const pumpPrice = ({ price }) => {
  let latestPrice = {};
  Object.values(price).forEach((d, i) => {
    const movement = { [d.product]: d.movement };
    latestPrice = { ...latestPrice, ...movement };
    if (i === price.length - 1) {
      const date = { effectiveAt: d.effectiveAt };
      latestPrice = { ...latestPrice, ...date };
    }
  });

  return latestPrice;
};
