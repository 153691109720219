import { Get, Post } from "utils";
import { ApiPath } from "enums";

export const getFeedBack = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.GetFeedback}/${fleetId}`, query);
  return res.data;
};

export const createFeedBack = async ({ ...body }) => {
  const res = await Post(`${ApiPath.GetFeedback}`, body);
  return res.data;
};
