import { Text, Product } from "components/commons";
import React from "react";
import { prettifyFuelType, formatCurrency } from "utils";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "./price-movement.module.scss";
import classNames from "classnames";
import { Products } from "enums";

const PriceMovement = ({ priceMovement, loading, gasProduct = false, noIndicator = false }) => {
  const { type, price } = priceMovement;
  const isRollback = price < 0;
  const noMovement = price === 0;
  return (
    <div className={styles.priceMovement}>
      <div className={styles.productLabel}>
        <div className={styles.colorCodeContainer}>
          {gasProduct && (
            <Product
              className={styles.colorCode}
              cheddar={Products.Diesel === type}
              grass={Products.Gas91 === type}
              salsa={Products.Gas95 === type}
              deepBlue={Products.Gas97 === type}
            />
          )}
        </div>
        <Text className={styles.fuelTypeName}>{prettifyFuelType(type)}</Text>
      </div>

      <div className={styles.movement}>
        <div className={styles.arrowContainer}>
          {!noMovement && !loading && !noIndicator && (
            <span
              className={classNames(styles.arrow, {
                "icon-arrow-up": !isRollback,
                "icon-arrow-down": isRollback,
                [styles.increase]: !isRollback,
                [styles.rollback]: isRollback,
              })}
            />
          )}
        </div>
        {loading ? (
          <Skeleton variant="text" className={styles.loader} />
        ) : (
          <Text
            className={classNames(styles.price, {
              [styles.increase]: !isRollback,
              [styles.rollback]: isRollback,
              [styles.noMovement]: noMovement,
              [styles.gasProduct]: noIndicator,
            })}
          >
            {formatCurrency(Math.abs(price || 0))}
          </Text>
        )}
      </div>
    </div>
  );
};

export default PriceMovement;
