import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    mobileNumber = {
      value: "",
      disabled: false,
    },
    points = {
      value: "",
      disabled: false,
    },
    firstName = {
      value: "",
      disabled: false,
    },
    lastName = {
      value: "",
      disabled: false,
    },
  } = state;

  return {
    mobileNumber: {
      name: "mobileNumber",
      placeholder: "9xxxxxxxxx",
      maxLength: 10,
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.numeric(),
        Validation.mobileNumberv2(),
      ],
      ...mobileNumber,
    },
    points: {
      name: "points",
      required: false,
      ...points,
    },
    firstName: {
      name: "firstName",
      required: false,
      ...firstName,
    },
    lastName: {
      name: "lastName",
      required: false,
      ...lastName,
    },
  };
};
