import React from "react";
import styles from "./forgot-password.module.scss";
import { ErrorCode, Path } from "enums";
import { Field, TextField, Button } from "components/commons";
import locale from "localization";
import { useHistory } from "react-router-dom";

const ForgotPasswordForm = ({ errorCode, ...props }) => {
  const history = useHistory();
  const {
    fields,
    modifyField,
    submittingForgotPassword,
    handleSubmit,
    submitForm,
    isFormSubmittable,
  } = props;

  return (
    <>
      <Field
        {...fields.email}
        label={
          errorCode === ErrorCode.InvalidPasswordToken
            ? locale.enterYourPasswordAgain
            : fields.email?.label
        }
      >
        <TextField
          {...fields.email}
          onChange={modifyField}
          onEnter={() => {
            submitForm(handleSubmit);
          }}
        />
      </Field>
      <div className={styles.buttonGroup}>
        <Button
          onClick={() => {
            history.push(Path.Auth);
          }}
        >
          {locale.cancel}
        </Button>
        <Button
          primary
          loading={submittingForgotPassword}
          onClick={() => {
            submitForm(handleSubmit);
          }}
          disabled={!isFormSubmittable}
        >
          {locale.continue}
        </Button>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
