import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const generateFuelCode = async ({ ...query }) => {
  const res = await Post(`${ApiPath.FuelCode}`, query);
  return res.data;
};

export const getMyFuelCodes = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.FuelCode}/fleet/${fleetId}`, query);
  return res.data;
};

export const getMyFuelCodesTotal = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.FuelCode}/count/fleet/${fleetId}`, query);
  return res.data;
};

export const updateFuelCode = async ({ fuelCodeId, ...body }) => {
  const res = await Put(`${ApiPath.FuelCode}/${fuelCodeId}`, body);
  return res.data;
};

export const batchGenerateFuelCode = async ({ body }) => {
  const res = await Post(`${ApiPath.FuelCode}/batch`, body);
  return res.data;
};

export const getTotalLitersMyActiveFuelCode = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.FuelCode}/fleet/${fleetId}/active-fuel-codes`, query);
  return res.data;
};
