import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Checkbox, Text, Button, TextField, Field } from "components/commons";
import locale from "localization";
import { initialState as formState } from "./satisfactory.state";
import { useForm } from "hooks";
import styles from "../dashboard.module.scss";

const SurveySatisfactoryForm = ({
  initialState = {},
  setStep2,
  setStep2Data,
  setRating,
  setShowSurvey,
  submitData,
}) => {
  const [buttonActionDisabled, setButtonActionDisabled] = useState(false);
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const { fields, modifyField, modifyForm, clearForm, submitForm, getFormValues } = useForm({
    initialState: form,
  });

  const submit = async () => {
    const params = getFormValues();
    setStep2Data({ ...params });
    setStep2(true);
  };

  useEffect(() => {
    if (
      fields?.savingFromFuelHedging.value ||
      fields?.pricing.value ||
      fields.fuelCodesAreConvenient.value ||
      fields?.fleetPortalWasEasyToUser.value ||
      fields?.fastAndCashlessTransactions.value ||
      fields?.realTimeTransactionsMonitoring.value
    ) {
      setButtonActionDisabled(false);
    } else {
      setButtonActionDisabled(true);
    }

    if (fields?.otherReasons.value) {
      if (fields?.otherReasonsDescription?.value) {
        setButtonActionDisabled(false);
      } else {
        setButtonActionDisabled(true);
      }
    }
  }, [fields]);

  const onChangeOtherReasons = useCallback(
    (name, { value }) => {
      modifyForm({
        [fields?.otherReasons.name]: {
          value,
        },
        [fields?.otherReasonsDescription.name]: {
          value: "",
        },
      });
    },
    [modifyForm, fields?.otherReasons.name, fields?.otherReasonsDescription.name]
  );

  const onSkip = async () => {
    const params = getFormValues();
    submitData({ isSkip: true, ...params });
    clearForm();
    setStep2Data({});
    setStep2(false);
    setRating(0);
    setShowSurvey(false);
  };
  return (
    <>
      <Text className={styles.headerTitleRating}>{locale.whatMadePriceLOCQGreat}</Text>
      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabel}>
          <Checkbox
            {...fields?.savingFromFuelHedging}
            value={fields?.savingFromFuelHedging?.value || null}
            onChange={modifyField}
          />
          <Text className={styles.description}>{locale.savingFromFuelHedging}</Text>
        </div>
      </div>
      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabel}>
          <Checkbox
            {...fields?.pricing}
            value={fields?.pricing?.value || null}
            onChange={modifyField}
          />
          <Text className={styles.description}>{locale.pricing}</Text>
        </div>
      </div>
      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabel}>
          <Checkbox
            {...fields?.fuelCodesAreConvenient}
            value={fields?.fuelCodesAreConvenient?.value || null}
            onChange={modifyField}
          />
          <Text className={styles.description}>{locale.fuelCodesAreConvenient}</Text>
        </div>
      </div>
      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabel}>
          <Checkbox
            {...fields?.fleetPortalWasEasyToUser}
            value={fields?.fleetPortalWasEasyToUser?.value || null}
            onChange={modifyField}
          />
          <Text className={styles.description}>{locale.fleetPortalWasEasyToUser}</Text>
        </div>
      </div>
      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabel}>
          <Checkbox
            {...fields?.fastAndCashlessTransactions}
            value={fields?.fastAndCashlessTransactions?.value || null}
            onChange={modifyField}
          />
          <Text className={styles.description}>{locale.fastAndCashlessTransactions}</Text>
        </div>
      </div>
      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabel}>
          <Checkbox
            {...fields?.realTimeTransactionsMonitoring}
            value={fields?.realTimeTransactionsMonitoring?.value || null}
            onChange={modifyField}
          />
          <Text className={styles.description}>{locale.realTimeTransactionsMonitoring}</Text>
        </div>
      </div>
      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabelOther}>
          <div className={styles.checkBoxLabelHeader}>
            <Checkbox
              {...fields?.otherReasons}
              value={fields?.otherReasons?.value || null}
              onChange={(name, value) => {
                onChangeOtherReasons(name, value);
              }}
            />
            <Text className={styles.description}>{locale.otherReasons}</Text>
          </div>
          {fields?.otherReasons?.value && (
            <div className={styles.checkBoxLabelFooter}>
              <Field {...fields?.otherReasonsDescription}>
                <TextField {...fields?.otherReasonsDescription} onChange={modifyField} />
              </Field>
            </div>
          )}
        </div>
      </div>
      <div className={styles.skipAndSubmitSatisfactory}>
        <div className={styles.skipSatisfactory}>
          <Button className={styles.skip} onClick={() => submitForm(onSkip)}>
            {locale.skip}
          </Button>
        </div>
        <div className={styles.submitSatisfactory}>
          <Button
            className={styles.continue}
            survey
            disabled={buttonActionDisabled}
            onClick={() => submitForm(submit)}
          >
            {locale.submit}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SurveySatisfactoryForm;
