import { getIntroductoryPromo } from "apis/promo.api";
import { FleetContext } from "contexts";
import { useApi, useMount } from "hooks";
import { useCallback, useContext } from "react";

const IntroductoryPromo = () => {
  const { fleet, notification, setNotification } = useContext(FleetContext);
  const { fleetId } = fleet;
  const { promo } = notification;

  const request = useApi({
    api: getIntroductoryPromo,
    params: {
      fleetId,
    },
    handleOwnError: true,
  });

  const fetchPromo = useCallback(async () => {
    const res = await request.request();
    const { status, cashinAmount = 0 } = res || {};
    if (status === "available") {
      setNotification({
        ...notification,
        promo: {
          ...promo,
          loaded: true,
          notification: cashinAmount === 0,
          details: res,
          refetch: fetchPromo,
        },
      });
    } else {
      setNotification({
        promo: {},
      });
    }
  }, [request, notification, setNotification, promo]);

  useMount(async () => {
    if (!promo.loaded) {
      fetchPromo();
    }
  });

  return null;
};

export default IntroductoryPromo;
