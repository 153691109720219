import locale from "localization";

export const initialState = (state = {}) => {
  const {
    poorCrewAssistanceAtStation = {
      value: "",
    },
    portalWasDifficultToUse = {
      value: "",
    },
    problemsWithFuelCodes = {
      value: "",
    },
    pricingOrNoValueForMoney = {
      value: "",
    },
    transactionsWereDifficultToTrack = {
      value: "",
    },
    otherReasons = {
      value: "",
    },
    otherReasonsDescription = {
      value: "",
    },
  } = state;
  return {
    poorCrewAssistanceAtStation: {
      name: "poorCrewAssistanceAtStation",
      ...poorCrewAssistanceAtStation,
    },
    portalWasDifficultToUse: {
      name: "portalWasDifficultToUse",
      ...portalWasDifficultToUse,
    },
    problemsWithFuelCodes: {
      name: "problemsWithFuelCodes",
      ...problemsWithFuelCodes,
    },
    pricingOrNoValueForMoney: {
      name: "pricingOrNoValueForMoney",
      ...pricingOrNoValueForMoney,
    },
    transactionsWereDifficultToTrack: {
      name: "transactionsWereDifficultToTrack",
      ...transactionsWereDifficultToTrack,
    },
    otherReasons: {
      name: "otherReasons",
      ...otherReasons,
    },
    otherReasonsDescription: {
      name: "otherReasonsDescription",
      multiline: true,
      maxLength: 255,
      rowsMax: 4,
      placeholder: locale.tellUsMoreToImprove,
      ...otherReasonsDescription,
    },
  };
};
