import { Field, Modal, TextField, ActionButton, Autocomplete, Text } from "components/commons";
import { useApi, useForm, useModal } from "hooks";
import React, { useCallback, useMemo } from "react";
import { initialState as formState } from "./user-access-form.state";
import styles from "./user-access.module.scss";
import { ConfirmModal, SuccessModal } from "components/modals";
import locale from "localization";
import { useEffect } from "react";
import { handleRequest } from "utils";
import { createUser, updateUserRole } from "apis/profile.api";
import { useContext } from "react";
import { FleetContext } from "contexts";
import { prettifyRole } from "utils/pretty.utils";
import { SuccessActivateAccount } from "images";
import { Role, PLBTypes } from "enums";
import Switch from "@material-ui/core/Switch";

const UserAccessForm = ({
  initialState,
  actionText,
  isEdit,
  fullName,
  currentFA,
  refreshPage,
  ...state
}) => {
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;
  const { close } = state;
  const successModal = useModal();
  const confirmModal = useModal();
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const accessTypes = useMemo(() => {
    if (fleet.plbType === PLBTypes.PLB_HEDGING) {
      return [
        {
          label: prettifyRole(Role.PortalAdmin),
          value: Role.PortalAdmin,
        },
        {
          label: prettifyRole(Role.Purchaser),
          value: Role.Purchaser,
        },
        {
          label: prettifyRole(Role.FleetManager),
          value: Role.FleetManager,
        },
        {
          label: prettifyRole(Role.DataAnalyst),
          value: Role.DataAnalyst,
        },
      ];
    }

    return [
      {
        label: prettifyRole(Role.PortalAdmin),
        value: Role.PortalAdmin,
      },
      {
        label: prettifyRole(Role.FleetManager),
        value: Role.FleetManager,
      },
      {
        label: prettifyRole(Role.DataAnalyst),
        value: Role.DataAnalyst,
      },
      {
        label: prettifyRole(Role.SeaoilAccountManager),
        value: Role.SeaoilAccountManager,
      },
    ];
  }, [fleet.plbType]);

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    applyFieldErrors,
    validateField,
    clearForm,
    isFormSubmittable,
  } = useForm({
    initialState: form,
  });

  useEffect(() => {
    if (state.active) {
      clearForm();
    }
    //eslint-disable-next-line
  }, [state.active]);

  const createUserRequest = useApi({
    api: createUser,
    handleOwnError: {
      badrequest: true,
    },
  });

  const updateRoleRequest = useApi({
    api: updateUserRole,
    handleOwnError: {
      badrequest: true,
    },
  });

  const handleChangeTwoFactorAuth = useCallback(
    (event) => {
      modifyField("twoFactorAuth", { value: event.target.checked });
    },
    [modifyField]
  );

  const loading = isEdit ? updateRoleRequest.loading : createUserRequest.loading;
  const params = getFormValues();

  const submit = async () => {
    let modalContent = {
      title: locale.saveDetailsAndSendEmail,
      content: (
        <locale.Populate
          text={locale.uponClickingSaveAndSendEmail}
          items={[<b>{params.email}</b>, <b>{prettifyRole(params.role)}</b>]}
        />
      ),
    };

    let requestUrl = createUserRequest;
    let requestParams = { fleetId, email: params.email, role: params.role };
    let confirmMessage = locale.saveAndSendEmail;
    if (isEdit) {
      modalContent = {
        title:
          params.twoFactorAuth === currentFA
            ? locale.saveChangesQuestion
            : params.twoFactorAuth
            ? locale.activateTwoFactorAuth
            : locale.turnOffTwoFactorAuth,
        content:
          params.twoFactorAuth === currentFA ? (
            <div>
              <locale.Populate
                text={locale.youAreAboutChangeRoleUser}
                items={[<b>{params.email}</b>, <b>{prettifyRole(params.role)}</b>]}
              />
            </div>
          ) : params.twoFactorAuth ? (
            <div>
              <locale.Populate
                text={locale.areYouSureYouWantToEnableTwoFactorAuth}
                items={[<b>{fullName}</b>]}
              />
            </div>
          ) : (
            <div>
              <locale.Populate
                text={locale.areYouSureYouWantToTurnOffTwoFactorAuth}
                items={[<b>{fullName}</b>]}
              />
            </div>
          ),
      };

      requestUrl = updateRoleRequest;
      requestParams = {
        userAccessId: params.userAccessId,
        email: params.email,
        role: params.role,
        isFaEnabled: params.twoFactorAuth,
      };
      confirmMessage =
        params.twoFactorAuth === currentFA
          ? locale.saveChanges
          : params.twoFactorAuth
          ? locale.yesActivate
          : locale.yesTurnOff;
    }

    confirmModal.show({
      ...modalContent,
      primary: {
        text: confirmMessage,
        onClick: async () => {
          handleRequest(
            async () => {
              await requestUrl.request(requestParams, () => submit());
              confirmModal.close();
              if (isEdit) {
                SuccessConfirm();
              } else {
                successModal.show();
              }

              refreshPage(isEdit);
            },
            {
              F1004: () => {
                confirmModal.close();
                applyFieldErrors({
                  email: locale.emailAddressExist,
                });
              },
              F1002: () => {
                confirmModal.close();
                applyFieldErrors({
                  email: locale.emailAddressExist,
                });
              },
            }
          );
        },
      },
      secondary: {
        text: locale.cancel,
        onClick: async () => {
          confirmModal.close();
        },
      },
    });
  };

  const SuccessConfirm = () => {
    confirmModal.show({
      image: "",
      title: locale.exclamatedSuccess,
      content:
        params.twoFactorAuth === currentFA ? (
          <div>
            <locale.Populate text={locale.successfullyUpdateUser} items={[<b>{params.email}</b>]} />
          </div>
        ) : params.twoFactorAuth ? (
          <div>
            <locale.Populate
              text={locale.youHaveSuccessfullyActivatedTwoFactorAuth}
              items={[<b>{fullName}</b>]}
            />
          </div>
        ) : (
          <div>
            <locale.Populate
              text={locale.successTurnedOffTwoFactorAuth}
              items={[<b>{fullName}</b>]}
            />
          </div>
        ),
      primary: {
        text: locale.gotIt,
        onClick: () => {
          confirmModal.close();
        },
      },
    });
  };

  return (
    <div>
      <ConfirmModal {...confirmModal} loading={loading} />
      <SuccessModal
        image={SuccessActivateAccount}
        title={locale.success}
        content={
          isEdit
            ? locale.detailHaveBeenSuccessfullyUpdated
            : locale.anAccountActivationInstructionHasBeenSent
        }
        {...successModal}
        primary={{
          text: isEdit ? locale.updateAccessType : locale.addAnotherUser,
          onClick: () => {
            successModal.close();
            clearForm();
          },
        }}
        secondary={{
          text: locale.goToListOfUsers,
          onClick: () => {
            close();
            successModal.close();
          },
        }}
      />
      <Modal close={loading ? false : close} {...state} className={styles.modalContent}>
        <div className={styles.formContent}>
          <div className={styles.content}>
            {!isEdit && (
              <Field {...fields.email} className={styles.inputDiv}>
                <TextField
                  {...fields.email}
                  onChange={modifyField}
                  fontColor={"#753BBD"}
                  onBlur={validateField}
                />
              </Field>
            )}
            {isEdit && (
              <Field {...fields.email} className={styles.inputDiv}>
                <Text className={styles.subtitle}>{params.email}</Text>
              </Field>
            )}
            <Field {...fields.role} className={styles.inputDiv}>
              <Autocomplete {...fields.role} options={accessTypes} onChange={modifyField} />
            </Field>
            {isEdit && (
              <Field {...fields.twoFactorAuth} className={styles.inputDivAuth}>
                <Switch
                  color="primary"
                  checked={fields?.twoFactorAuth?.value}
                  onChange={handleChangeTwoFactorAuth}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Field>
            )}
          </div>
        </div>
        <ActionButton
          right
          items={[
            {
              onClick: () => {
                state.close();
              },
              disabled: loading,
              text: locale.cancel,
            },
            {
              onClick: () => {
                submitForm(submit);
              },
              disabled: !isFormSubmittable,
              loading,
              text: locale.save,
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default UserAccessForm;
