import { getBuyFuelHistory, generateReport } from "apis";
import { BackLink, Intro, DataTable, Product } from "components/commons";
import { FleetContext } from "contexts";
import { Path, Products } from "enums";
import { useApi, useFilter, useModal, useMount } from "hooks";
import React, { useCallback, useMemo, useContext } from "react";
import { fuelHistoryColumns } from "./fuel-history-columns";
import FuelHistoryFilter from "./fuel-history-filter";
import { fuelHistoryState } from "./fuel-history-filter.state";
import styles from "./fuel-history.module.scss";
import { prettifyProduct } from "utils/pretty.utils";
import locale from "localization";
import { dateTimeFormat, formatAmount, formatVolume } from "utils";
import { SuccessModal } from "components/modals";

const FuelHistoryModule = () => {
  const successModal = useModal();
  const { fleet } = useContext(FleetContext);

  const { modifyFilter, modifyFilters, filterState, requestState, submitFilter } = useFilter(
    fuelHistoryState(fleet.fleetId)
  );

  const {
    request: getFuelHistoryRequest,
    loading: fetchingFuelHistory,
    result: getBuyFuelHistoryResult = { buyFuels: [], count: 0 },
  } = useApi({
    api: getBuyFuelHistory,
    pageError: true,
  });

  const { request: generateReportRequest, loading: fetchingReport } = useApi({
    api: generateReport,
    pageError: true,
  });

  useMount(() => {
    fetchBuyFuelHistory(requestState);
  });

  const fetchBuyFuelHistory = useCallback(
    (rs) => {
      getFuelHistoryRequest(submitFilter(rs));
    },
    [getFuelHistoryRequest, submitFilter]
  );

  const preparedFuelHistoryData = useMemo(() => {
    const { buyFuels } = getBuyFuelHistoryResult;
    if (buyFuels.length > 0) {
      const preparedData = buyFuels.map((b) => {
        const map = new Map();
        map.set("buyFuelId", b.buyFuelId);
        map.set("purchasedDate", dateTimeFormat(b.createdAt));
        map.set(
          "product",
          <Product
            grass={b.productCode === Products.Gas91}
            salsa={b.productCode === Products.Gas95}
            deepBlue={b.productCode === Products.Gas97}
            cheddar={b.productCode === Products.Diesel}
          >
            {prettifyProduct(b.productCode)}
          </Product>
        );
        map.set("creditPrice", formatAmount(b.creditPrice));
        map.set("volume", formatVolume(b.literVolume));
        map.set("totalCreditAmount", formatAmount(b.totalCreditAmount));

        return map;
      });
      return preparedData;
    }
    return [];
  }, [getBuyFuelHistoryResult]);

  const onDateRangeCb = useCallback(
    (value, dirty) => {
      const { startDate, endDate } = value;
      const { requestState } = modifyFilters({ startDate, endDate, page: 1 });
      fetchBuyFuelHistory(requestState);
    },
    [modifyFilters, fetchBuyFuelHistory]
  );

  const onProductChangeCb = useCallback(
    (productCode) => {
      const { requestState } = modifyFilters({ productCode, page: 1 });
      fetchBuyFuelHistory(requestState);
    },
    [modifyFilters, fetchBuyFuelHistory]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      fetchBuyFuelHistory(requestState);
    },
    [modifyFilters, fetchBuyFuelHistory]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchBuyFuelHistory(requestState);
    },
    [modifyFilters, fetchBuyFuelHistory]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchBuyFuelHistory(requestState);
    },
    [modifyFilters, fetchBuyFuelHistory]
  );

  const onDownloadFileCb = async () => {
    const { requestState } = modifyFilters({ reportType: "buy-fuel" });
    delete requestState.perPage;
    delete requestState.page;
    const generateResult = await generateReportRequest(submitFilter(requestState));
    if (generateResult?.downloadUrl) {
      window.open(generateResult?.downloadUrl, "_blank");
    } else {
      successModal.show({
        title: locale.successTitle,
        content: locale.reportGenerated,
        // contentHighlight: [<strong>{user?.email}</strong>, <strong>business@pricelocq.com</strong>],
      });
    }
  };

  return (
    <div>
      <SuccessModal {...successModal} />
      <div>
        <BackLink text={locale.buyFuel} path={Path.BuyFuel} />
        <Intro title={locale.buyFuelHistory} subtitle="" />
      </div>
      <div className={styles.filters}>
        <FuelHistoryFilter
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onProductChange={onProductChangeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          onDownloadFile={onDownloadFileCb}
          fetchingReport={fetchingReport}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingFuelHistory}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={fuelHistoryColumns}
          data={preparedFuelHistoryData}
          dataCount={getBuyFuelHistoryResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
    </div>
  );
};

export default FuelHistoryModule;
