import classNames from "classnames";
import React from "react";
import styles from "./text.module.scss";

const Text = ({
  children,
  label,
  header,
  className,
  subtitle,
  align,
  strong,
  italic,
  colored,
  bolder,
  strike,
  style,
  link,
}) => {
  const classes = classNames(styles.text, className, {
    [`${styles.label}`]: label,
    [`${styles.subtitle}`]: subtitle,
    [`${styles.strong}`]: strong,
    [`${styles.italic}`]: italic,
    [`${styles.colored}`]: colored,
    [`${styles.header}`]: header,
    [`${styles.bolder}`]: bolder,
    [`${styles.strike}`]: strike,
    [`${styles.link}`]: link,
  });

  return (
    <p style={{ textAlign: align, ...style }} className={classes}>
      {children}
    </p>
  );
};

export default Text;
