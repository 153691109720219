import { Path, CashInMethod } from "enums";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

const OnlineBankPage = () => {
  const onlineBankPath = `${Path.Redirect}${Path.Brankas}`;
  const successPath = `${onlineBankPath}${Path.Success}`;
  const failurePath = `${onlineBankPath}${Path.Failed}`;
  const cancelPath = `${onlineBankPath}${Path.Cancel}`;

  const location = useLocation();

  const paths = location.pathname.split("/");
  const cashInId = paths[5];

  return (
    <Switch>
      <Route path={successPath}>
        <CashInWithStatusComponent
          status={"success"}
          cashInId={cashInId}
          paymentMethod={CashInMethod.OnlineBanking}
        />
      </Route>
      <Route path={failurePath}>
        <CashInWithStatusComponent
          status={"failed"}
          cashInId={cashInId}
          paymentMethod={CashInMethod.OnlineBanking}
        />
      </Route>
      <Route path={cancelPath}>
        <CashInWithStatusComponent
          status={"cancel"}
          cashInId={cashInId}
          paymentMethod={CashInMethod.OnlineBanking}
        />
      </Route>
    </Switch>
  );
};

const CashInWithStatusComponent = ({ status, cashInId, paymentMethod }) => {
  return (
    <Redirect
      to={{
        pathname: `${Path.CashIn}`,
        state: { cashInStatus: status, cashInId, paymentMethod },
      }}
    />
  );
};

export default OnlineBankPage;
