import React, { useCallback } from "react";
import styles from "./dashboard-filter.module.scss";
import { Dropdown, DateRangePicker } from "components/commons";
import locale from "localization";
import { PriceMovementGraphType } from "enums";

const DashboardFilter = ({ filterState, onGraphTypeChange, onDateRangeChange, earliestRecord }) => {
  const onDateRangeChangeCb = useCallback(
    (name, value) => {
      if (onDateRangeChange) {
        return onDateRangeChange(value);
      }
    },
    [onDateRangeChange]
  );
  const onGraphTypeChangeCb = useCallback(
    (value) => {
      if (onGraphTypeChange) return onGraphTypeChange(value);
    },
    [onGraphTypeChange]
  );

  return (
    <div className={styles.filter}>
      <Dropdown
        className={styles.graphType}
        value={filterState.graphType}
        options={[
          // { label: locale.contractPrices, value: PriceMovementGraphType.contractPrices },
          { label: locale.priceMovements, value: PriceMovementGraphType.priceMovements },
          {
            label: locale.priceMovementsCumulative,
            value: PriceMovementGraphType.priceMovementsCumulative,
          },
        ]}
        onChange={onGraphTypeChangeCb}
      />
      <DateRangePicker
        startDate={filterState.startDate}
        endDate={filterState.endDate}
        onChange={onDateRangeChangeCb}
        minDate={earliestRecord}
        isRangeDisablerActive={true}
      />
    </div>
  );
};

export default DashboardFilter;
