import locale from "localization";
import { Text } from "components/commons";
import styles from "./manual-bank-form.module.scss";


const ManualBanking = ({
  title,
}) => {
  

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Text header bolder>
          {title || locale.cashInProcessInstruction}
        </Text>
      </div>
      <div className={styles.description}>
        <Text>{locale.makeYourManualDeposit}</Text>
      </div>
     <div className={styles.indent}>
      <div className={styles.title}>
          <Text header bolder>
            {locale.accountName}
          </Text>
        </div>
        <div className={styles.description}>
          <Text>{locale.locqInc}</Text>
        </div>
        <div className={styles.title}>
          <Text header bolder>
            {locale.bankNameAcctNumber}
          </Text>
        </div>
        <div className={styles.description}>
          <span className={styles.acctnum}>{locale.bdo_short}</span><span >{locale.bdoAcctNum}</span>
        </div>
        <div className={styles.description}>
          <span className={styles.acctnum}>{locale.ubp_short}</span><span >{locale.ubpAcctNum}</span>
        </div>
        <div className={styles.description}>
          <span className={styles.bpi}>{locale.bpi_short}</span><span >{locale.bpiAcctNum}</span>
        </div>
     </div>
      <div className={styles.description}>
        <Text>{<locale.Populate
                text={locale.sendUsProof}
                items={[<strong>{"business@locq.com"}</strong>]}
              />}</Text>
      </div>
      <div className={styles.description}>
        <Text>{locale.kindlyWaitForTheStatus}</Text>
      </div>
      <div className={styles.description}>
        <Text>{locale.onceCleared}</Text>
      </div>
      <div className={styles.description}>
        <Text>{<locale.Populate
                text={locale.pleaseNote50deduction}
                items={[<strong>{"Php 50.00"}</strong>]}
              />}</Text>
      </div>
    </div>
  );
};

export default ManualBanking;
