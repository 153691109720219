import locale from "localization";

export const priceHistoryColumns = [
  { key: "effectiveAt", text: locale.effectiveDate, width: "15%" },
  { key: "product", text: locale.product, width: "10%" },
  { key: "pumpPrice", text: locale.basePrice, width: "8%" },
  { key: "priceMovement", text: locale.priceMovement, width: "10%" },
  { key: "discount", text: locale.discount, width: "8%" },
  { key: "creditPrice", text: locale.contractPrice, width: "10%" },
  { key: "movementType", text: locale.priceMovementType, width: "20%" },
];
