import { getDrivers } from "apis";
import { Intro, DataTable, Pill, Image, PopOverMenu, Button } from "components/commons";
import { FleetContext, AnalyticsContext, UserContext } from "contexts";
import { DriverStatus, Path, FleetStatus, Page, UserAccess, Role } from "enums";
import { useApi, useFilter, useMount } from "hooks";
import useModal from "hooks/useModal";
import { DefaultProfile } from "images";
import React, { useCallback, useMemo, useContext } from "react";
import { formatName, prettifyDriverStatus } from "utils";
import { driverColumns } from "./driver-columns";
import DriverFilter from "./driver-filter";
import { driverFilterState } from "./driver-filter.state";
import DriverForm from "./driver-form";
import DriverStatusModal from "./driver-status-modal";
import styles from "./driver.module.scss";
import locale from "localization";
import { useHistory } from "react-router-dom";
import ImportDriverModal from "./import-driver";
import { importDriver } from "apis/driver.api";

const DriverModule = () => {
  const { page } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { userAccess } = useContext(UserContext);
  const { fleetId, status } = fleet;
  const deactivated = status === FleetStatus.Deactivated;
  const statusModal = useModal();
  const importDriverModal = useModal();
  const history = useHistory();

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.Driver;
  });

  const { modifyFilter, modifyFilters, filterState, requestState, submitFilter } = useFilter(
    driverFilterState(fleetId)
  );
  const driverFormModal = useModal();

  const {
    request: getDriversRequest,
    loading: fetchingDrivers,
    result: getDriversResult = { drivers: [], count: 0 },
  } = useApi({
    api: getDrivers,
    pageError: true,
    params: {
      fleetId,
    },
  });

  const importRequest = useApi({
    api: importDriver,
  });

  useMount(() => {
    fetchDrivers(requestState);
    page({
      name: Page.ViewManageFleetDrivers,
    });
  });

  const fetchDrivers = useCallback(
    (rs) => {
      getDriversRequest(submitFilter({ ...rs }));
    },
    [getDriversRequest, submitFilter]
  );

  const changeStatusCb = useCallback(
    (driverId, driverName, status) => {
      statusModal.show({ status, driverId, driverName });
    },
    [statusModal]
  );

  const preparedDriversData = useMemo(() => {
    const { drivers } = getDriversResult;
    if (drivers.length > 0) {
      const preparedData = drivers.map((d) => {
        const map = new Map();
        const driverName = formatName(d.firstName, d.lastName);
        map.set("avatar", <Image src={DefaultProfile} className={styles.avatar} />);
        map.set("name", driverName);
        map.set("driverLicenseId", d.driverLicenseId);
        map.set("mobileNumber", d.mobileNumber);
        map.set(
          "status",
          <Pill
            grass={d.status === DriverStatus.Active}
            cheddar={d.status === DriverStatus.Inactive}
          >
            {prettifyDriverStatus(d.status)}
          </Pill>
        );
        let options = [];
        let viewRedemption;
        let editDetails;
        let deactivateUser;
        if (getUserAccess?.fullAccess || getUserAccess?.viewAccess) {
          if (getUserAccess?.role !== Role.SeaoilAccountManager) {
            viewRedemption = {
              content: locale.viewRedemptions,
              onClick: () => {
                history.push(Path.Redemption, { searchKey: d.driverLicenseId });
              },
            };

            options.push(viewRedemption);
          }
        }

        if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
          editDetails = {
            content: locale.editDetails,
            onClick: () => {
              driverFormModal.show({
                title: locale.editDetails,
                actionText: locale.save,
                create: false,
                driverId: d.driverId,
                initialState: {
                  firstName: {
                    value: d.firstName,
                  },
                  lastName: {
                    value: d.lastName,
                  },
                  driverLicenseId: {
                    value: d.driverLicenseId,
                    disabled: true,
                  },
                  mobileNumber: {
                    value: d.mobileNumber,
                  },
                  status: {
                    value: d.status,
                  },
                },
              });
            },
            disabled: deactivated,
          };
          options.push(editDetails);
        }

        if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
          deactivateUser = {
            content: d.status === DriverStatus.Active ? locale.deactivate : locale.activate,
            onClick: () => {
              changeStatusCb(d.driverId, driverName, d.status);
            },
            disabled: deactivated,
          };
          options.push(deactivateUser);
        }

        map.set("options", <PopOverMenu options={options} />);
        return map;
      });
      return preparedData;
    }
    return [];
  }, [getDriversResult, changeStatusCb, driverFormModal, history, deactivated, getUserAccess]);

  const onStatusChangeCb = useCallback(
    (status) => {
      const { requestState } = modifyFilters({ status, page: 1 });
      fetchDrivers(requestState);
    },
    [fetchDrivers, modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      fetchDrivers(requestState);
    },
    [fetchDrivers, modifyFilters]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchDrivers(requestState);
    },
    [fetchDrivers, modifyFilters]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchDrivers(requestState);
    },
    [fetchDrivers, modifyFilters]
  );

  return (
    <div>
      <DriverForm
        {...driverFormModal}
        refreshPage={() => {
          getDriversRequest(requestState);
        }}
      />
      <div>
        <Intro
          title={locale.manageFleetDrivers}
          subtitle={locale.manageYourDrivers}
          actionText={
            getUserAccess?.fullAccess || getUserAccess?.modifyAccess ? locale.addDriver : ""
          }
          actionOnClick={() => {
            driverFormModal.show({
              title: locale.addDriver,
              actionText: locale.add,
              create: true,
              initialState: {
                firstName: {
                  value: "",
                },
                lastName: {
                  value: "",
                },
                driverLicenseId: {
                  value: "",
                  disabled: false,
                },
                mobileNumber: {
                  value: "",
                },
              },
            });
          }}
          actionDisabled={deactivated}
        />
      </div>
      <div className={styles.filters}>
        <div>
          <DriverFilter
            filterState={filterState}
            onStatusChange={onStatusChangeCb}
            onSearchChange={modifyFilter}
            onSearch={onSearchCb}
          />
        </div>
        <div>
          {(getUserAccess?.fullAccess || getUserAccess?.modifyAccess) && (
            <Button
              exportButton
              onClick={() => {
                importDriverModal.show({});
              }}
            >
              {locale.import}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingDrivers}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={driverColumns}
          data={preparedDriversData}
          dataCount={getDriversResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>

      <ImportDriverModal
        {...importDriverModal}
        request={importRequest}
        refresh={() => {
          fetchDrivers({
            ...requestState,
            page: 1,
          });
        }}
      />

      <DriverStatusModal refetchDrivers={() => fetchDrivers(requestState)} {...statusModal} />
    </div>
  );
};

export default DriverModule;
