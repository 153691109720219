const FleetCardStatus = {
  Active: "active",
  Inactive: "inactive",
  Terminated: "terminated",
  Deactivated: "deactivated",
  Scheduled: "scheduled",
  Expired: "expired",
  Redeemed: "redeemed"
};

export default FleetCardStatus;
