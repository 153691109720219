import React from "react";
import { ExternalLink, Event } from "enums";
import { Image, Button } from "components/commons";
import { ManualCashinInstruction } from "images";
import styles from "./cashin-over-the-counter.module.scss";
import locale from "localization";

const CashInOverTheCounter = () => {
  return (
    <div>
      <div className={styles.instructionContent}>
        <Image src={ManualCashinInstruction} className={styles.imgContent} />
      </div>
      <div className={styles.formLink}>
        <Button
          primary
          onClick={() => window.open(ExternalLink.ManualCashInForm, "_blank")}
          eventName={Event.CashInOverTheCounter}
        >
          {locale.proceedCashInRequestForm}
        </Button>
      </div>
    </div>
  );
};

export default CashInOverTheCounter;
