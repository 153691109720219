import React, { useCallback } from "react";
import { Checkbox as MaterialCheckbox } from "@material-ui/core";
import styles from "./checkbox.module.scss";
import classNames from "classnames";

const Checkbox = ({
  value = false,
  onChange,
  name,
  disabled,
  contents = null,
  style,
  icon,
  checkedIcon,
}) => {
  const onChangeCb = useCallback(() => {
    if (onChange) {
      onChange(name, {
        value: !value,
        data: contents,
      });
    }
  }, [name, value, onChange, contents]);

  return (
    <div
      className={classNames({
        [`${styles.disabled}`]: disabled,
      })}
    >
      <MaterialCheckbox
        color="default"
        className={styles.checkbox}
        checked={value === "indeterminate" ? null : value}
        indeterminate={value === "indeterminate"}
        disabled={disabled}
        onChange={onChangeCb}
        icon={icon}
        checkedIcon={checkedIcon}
        sx={style}
      />
    </div>
  );
};

export default Checkbox;
