import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    otp = {
      value: "",
      disabled: false,
    },
  } = state;
  return {
    otp: {
      name: "otp",
      validations: [
        Validation.required({ noMessage: true }, Validation.numeric({ noMessage: true })),
      ],
      maxLength: 8,
      required: true,
      ...otp,
    },
  };
};
