import { Products } from "enums";
import FleetCardStatus from "enums/fleet-card-status";
import FuelCodeType from "enums/fuel-code-type";
export const fleetCardManagementFilterState = (fleetId, location) => {
  const { state } = location || {};
  const { searchKey = "" } = state || {};
  return {
    fleetId,
    startDate: null,
    endDate: null,
    fuelCardStatuses: [
      FleetCardStatus.Active,
      FleetCardStatus.Deactivated,
      FleetCardStatus.Terminated,
    ],
    fuelCardTypes: [
      FuelCodeType.DriverSpecific,
      FuelCodeType.VehicleSpecific,
      FuelCodeType.DriverVehicleSpecific,
      FuelCodeType.AnyDriverAnyVehicle,
    ],
    productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97, Products.Lubes],
    searchKey,
    page: 1,
    perPage: 10,
  };
};
