import { Text, Product } from "components/commons";
import React from "react";
import { Products } from "enums";
import { formatVolume } from "utils";
import { prettifyProduct } from "utils/pretty.utils";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "./consumption-card.module.scss";

const ConsumptionCard = ({ balance, type, loading, clickProduct }) => {
  return (
    <div
      className={styles.consumptionCard}
      onClick={() => {
        clickProduct(type);
      }}
    >
      <div className={styles.productHeader}>
        {loading ? (
          <Skeleton variant="rect" className={styles.loaderProduct} />
        ) : (
          <>
            <Product
              classNameContainer={styles.productColor}
              cheddar={Products.Diesel === type}
              grass={Products.Gas91 === type}
              salsa={Products.Gas95 === type}
              deepBlue={Products.Gas97 === type}
              clickProduct={clickProduct}
            />
            <Text className={styles.productName}>{`${prettifyProduct(type)}`}</Text>
          </>
        )}
      </div>
      <div className={styles.nameBalance}>
        {loading ? (
          <Skeleton variant="rect" className={styles.loaderAmount} />
        ) : (
          <Text className={styles.title}>
            {formatVolume(balance, "")}
            <span className={styles.literSign}>L</span>
          </Text>
        )}
      </div>
    </div>
  );
};

export default ConsumptionCard;
