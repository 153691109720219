import { Field, TextField, ActionButton, Checkbox } from "components/commons";
import { SelectLoanProduct, SelectRegion, SelectBranch } from "components/field";
import { useApi, useForm, useModal } from "hooks";
import React, { useMemo } from "react";
import { initialState as formState } from "./loans-form.state";
import styles from "./loans-form.module.scss";
import { createLoan } from "apis/loan.api";
import { useContext } from "react";
import { AnalyticsContext, FleetContext, UserContext } from "contexts";
import locale from "localization";
import { useEffect } from "react";
import { handleRequest } from "utils";
import LoansSuccessModal from "./loans-success.modal";
import { Event } from "enums";

const LoansForm = ({ initialState, actionText, create, refreshPage, ...state }) => {
  const { track } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { user } = useContext(UserContext);
  const statusModal = useModal();
  const { fleetId } = fleet;
  const { firstName, lastName } = user;
  const form = useMemo(() => {
    return formState({
      ...initialState,
      firstName: {
        value: firstName || "",
      },
      lastName: {
        value: lastName || "",
      },
    });
  }, [initialState, firstName, lastName]);

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    validateField,
    clearForm,
    isFormSubmittable,
    modifyForm,
  } = useForm({
    initialState: form,
  });

  useEffect(() => {
    if (state.active) {
      clearForm();
    }
  }, [clearForm, state.active]);

  const createLoanRequest = useApi({
    api: createLoan,
    handleOwnError: {
      badrequest: true,
    },
  });

  const loading = createLoanRequest.loading;

  const submit = async () => {
    const formValues = getFormValues();

    const params = {
      fleetId: fleetId,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      mobileNumber: formValues.contactNumber,
      email: formValues.email,
      loanProduct: formValues.loanProduct,
      region: formValues.region,
      branch: formValues.branch,
    };

    handleRequest(async () => {
      const finalParams = {
        ...params,
        fleetId,
      };
      await createLoanRequest.request(finalParams, () => submit());
      track(Event.VehicleFinancingLoan, finalParams);
      statusModal.show();
      clearForm();
    });
  };

  const handleRegionChange = (name, { value }) => {
    modifyForm({
      [name]: {
        value,
      },
      branch: {
        value: null,
      },
    });
  };

  return (
    <div className={styles.main}>
      <div className={styles.formContent}>
        <div className={styles.subTitle}>
          {locale.findingTheRightLoan}{" "}
          <a href="https://gdfi.com.ph/#loan-products">
            <span>See Loan Options</span>{" "}
          </a>
        </div>

        <div className={styles.content}>
          <Field {...fields.firstName}>
            <TextField {...fields.firstName} onChange={modifyField} onBlur={validateField} />
          </Field>
          <Field {...fields.lastName}>
            <TextField {...fields.lastName} onChange={modifyField} onBlur={validateField} />
          </Field>
          <Field {...fields.contactNumber}>
            <TextField
              {...fields.contactNumber}
              onChange={modifyField}
              onBlur={validateField}
              upperCase
            />
          </Field>
          <Field {...fields.email}>
            <TextField {...fields.email} onChange={modifyField} onBlur={validateField} />
          </Field>
          <Field label={locale.loanProduct}>
            <SelectLoanProduct
              name="loanProduct"
              {...fields.loanProduct}
              onChange={modifyField}
              value={fields.loanProduct.value || null}
            />
          </Field>
          <Field label={locale.chooseRegion}>
            <SelectRegion
              name="region"
              {...fields.region}
              onChange={handleRegionChange}
              value={fields.region.value || null}
            />
          </Field>
          <Field label={locale.chooseBranch}>
            <SelectBranch
              name="branch"
              {...fields.branch}
              onChange={modifyField}
              value={fields.branch.value || null}
              filterState={fields.region}
            />
          </Field>
        </div>
        <div className={styles.privacy}>
          <Checkbox
            name="dominionPrivacy"
            value={fields.dominionPrivacy.value || null}
            onChange={modifyField}
          />
          <span>
            {locale.dominionPrivacyPolicy}{" "}
            <a href="https://gdfi.com.ph/privacy-policy/">
              <span>Privacy Policy.</span>
            </a>
          </span>
        </div>
        <div className={styles.privacy}>
          <Checkbox
            name="locqPrivacy"
            value={fields.locqPrivacy.value || null}
            onChange={modifyField}
          />
          <span>
            {locale.locqPrivacyPolicy}{" "}
            <a href="https://pricelocq.com/policy">
              <span>Privacy Policy.</span>{" "}
            </a>
          </span>
        </div>
        <div className={styles.pending}>
          {locale.forPendingInquiries}{" "}
          <span onClick={() => window.open("mailto:accountcare@gdfi.com.ph")}>
            accountcare@gdfi.com.ph
          </span>
        </div>
      </div>
      <ActionButton
        left
        items={[
          {
            onClick: () => {
              submitForm(submit);
            },
            disabled: !isFormSubmittable,
            loading,
            text: actionText,
            primary: true,
          },
        ]}
      />

      <LoansSuccessModal
        data={{ inquiryId: createLoanRequest.result && createLoanRequest.result.loanId }}
        {...statusModal}
      />
    </div>
  );
};

export default LoansForm;
