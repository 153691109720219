import Validation from "utils/validation.utils";
import locale from "localization";
import { DriverStatus } from "enums";

export const initialState = (state = {}) => {
  const {
    firstName = {
      value: "",
      disabled: false,
    },
    lastName = {
      value: "",
      disabled: false,
    },
    driverLicenseId = {
      value: "",
      disabled: false,
    },
    mobileNumber = {
      value: "",
      disabled: false,
    },
    status = {
      value: DriverStatus.Active,
    },
  } = state;
  return {
    firstName: {
      name: "firstName",
      placeholder: locale.enterFirstNameHere,
      validations: [Validation.required({ noMessage: true }), Validation.name()],
      maxLength: 75,
      label: locale.firstName,
      required: true,
      ...firstName,
    },
    lastName: {
      name: "lastName",
      placeholder: locale.enterLastNameHere,
      validations: [Validation.required({ noMessage: true }), Validation.name()],
      maxLength: 75,
      label: locale.lastName,
      required: true,
      ...lastName,
    },
    driverLicenseId: {
      name: "driverLicenseId",
      placeholder: "IDxxxxxxxxx",
      validations: [
        Validation.required({ noMessage: true }),
        Validation.alphaNumeric(),
        Validation.minlength(11, "Driver ID must be 11 characters."),
        // Validation.driverLicenseId({
        //   customMessage: locale.pleaseEnterAValidLicensedId,
        //   removeLast: true,
        // }),
      ],
      maxLength: 11,
      label: locale.driverId,
      upperCase: true,
      required: true,
      ...driverLicenseId,
    },
    mobileNumber: {
      name: "mobileNumber",
      placeholder: "09xxxxxxxxx",
      maxLength: 11,
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.numeric(),
        Validation.mobileNumber(),
      ],
      label: locale.mobileNumber,
      ...mobileNumber,
    },
    status: {
      name: "status",
      ...status,
    },
  };
};
