import React from "react";
import { Button } from "..";
import styles from "./action-button.module.scss";
import classNames from "classnames";

const ActionButton = ({ items = [], left, right, loading, center }) => {
  const defaultAction = {
    0: {
      text: "",
      onClick: () => {},
    },
    1: {
      text: "",
      onClick: () => {},
      primary: true,
    },
  };
  return (
    <div
      className={classNames(styles.container, {
        [`${styles.left}`]: left,
        [`${styles.right}`]: right,
        [`${styles.center}`]: center,
      })}
    >
      {items?.map((item, key) => {
        let button = { ...item };
        if (defaultAction[key]) {
          button = { ...defaultAction[key], ...button };
        }
        const { text } = button;
        return (
          <Button
            loading={loading && button.primary}
            key={key}
            className={styles.button}
            {...button}
          >
            {text}
          </Button>
        );
      })}
    </div>
  );
};

export default ActionButton;
