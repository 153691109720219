const timerIds = [];

export const debounced = (fn, delay) => {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      // trigger function only on last hit
      if (timerIds[timerIds.length - 1] === timerId) {
        fn(...args);
      }
      timerId = null;
    }, delay);
    timerIds.push(timerId);
  };
};

export const handleRequest = async (t = async () => {}, e = {}, customError = null) => {
  try {
    await t();
  } catch (err) {
    if (customError && typeof customError === "function") {
      return customError(err);
    }
    const { data = {} } = err;
    const { errorCode } = data;

    if (e?.[errorCode]) {
      return e[errorCode]();
    }
    if (err.showError) {
      return err.showError();
    }
  }
};
