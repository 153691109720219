import React from "react";
import { Text } from "..";
import SelectPanel from "./select-panel";
import styles from "./select-panel.module.scss";

const SelectPanels = ({ active, items, onClick, className, label, disabled = false }) => {
  return (
    <div className={className}>
      <Text label>{label}</Text>
      <div className={styles.panels}>
        {items.map((item, index) => (
          <SelectPanel
            disabled={disabled}
            key={index}
            {...item}
            classNameContainer={styles.panelItem}
            active={item.value === active || index === active}
            onClick={(prop) => {
              if (onClick) {
                return onClick(prop);
              }
              if (item.onClick) {
                return item.onClick(prop);
              }
            }}
          >
            {item.content}
          </SelectPanel>
        ))}
      </div>
    </div>
  );
};

export default SelectPanels;
