import { Intro, DataTable, Product, Pill, PopOverMenu, Text, Counts } from "components/commons";
import { FleetContext, AnalyticsContext, UserContext } from "contexts";
import { DateTime, FuelCodesStatus, Path, Products, FleetStatus, Page, UserAccess } from "enums";
import { useApi, useFilter, useModal, useMount } from "hooks";
import React, { useCallback, useMemo, useContext } from "react";
import { myFuelCodesColumns } from "./my-fuel-codes-columns";
import MyFuelCodesFilter from "./my-fuel-codes-filter";
import { myFuelCodesState } from "./my-fuel-codes-filter.state";
import styles from "./my-fuel-codes.module.scss";
import { prettifyFuelCodesStatus, prettifyFuelCodeType, prettifyProduct } from "utils/pretty.utils";
import locale from "localization";
import { formatNumber, formatVolume } from "utils";
import moment from "moment";
import MyFuelCodesStatusModal from "./my-fuel-codes-status-modal";
import { getMyFuelCodes, getMyFuelCodesTotal, generateReport, updateFuelCode } from "apis";
import { useHistory } from "react-router-dom";
import EditFuelCodeModal from "../generate-fuel-codes/edit-fuel-code.modal";
import { SuccessModal } from "components/modals";

const MyFuelCodesModule = () => {
  const { page } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { userAccess } = useContext(UserContext);
  const { fleetId, status, fleetStations } = fleet;
  const deactivated = status === FleetStatus.Deactivated;
  const statusModal = useModal();
  const successModal = useModal();
  const history = useHistory();
  const activeProductCode = history?.location?.state?.productCode;

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.MyFuelCode;
  });

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    submitFilter,
    submittedFilter,
    clearFilter,
    filterCount,
  } = useFilter(myFuelCodesState(fleetId));

  const {
    request: getMyFuelCodesRequest,
    loading: fetchingMyFuelCodes,
    result: getMyFuelCodesResult = { count: 0, active: 0 },
  } = useApi({
    api: getMyFuelCodes,
    pageError: true,
  });

  const {
    request: fetchMyFuelCodesCount,
    result: fuelCodesCount = { data: {} },
    loading: fetchingMyFuelCodesCount,
  } = useApi({
    api: getMyFuelCodesTotal,
    params: {
      fleetId: fleetId,
    },
    pageError: true,
  });

  const { request: generateReportRequest, loading: fetchingReport } = useApi({
    api: generateReport,
    pageError: true,
  });

  useMount(() => {
    if (activeProductCode) {
      fromDashboardProduct(activeProductCode);
    } else {
      fetchMyFuelCodes(requestState);
      fetchMyFuelCodesCount();
      page({
        name: Page.ViewMyFuelCodes,
      });
    }
  });

  const fetchMyFuelCodes = useCallback(
    (rs) => {
      getMyFuelCodesRequest(submitFilter(rs));
      fetchMyFuelCodesCount();
    },
    [getMyFuelCodesRequest, submitFilter, fetchMyFuelCodesCount]
  );

  const fromDashboardProduct = useCallback(
    (value) => {
      const { requestState } = modifyFilters({
        productCode: value,
        status: FuelCodesStatus.Active,
        page: 1,
      });
      fetchMyFuelCodes(requestState);
      fetchMyFuelCodesCount();
    },
    [modifyFilters, fetchMyFuelCodes, fetchMyFuelCodesCount]
  );

  const changeStatusCb = useCallback(
    (fuelCodeId, fuelCode, status) => {
      if (status === FuelCodesStatus.Active || status === FuelCodesStatus.Scheduled) {
        statusModal.show({ fuelCodeId, fuelCode });
      }
    },
    [statusModal]
  );
  const editFuelCodeModal = useModal();

  const updateFuelCodeApi = useApi({
    api: updateFuelCode,
  });

  const preparedMyFuelCodesData = useMemo(() => {
    const { fuelCodes } = getMyFuelCodesResult;
    if (fuelCodes && fuelCodes.length > 0) {
      const preparedData = fuelCodes.map((d) => {
        const map = new Map();
        map.set("fuelCode", d.fuelCode);
        map.set("fuelCodeType", prettifyFuelCodeType(d.fuelCodeType));
        map.set(
          "assignedDriver",
          d.drivers.length > 0 ? (
            <div>
              <Text>{`${d.drivers[0].firstName} ${d.drivers[0].lastName}`}</Text>
            </div>
          ) : (
            <Text italic>{locale.any}</Text>
          )
        );
        map.set(
          "assignedVehicle",
          d.vehicles.length > 0 ? (
            <div>
              <Text>{d.vehicles[0]?.plateNumber || ""}</Text>
            </div>
          ) : (
            <Text italic>{locale.any}</Text>
          )
        );
        map.set(
          "product",
          <Product
            grass={d.productCode === Products.Gas91}
            salsa={d.productCode === Products.Gas95}
            deepBlue={d.productCode === Products.Gas97}
            cheddar={d.productCode === Products.Diesel}
          >
            {prettifyProduct(d.productCode)}
          </Product>
        );
        map.set("literVolume", formatVolume(d.literVolume));
        map.set("volumeRedeemed", formatVolume(d.volumeRedeemed));
        map.set("oneTimeUse", d.maxUsage === 1 ? locale.yes : locale.no);
        map.set("sendSms", d.sendSms ? locale.yes : locale.no);
        map.set("autoRenew", d.autoRenew ? locale.yes : locale.no);
        map.set(
          "startDate",
          <div>
            <Text>{moment(d.startDate).format(DateTime.A)}</Text>
            <Text>{moment(d.startDate).format(DateTime.B)}</Text>
          </div>
        );
        map.set(
          "endDate",
          <div>
            <Text>{moment(d.endDate).format(DateTime.A)}</Text>
            <Text>{moment(d.endDate).format(DateTime.B)}</Text>
          </div>
        );
        map.set(
          "createdAt",
          <div>
            <Text>{moment(d.createdAt).format(DateTime.A)}</Text>
            <Text>{moment(d.createdAt).format(DateTime.B)}</Text>
          </div>
        );
        map.set(
          "status",
          <Pill
            sky={d.status === FuelCodesStatus.Scheduled}
            deepRed={d.status === FuelCodesStatus.Deactivated}
            grass={d.status === FuelCodesStatus.Active}
            cement={d.status === FuelCodesStatus.Expired}
            cheddar={d.status === FuelCodesStatus.Redeemed}
          >
            {prettifyFuelCodesStatus(d.status)}
          </Pill>
        );
        let options = [];
        let viewRedemption;
        let deactivateFuelCode;
        if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
          deactivateFuelCode = {
            content: locale.deactivate,
            disabled:
              d.status === FuelCodesStatus.Deactivated || d.status === FuelCodesStatus.Expired,
            onClick: () => {
              changeStatusCb(d.fuelCodeId, d.fuelCode, d.status);
            },
          };

          options.push(deactivateFuelCode);
        }

        if (getUserAccess?.fullAccess || getUserAccess?.viewAccess) {
          viewRedemption = {
            content: locale.viewRedemptions,
            onClick: () => {
              history.push(Path.Redemption, { searchKey: d.fuelCode });
            },
          };
          options.push(viewRedemption);
        }

        map.set(
          "actions",
          <PopOverMenu
            options={options}
            // options={[
            //   options
            // {
            //   content: locale.overrideDetails,
            //   onClick: () => {
            //     editFuelCodeModal.show({
            //       fuelCode: d.fuelCode,
            //       fuelCodeId: d.fuelCodeId,
            //       title: `${locale.overrideFuelCodeDetails} - ${d.fuelCode}`,
            //       initialState: {
            //         fuelCodeType: {
            //           value: d.fuelCodeType,
            //           disabled: true,
            //         },
            //         vehicleIds: {
            //           value: d.vehicles[0]?.plateNumber,
            //           label: d.vehicles[0]?.plateNumber,
            //           disabled: true,
            //         },
            //         driverIds: {
            //           value: d.drivers[0]?.driverId,
            //           label: `${d.drivers[0]?.firstName} ${d.drivers[0]?.lastName}`,
            //           disabled: true,
            //         },
            //         productCode: {
            //           value: d.productCode,
            //         },
            //         literVolume: {
            //           value: d.literVolume,
            //           infoMessage: (
            //             <locale.Populate
            //               text={locale.currentRedeemedVolumeIsN}
            //               items={[<b>{formatVolume(d.volumeRedeemed)}</b>]}
            //             />
            //           ),
            //         },
            //         period: {
            //           value: {
            //             startDate: new Date(d.startDate),
            //             endDate: new Date(d.endDate),
            //           },
            //           disabled: true,
            //         },
            //         duration: {
            //           value: moment(d.endDate).diff(moment(d.startDate), "days"),
            //         },
            //         limitOneTime: {
            //           value: d.maxUsage !== 0,
            //           disabled: true,
            //           infoMessage: (
            //             <locale.Populate
            //               text={locale.codeHasBeenUsed}
            //               items={[
            //                 <b>
            //                   {d.useCount} {pluralize(d.useCount, locale.time, locale.times)}
            //                 </b>,
            //               ]}
            //             />
            //           ),
            //         },
            //         sendAsSms: {
            //           value: d.sendAsSms,
            //           disabled: true,
            //         },
            //         saveTemplate: {
            //           value: false,
            //           hidden: true,
            //         },
            //       },
            //     });
            //   },
            // },

            //]}
          />
        );
        return map;
      });
      return preparedData;
    }
    return [];
  }, [getMyFuelCodesResult, changeStatusCb, history, getUserAccess]);

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate });
    },
    [modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      fetchMyFuelCodes(requestState);
    },
    [modifyFilters, fetchMyFuelCodes]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchMyFuelCodes(requestState);
    },
    [modifyFilters, fetchMyFuelCodes]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchMyFuelCodes(requestState);
    },
    [modifyFilters, fetchMyFuelCodes]
  );

  const onDownloadFileCb = async () => {
    const { requestState } = modifyFilters({ reportType: "fuel-code" });
    if (requestState.status) {
      requestState.fuelCodeStatus = requestState.status;
      delete requestState.status;
    }
    delete requestState.perPage;
    delete requestState.page;
    const generateResult = await generateReportRequest(submitFilter(requestState));
    if (generateResult?.downloadUrl) {
      window.open(generateResult?.downloadUrl, "_blank");
    } else {
      successModal.show({
        title: locale.successTitle,
        content: locale.reportGenerated,
        // contentHighlight: [<strong>{user?.email}</strong>, <strong>business@pricelocq.com</strong>],
      });
    }
  };

  return (
    <div>
      <SuccessModal {...successModal} />
      <div>
        <Intro
          title={locale.myFuelCodesTitle}
          subtitle={locale.createFuelCodes}
          actionText={
            getUserAccess?.fullAccess || getUserAccess?.modifyAccess ? locale.generateFuelCode : ""
          }
          actionOnClick={() => {
            history.push(Path.FuelGenerateCodes);
          }}
          actionDisabled={deactivated}
        />
      </div>
      <Counts
        loading={fetchingMyFuelCodesCount}
        items={[
          {
            label: locale.totalFuelCodes,
            content: formatNumber(fuelCodesCount?.total || 0, 0),
          },
          {
            label: locale.activeFuelCodes,
            content: formatNumber(fuelCodesCount?.active || 0, 0),
          },
        ]}
      />
      <div className={styles.filters}>
        <MyFuelCodesFilter
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          onFieldChange={(name, { value }) => {
            modifyFilter(name, { value: value || "all" });
          }}
          resetFilter={() => {
            modifyFilters({
              productCode: "all",
              fuelCodeType: "all",
              status: "all",
              endDate: null,
              startDate: null,
              ...submittedFilter,
            });
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchMyFuelCodes(requestState);
          }}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchMyFuelCodes(requestState);
          }}
          filterCount={filterCount}
          onDownloadFile={onDownloadFileCb}
          fetchingReport={fetchingReport}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingMyFuelCodes}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={myFuelCodesColumns}
          data={preparedMyFuelCodesData}
          dataCount={getMyFuelCodesResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
          searchKey={
            submittedFilter?.searchKey ||
            (submittedFilter?.startDate && submittedFilter?.endDate
              ? `${moment(submittedFilter?.startDate).format(DateTime.E)} to ${moment(
                  submittedFilter?.endDate
                ).format(DateTime.E)}`
              : null)
          }
        />
      </div>

      <EditFuelCodeModal
        fleetStations={fleetStations}
        {...editFuelCodeModal}
        loading={updateFuelCodeApi.loading}
        callback={() => {
          fetchMyFuelCodes({
            ...requestState,
            page: 1,
          });
        }}
      />

      <MyFuelCodesStatusModal
        refetchMyFuelCodes={() => fetchMyFuelCodes(requestState)}
        {...statusModal}
      />
    </div>
  );
};

export default MyFuelCodesModule;
