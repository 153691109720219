const pumpPriceModel = [
    {
      "productCode": "diesel",
      "pumpPrice": 0.00,
      "discount": 0,
      "creditPrice": 0.00,
    },
    {
      "productCode": "gas91",
      "pumpPrice":0.00,
      "discount": 0,
      "creditPrice": 0.00,
    },
    {
      "productCode": "gas95",
      "pumpPrice": 0.00,
      "discount": 0,
      "creditPrice": 0.00,
    },
    {
      "productCode": "gas97",
      "pumpPrice": 0.00,
      "discount": 0,
      "creditPrice": 0.00,
    }
  ];

  export default pumpPriceModel;