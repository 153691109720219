import locale from "localization";
import { capitalize } from "utils/text.utils";
import { prettifyFuelCodeType } from "utils/pretty.utils";
import FuelCodeType from "enums/fuel-code-type";
import Validation from "utils/validation.utils";

export const initialState = (card) => {
  return {
    cardNumber: {
      name: "cardNumber",
      value: card.cardNumber,
      label: locale.cardNumber,
    },
    cardType: {
      name: "cardType",
      value: card.fuelCardType || prettifyFuelCodeType(card.fuelCardType ?? ""),
      label: locale.cardType,
      options: [
        {
          label: prettifyFuelCodeType(FuelCodeType.DriverSpecific),
          value: FuelCodeType.DriverSpecific,
        },
        {
          label: prettifyFuelCodeType(FuelCodeType.VehicleSpecific),
          value: FuelCodeType.VehicleSpecific,
        },
        {
          label: prettifyFuelCodeType(FuelCodeType.DriverVehicleSpecific),
          value: FuelCodeType.DriverVehicleSpecific,
        },
        {
          label: prettifyFuelCodeType(FuelCodeType.AnyDriverAnyVehicle),
          value: FuelCodeType.AnyDriverAnyVehicle,
        },
      ],
      validations: [Validation.required({ noMessage: true })],
      required: true,
    },
    driverIds: {
      name: "driverIds",
      value: card.driver?.driverId ?? "",
      label: `${capitalize(card.driver?.firstName ?? "")} ${capitalize(
        card.driver?.lastName ?? ""
      )}`.trim(),
      placeholder: locale.searchOrSelect,
    },
    vehicleIds: {
      name: "vehicleIds",
      value: card.vehicle?.vehicleId ?? "",
      label: `${card.vehicle?.model ?? ""} ${card.vehicle?.plateNumber ?? ""}`.trim(),
      placeholder: locale.searchOrSelect,
    },
    locqpayLimit: {
      name: "locqpayLimit",
      value: card.locqpayLimit ?? 0,
      disabled: card.locqpayLimit == null,
      label: locale.locqpayLimit,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.min(1, {
          customMessage: locale.populate(locale.minimumLimit, ["1.00"]),
          isFormatted: true,
        }),
        Validation.max(99999999.99, {
          customMessage: locale.populate(locale.maximumLimit, ["99,999,999.99"]),
          isFormatted: true,
        }),
      ],
      required: true,
    },
    transactionLimit: {
      name: "transactionLimit",
      value: card.transactionLimit ?? 0,
      disabled: card.transactionLimit == null,
      label: locale.transactionLimit,
      validations: [
        Validation.numeric({ noMessage: true }),
        Validation.required({ noMessage: true }),
        Validation.min(1, {
          customMessage: locale.populate(locale.minimumLimit, ["1"]),
          isFormatted: true,
        }),
        Validation.max(999999, {
          customMessage: locale.populate(locale.maximumLimit, ["999,999"]),
          isFormatted: true,
        }),
      ],
      required: true,
    },
    noLimitLocqpay: {
      name: "noLimitLocqpay",
      value: card.locqpayLimit === null ? true : false,
    },
    noLimitTransaction: {
      name: "noLimitTransaction",
      value: card.transactionLimit === null ? true : false,
    },
    locqpayPaymentThisMonth: {
      name: "locqpayPaymentThisMonth",
      value: card.locqpayPaymentThisMonth ?? 0,
      disabled: true,
    },
    transactionThisMonth: {
      name: "transactionThisMonth",
      value: card.transactionThisMonth ?? 0,
      disabled: true,
    },
    diesel: {
      name: "diesel",
      value: card.diesel,
    },
    gas91: {
      name: "gas91",
      value: card.gas91,
    },
    gas95: {
      name: "gas95",
      value: card.gas95,
    },
    gas97: {
      name: "gas97",
      value: card.gas97,
    },
    lubes: {
      name: "lubes",
      value: card.lubes,
    },
    remarks: {
      name: "remarks",
      value: card.remarks ?? "",
      maxLength: 255,
    },
  };
};
