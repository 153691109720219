import { Modal, Text, ActionButton } from "components/commons";
import FleetCardStatus from "enums/fleet-card-status";
import React, { useCallback } from "react";
import styles from "./management-status-modal.module.scss";
import locale from "localization";
import { updateFuelCard } from "apis";
import { handleRequest } from "utils";
import { useApi } from "hooks";

const FleetCardStatusModal = ({
  refetchFuelCards,
  cardNumber,
  action,
  fuelCardId,
  close,
  active,
}) => {
  const { request: updateFuelCardRequest, loading: updatingFuelCard } = useApi({
    api: updateFuelCard,
  });

  const updateStatusCb = useCallback(async () => {
    const newStatus = action;

    handleRequest(async () => {
      await updateFuelCardRequest({ fuelCardId, status: newStatus }, () => updateStatusCb());
      refetchFuelCards();
      close();
    });
  }, [fuelCardId, updateFuelCardRequest, refetchFuelCards, close, action]);

  const displayTitle = (action) => {
    if (action === FleetCardStatus.Deactivated) {
      return locale.askDeactivateFleetCard;
    }
    if (action === FleetCardStatus.Terminated) {
      return locale.askTerminateFleetCard;
    }
    if (action === FleetCardStatus.Active) {
      return locale.askActivateFleetCard;
    }
  };

  const displayText = (action) => {
    if (action === FleetCardStatus.Deactivated) {
      return (
        <locale.Populate
          text={locale.fleetCardDeactivateStatus}
          items={[<strong>deactivate</strong>, <strong>{cardNumber}</strong>, "temporarily"]}
        />
      );
    }

    if (action === FleetCardStatus.Terminated) {
      return (
        <locale.Populate
          text={locale.fleetCardDeactivateStatus}
          items={[<strong>terminate</strong>, <strong>{cardNumber}</strong>, "permanently"]}
        />
      );
    }

    return (
      <locale.Populate
        text={locale.fleetCardActiveStatus}
        items={[<strong>activate</strong>, <strong>{cardNumber}</strong>]}
      />
    );
  };

  const displayButtonText = (action) => {
    if (action === FleetCardStatus.Deactivated) {
      return locale.yesDeactivateIt;
    }
    if (action === FleetCardStatus.Terminated) {
      return locale.yesTerminateIt;
    }
    if (action === FleetCardStatus.Active) {
      return locale.yesActivateIt;
    }
  };

  return (
    <Modal active={active} title={displayTitle(action)} close={close} className={styles.modal}>
      <div className={styles.content}>
        <Text align="center">{displayText(action)}</Text>
      </div>
      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: close,
          },
          {
            loading: updatingFuelCard,
            onClick: updateStatusCb,
            text: displayButtonText(action),
          },
        ]}
      />
    </Modal>
  );
};

export default FleetCardStatusModal;
