import { getCashIns, generateReport } from "apis";
import { DataTable, Intro, Pill, Text, BackLink } from "components/commons";
import { FleetContext, AnalyticsContext } from "contexts";
import { CashInStatus, DateTime, Page } from "enums";
import { useApi, useFilter, useModal, useMount } from "hooks";
import React, { useCallback, useContext, useMemo } from "react";
import { prettifyCashInStatus } from "utils";
import { cashInColumns } from "./cashin-history-columns";
import CashInHistoryFilter from "./cashin-history-filter";
import { cashInHistoryFilterState } from "./cashin-history-filter.state";
import styles from "./cashin-history.module.scss";
import moment from "moment";
import { prettifyCashInChannel } from "utils/pretty.utils";
import locale from "localization";
import { formatAmount } from "utils/text.utils";
import { Path } from "enums";
import { SuccessModal } from "components/modals";
import { bankItems } from "../cashin/cashin-online-banking/banks";

const CashInHistoryModule = () => {
  const successModal = useModal();
  const { fleet } = useContext(FleetContext);
  const { page } = useContext(AnalyticsContext);
  // const { user } = useContext(UserContext);

  const { modifyFilter, modifyFilters, filterState, requestState, submitFilter } = useFilter(
    cashInHistoryFilterState(fleet.fleetId)
  );

  const {
    request: getCashInsRequest,
    loading: fetchingCashIns,
    result: getCashInsResult = { cashins: [], count: 0 },
  } = useApi({
    api: getCashIns,
    pageError: true,
  });

  const { request: generateReportRequest, loading: fetchingReport } = useApi({
    api: generateReport,
    pageError: true,
  });

  useMount(() => {
    fetchCashIns(requestState);
    page({ name: Page.ViewCashInHistory });
  });

  const fetchCashIns = useCallback(
    (rs) => {
      getCashInsRequest(submitFilter(rs));
    },
    [getCashInsRequest, submitFilter]
  );

  const preparedCashInsData = useMemo(() => {
    const { cashins } = getCashInsResult;

    if (cashins.length > 0) {
      const preparedData = cashins.map((c) => {
        const map = new Map();
        map.set("cashInId", c.cashinId);
        map.set("referenceNumber", c.referenceNumber);
        map.set("amount", formatAmount(c.amount));
        map.set(
          "createdDate",
          <div>
            <Text>{moment(c.createdAt).format(DateTime.A)}</Text>
            <Text>{moment(c.createdAt).format(DateTime.B)}</Text>
          </div>
        );
        map.set(
          "fulfilledDate",
          c.fulfilledAt ? (
            <div>
              <Text>{moment(c.fulfilledAt).format(DateTime.A)}</Text>
              <Text>{moment(c.fulfilledAt).format(DateTime.B)}</Text>
            </div>
          ) : (
            ""
          )
        );

        const bankMode = bankItems[c.mode || c.paymentProcessor];
        map.set(
          "cashInChannel",
          bankMode ? (
            <div>
              <div>{prettifyCashInChannel(c.cashinChannel)}</div>
              <div>
                <Text subtitle>{bankMode.shortTitle || bankMode.title}</Text>
              </div>
            </div>
          ) : (
            prettifyCashInChannel(c.cashinChannel)
          )
        );
        map.set(
          "status",
          <Pill
            sky={c.status === CashInStatus.Pending}
            deepRed={c.status === CashInStatus.Failed}
            grass={c.status === CashInStatus.Success}
            cement={c.status === CashInStatus.Expired || c.status === CashInStatus.Voided}
          >
            {prettifyCashInStatus(c.status)}
          </Pill>
        );
        return map;
      });
      return preparedData;
    }
    return [];
  }, [getCashInsResult]);

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      const { requestState } = modifyFilters({ startDate, endDate, page: 1 });
      fetchCashIns(requestState);
    },
    [modifyFilters, fetchCashIns]
  );

  const onChannelChangeCb = useCallback(
    (cashinChannel) => {
      const { requestState } = modifyFilters({ cashinChannel, page: 1 });
      fetchCashIns(requestState);
    },
    [modifyFilters, fetchCashIns]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      fetchCashIns(requestState);
    },
    [modifyFilters, fetchCashIns]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchCashIns(requestState);
    },
    [modifyFilters, fetchCashIns]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchCashIns(requestState);
    },
    [modifyFilters, fetchCashIns]
  );

  const onDownloadFileCb = async () => {
    const { requestState } = modifyFilters({ reportType: "cashin" });
    delete requestState.perPage;
    delete requestState.page;
    const generateResult = await generateReportRequest(submitFilter(requestState));
    if (generateResult?.downloadUrl) {
      window.open(generateResult?.downloadUrl, "_blank");
    } else {
      successModal.show({
        title: locale.successTitle,
        content: locale.reportGenerated,
        // contentHighlight: [<strong>{user?.email}</strong>, <strong>business@pricelocq.com</strong>],
      });
    }
  };

  return (
    <div>
      <SuccessModal {...successModal} />
      <div>
        <BackLink text={locale.cashIn} path={Path.CashIn} />
        <Intro title={locale.cashInHistory} />
      </div>
      <div className={styles.filters}>
        <CashInHistoryFilter
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onChannelChange={onChannelChangeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          onDownloadFile={onDownloadFileCb}
          fetchingReport={fetchingReport}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingCashIns}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={cashInColumns}
          data={preparedCashInsData}
          dataCount={getCashInsResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
    </div>
  );
};

export default CashInHistoryModule;
