import React, { Fragment, useCallback, useContext, useState, useEffect } from "react";
import { Route, Switch } from "react-router";

import { getFleet, getUserV2 } from "apis";
import { Loader } from "components/commons";
import Header from "components/commons/header/header";
import Sidebar from "components/commons/sidebar/sidebar";
import AppLoader from "components/commons/app-loader/app-loader";
// import DownloadDrawer from "components/commons/download-drawer/download-drawer";
import { AppContext, FleetContext, UserContext, FeatureFlagContext } from "contexts";
import { Path } from "enums";
import env from "environments/env";
import { useApi, useMount } from "hooks";
import useWebSocket from "hooks/useWebSockets";
import IntroductoryPromo from "modules/promo/introductory-promo.module";
import DownloadModule from "modules/download/downloads.module";

import ActivityPage from "./activity.page";
import DashboardPage from "./dashboard.page";
import CashPage from "./cash.page";
import FuelPage from "./fuel.page";
import FleetCardPage from "./fleet-card.page";
import StationPage from "./station.page";
import OtherServices from "./other-services.page";
import RedirectPage from "./redirect.page";
import PromoPage from "./promo.page";
import AccessAuthorizationPage from "./access-authorization.page";
import MessagesPage from "./messages.page";
import InboxPage from "./inbox.page";
import UserRoleAccess from "utils/user-role-access.utils";
import { Portal } from "enums";

const AuthorizedPage = () => {
  const { message } = useWebSocket({ URL: env?.WEB_SOCKET_URL });
  const [newNotification, setNewNotification] = useState(false);
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { hasFeature, setUserEmail } = useContext(FeatureFlagContext);
  const { appState } = useContext(AppContext);
  const [userAccess, setUserAccess] = useState([]);
  const [user, setUser] = useState();
  const [fleet, setFleet] = useState();
  const fleetId = localStorage.getItem("fleetId");
  const [notification, setNotification] = useState({
    promo: {
      loaded: false,
      notification: false,
    },
  });
  const { request: getUserRequest, loading: fetchingUser } = useApi({
    api: getUserV2,
    pageError: true,
  });
  const { request: getFleetRequest, loading: fetchingFleet } = useApi({
    api: getFleet,
    pageError: true,
  });

  useMount(async () => {
    fetchUser();
    fetchFleet();
  });

  useEffect(() => {
    if (message.length !== 0) {
      setNewNotification(true);
    }
  }, [message]);

  const fetchUser = useCallback(async () => {
    const userData = await getUserRequest();

    const { role } = userData.userAccesses?.find((element) => {
      return element.portal === Portal.FLEET && element.fleetId === Number(fleetId);
    });

    UserRoleAccess.forEach((value) => {
      value?.items?.forEach((value) => {
        const { key } = value;

        const userAccess = value?.permission?.filter((item) => {
          if (item?.role === role) {
            return item;
          }

          return "";
        });

        if (userAccess.length > 0) {
          setUserAccess((prevState) => [
            ...prevState,
            {
              key,
              ...userAccess[0],
              role,
            },
          ]);
        }
      });
    });

    setUser(userData);
    setUserEmail(userData?.email || "");
  }, [getUserRequest, setUserEmail, fleetId]);

  const fetchFleet = useCallback(async () => {
    setFleet(await getFleetRequest({ fleetId }));
  }, [getFleetRequest, fleetId]);

  const loading = fetchingFleet || fetchingUser || appState?.loading || !user || !fleet;

  if (loading) {
    return <AppLoader />;
  }

  return (
    <UserContext.Provider value={{ user, userAccess, fetchUser, loading: fetchingUser }}>
      <FleetContext.Provider
        value={{ fleet, fetchFleet, loading: fetchingFleet, setNotification, notification }}
      >
        <Loader open={fetchingFleet || fetchingUser || appState?.loading} />
        {user && fleet && (
          <MessagesPage>
            <Fragment>
              <Header
                setNewNotification={() => setNewNotification(false)}
                newNotification={newNotification}
              />
              <Sidebar />
              {/* <DownloadDrawer
              isOpen={isDrawerOpen}
              drawerData={message}
              setIsOpen={setIsDrawerOpen}
              pageType={"plb"}
            /> */}
              <div
                style={{
                  marginLeft: "196px",
                  marginTop: "60px",
                  padding: "27px 36px",
                  minWidth: "1170px",
                  overflowX: "auto",
                  minHeight: "100vh",
                }}
              >
                <Switch>
                  {[
                    [Path.Slash, DashboardPage, true, "DASHBOARD"],
                    [Path.Download, DownloadModule, true, "DOWNLOAD"],
                    [Path.Cash, CashPage, false, "CASH"],
                    [Path.FleetCard, FleetCardPage, false, "FLEET_CARD"],
                    [Path.Fuel, FuelPage, false, "FUEL"],
                    [Path.Activity, ActivityPage, false, "ACTIVITY"],
                    [Path.Station, StationPage, false, "STATION"],
                    [Path.OtherServices, OtherServices, false, "OTHER_SERVICES"],
                    [Path.Promo, PromoPage, false, "PROMO"],
                    [Path.Inbox, InboxPage, true, "INBOX"],
                    [
                      Path.AccessAuthorization,
                      AccessAuthorizationPage,
                      false,
                      "ACCESS_AUTHORIZATION",
                    ],
                  ]
                    .filter(([p, c, e, flag]) => hasFeature(`PAGE_${flag}`))
                    .map(([path, component, exact], key) => (
                      <Route key={key} path={path} component={component} exact={exact} />
                    ))}
                  <Route path={Path.Redirect} component={RedirectPage} />
                </Switch>
              </div>
              <IntroductoryPromo />
            </Fragment>
          </MessagesPage>
        )}
      </FleetContext.Provider>
    </UserContext.Provider>
  );
};

//

export default AuthorizedPage;
