import { Path } from "enums";
import Loans from "modules/other-services/loans/loans.module";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

const CashPage = () => {
  return (
    <Switch>
      <Route exact path={Path.Loans} component={Loans} />
      <Redirect to={Path.Loans} />
    </Switch>
  );
};

export default CashPage;
