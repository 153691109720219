import { ActionButton, Modal, Text } from "components/commons";
import { Path } from "enums";
import locale from "localization";
import { useHistory } from "react-router-dom";
import styles from "./success-generate-fuel-codes-modal.module.scss";

const SuccessGenerateFuelCodeModal = ({ ...modalState }) => {
  const history = useHistory();

  const { close, active } = modalState;

  return (
    <Modal
      active={active}
      title={`${locale.successfullyGeneratedFuelCode}`}
      close={close}
      className={styles.modal}
    >
      <div>
        <Text>{locale.youMayStartUsingCodes}</Text>
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: () => {
              close();
            },
            text: locale.generateAnotherCode,
          },
          {
            onClick: () => {
              history.push(Path.MyFuelCode);
            },
            text: locale.goToMyFuelCodes,
          },
        ]}
      />
    </Modal>
  );
};

export default SuccessGenerateFuelCodeModal;
