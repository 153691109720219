import { CashInMethod, Path } from "enums";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

const CybersourcePage = () => {
  const cybersourcePath = `${Path.Redirect}${Path.Cybersource}`;
  const successPath = `${cybersourcePath}${Path.Success}`;
  const failurePath = `${cybersourcePath}${Path.Failure}`;
  const cancelPath = `${cybersourcePath}${Path.Cancel}`;

  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const cashInId = query.get("cashInId");
  const referenceNumber = query.get("referenceNumber");

  return (
    <Switch>
      <Route path={successPath}>
        <CashInWithStatusComponent
          status={"success"}
          cashInId={cashInId}
          referenceNumber={referenceNumber}
          paymentMethod={CashInMethod.Card}
        />
      </Route>
      <Route path={failurePath}>
        <CashInWithStatusComponent
          status={"failed"}
          cashInId={cashInId}
          referenceNumber={referenceNumber}
          paymentMethod={CashInMethod.Card}
        />
      </Route>
      <Route path={cancelPath}>
        <CashInWithStatusComponent
          status={"cancel"}
          cashInId={cashInId}
          referenceNumber={referenceNumber}
          paymentMethod={CashInMethod.Card}
        />
      </Route>
    </Switch>
  );
};

const CashInWithStatusComponent = ({ status, cashInId, referenceNumber }) => {
  return (
    <Redirect
      to={{
        pathname: `${Path.CashIn}`,
        state: {
          cashInStatus: status,
          cashInId,
          referenceNumber,
          paymentMethod: CashInMethod.Card,
        },
      }}
    />
  );
};

export default CybersourcePage;
