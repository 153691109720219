import Validation from "utils/validation.utils";
import locale from "localization";
import { CashinCardPaymentSelection } from "enums";

export const initialState = (state = {}) => {
  const {
    nameOnCard = {
      value: "",
      label: locale.nameOnCard,
      disabled: false,
      validations: [Validation.required(), Validation.name()],
      required: true,
      placeholder: locale.enterNameHere,
      maxLength: 75,
    },
    cardNumber = {
      value: "",
      disabled: false,
      validations: [
        Validation.required(),
        Validation.numeric({ noMessage: true }),
        Validation.minlength(16, locale.cardNumberYouEnterIsIncorrect),
      ],
      required: true,
      label: locale.cardNumber,
      placeholder: locale.enterCardNumber,
    },
    expiryDate = {
      value: "",
      name: "expiryDate",
      validations: [Validation.required(), Validation.expiryDate()],
      required: true,
      label: locale.expiryDate,
      placeholder: locale.mmYY,
      maxLength: 5,
    },
    cvv = {
      value: "",
      name: "cvv",
      validations: [
        Validation.required(),
        Validation.numeric({ noMessage: true }),
        Validation.minlength(3),
      ],
      required: true,
      label: locale.cvv,
      placeholder: locale.cvv,
      maxLength: 3,
      type: "password",
    },
    amount = {
      value: "",
      name: "amount",
      validations: [
        Validation.required(),
        Validation.min(100, {
          customMessage: locale.populate(locale.minimumCashInIsAmount, ["100.00"]),
          isFormatted: true,
        }),
        Validation.max(500000, {
          customMessage: locale.populate(locale.maximumCashInIsAmount, ["500,000.00"]),
          isFormatted: true,
        }),
      ],
      required: true,
    },
    cashInCardPaymentSelection = {
      name: "cashInCardPaymentSelection",
      value: CashinCardPaymentSelection.SavedCards,
    },
  } = state;
  return {
    nameOnCard: {
      name: "nameOnCard",
      ...nameOnCard,
    },
    cardNumber: {
      name: "cardNumber",
      ...cardNumber,
    },
    expiryDate,
    cvv,
    amount,
    cashInCardPaymentSelection,
  };
};
