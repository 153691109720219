import { Check as CheckIcon } from "@material-ui/icons";
import { getStations } from "apis";
import { DataTable, Intro, Image, Product } from "components/commons";
import Text from "../../../components/commons/text/text";
import { useApi, useFilter, useMount } from "hooks";
import * as _ from "lodash";
import React, { useCallback, useMemo, useContext, useState, useRef } from "react";
import { stationColumns } from "./station-list-columns";
import StationFilter from "./station-list-filter";
import { stationFilterState } from "./station-list-filter.state";
import styles from "./station-list.module.scss";
import locale from "localization";
import provinces from "../../../assets/province.json";
import { AnalyticsContext, FleetContext, UserContext } from "contexts";
import { DateTime, Page, UserAccess } from "enums";
import StationMap from "components/station-map/station-map";
import moment from "moment";
import { Seaoil, Direct, StationSchedule, StationLocation } from "images";
import { formatNumber } from "utils";

const StationModule = () => {
  const stationRef = useRef();
  const { page } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { userAccess } = useContext(UserContext);
  const { fleetId, fleetStations } = fleet;

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.StationList;
  });

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    submitFilter,
    submittedFilter,
    clearFilter,
    filterCount,
  } = useFilter(stationFilterState(fleetId));
  const [currentLocation, setCurrentLocation] = useState(null);
  const [openMap, setOpenMap] = useState(false);
  const [pinnedMap, setPinnedMap] = useState(null);
  const [stationListDataMap, setStationListDataMap] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [allowAccessLocation, setAllowAccessLocation] = useState(true);

  const {
    request: getStationsRequest,
    loading: fetchingStations,
    result: getStationsResult = { stations: [], count: 0 },
  } = useApi({
    api: getStations,
    pageError: true,
  });

  const fetchStations = useCallback(
    async (rs) => {
      const products = {
        diesel: rs.productCodes.includes("diesel") || null,
        gas91: rs.productCodes.includes("gas91") || null,
        gas95: rs.productCodes.includes("gas95") || null,
        gas97: rs.productCodes.includes("gas97") || null,
      };
      const s = submitFilter(rs);

      return await getStationsRequest({ ...s, ...products, productCodes: null });
    },
    [getStationsRequest, submitFilter]
  );

  const updateStationMapListData = useCallback(
    async (requestState) => {
      const result = await fetchStations(requestState);
      setStationListDataMap(result?.stations);
    },
    [fetchStations]
  );

  const getCurrentPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position) {
          setCurrentLocation({
            lat: Number(position.coords.latitude),
            lng: Number(position.coords.longitude),
          });
        }
      },
      () => {
        setAllowAccessLocation(false);
      }
    );
  }, []);

  useMount(() => {
    fetchStations(requestState);
    page({
      name: Page.ViewPLBStations,
    });
    getCurrentPosition();
  });

  const getKmDistance = useCallback(
    (lat, lng) => {
      const radlat1 = (Math.PI * lat) / 180;
      const radlat2 = (Math.PI * currentLocation.lat) / 180;
      const theta = lng - currentLocation.lng;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;

      return formatNumber(dist, 2);
    },
    [currentLocation]
  );

  const operation = useCallback(({ open, close, bold = false }) => {
    const now = moment();
    const openHours = moment(open, DateTime.M);
    const closingHours = moment(close, DateTime.M);
    const is24Hrs =
      open === moment().startOf("day").format(DateTime.Q) &&
      close === moment().endOf("day").format(DateTime.Q);
    let operating = true;
    if (open !== close && !is24Hrs) {
      operating = now.isBetween(openHours, closingHours);
    }

    return (
      <Text
        className={{
          [styles.openTag]: operating,
          [styles.closeTag]: !operating,
          [styles.bold]: bold,
        }}
      >
        {operating ? locale.open : locale.closed}
      </Text>
    );
  }, []);

  const fetchStationListMap = useCallback(
    (requestStateParam = {}) => {
      const { requestState } = modifyFilters(requestStateParam);
      const newRequestState = prepareRequestState(requestState);

      updateStationMapListData(newRequestState);
    },
    [modifyFilters, updateStationMapListData]
  );

  const preparedStationListData = useMemo(() => {
    const { stations } = getStationsResult;

    if (stations.length > 0) {
      const preparedData = stations.map((s, i) => {
        const map = new Map();
        map.set(
          "stationName",
          <div className={styles.stationName}>
            <Image src={Seaoil} className={styles.stationLogo} />
            <div>
              {s.name}
              <Text subtitle>{s.address && s.address}</Text>
              <div className={styles.locationDirection}>
                {currentLocation && (
                  <Text subtitle className={styles.kmAway}>
                    <locale.Populate
                      text={locale.distanceAway}
                      items={[getKmDistance(s.latitude, s.longitude)]}
                    />
                  </Text>
                )}
                {(getUserAccess?.fullAccess || getUserAccess?.viewAccess) && (
                  <div
                    onClick={() => {
                      setOpenMap(true);
                      setSelectedStation(i);
                      setPinnedMap({ lng: s.longitude, lat: s.latitude });
                      fetchStationListMap();
                    }}
                    className={styles.directionContaier}
                  >
                    <Image src={Direct} className={styles.directionIcon} />
                    <Text className={styles.directionText}>{locale.getDirection}</Text>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
        map.set(
          "city",
          <div className={styles.city}>
            {s.city && s.city.toLowerCase()}
            <Text subtitle>{s.province && s.province.toLowerCase()}</Text>
          </div>
        );
        map.set(
          "operatingHours",
          <div>
            <Text>
              {s.opensAt === s.closesAt
                ? locale.allDay
                : `${moment(s.opensAt, DateTime.M).format(DateTime.M)} -  ${moment(
                    s.closesAt,
                    DateTime.M
                  ).format(DateTime.M)}`}
            </Text>
            {operation({ open: s.opensAt, close: s.closesAt })}
          </div>
        );
        map.set(
          "city",
          <div className={styles.city}>
            {s.city && s.city.toLowerCase()}
            <Text subtitle>{s.province && s.province.toLowerCase()}</Text>
          </div>
        );
        map.set("diesel", s.stationProduct.diesel ? <CheckIcon /> : null);
        map.set("gas91", s.stationProduct.gas91 ? <CheckIcon /> : null);
        map.set("gas95", s.stationProduct.gas95 ? <CheckIcon /> : null);
        map.set("gas97", s.stationProduct.gas97 ? <CheckIcon /> : null);
        return map;
      });
      return preparedData;
    }
    return [];
  }, [
    currentLocation,
    fetchStationListMap,
    getKmDistance,
    getStationsResult,
    getUserAccess,
    operation,
  ]);

  const resetPinnedStation = useCallback(() => {
    setPinnedMap(null);
    stationRef?.current?.clearDirection();
  }, []);

  const onSearchCb = useCallback(
    async (searchKey) => {
      resetPinnedStation();
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      const newRequestState = prepareRequestState(requestState);
      if (openMap) {
        updateStationMapListData(newRequestState);
      } else {
        fetchStations(newRequestState);
      }
    },
    [resetPinnedStation, modifyFilters, openMap, updateStationMapListData, fetchStations]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      const newRequestState = prepareRequestState(requestState);
      fetchStations(newRequestState);
    },
    [modifyFilters, fetchStations]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      const newRequestState = prepareRequestState(requestState);

      fetchStations(newRequestState);
    },
    [fetchStations, modifyFilters]
  );

  const prepareRequestState = (requestState) => {
    const newRequestState = requestState;
    if (newRequestState.province && newRequestState.province !== "all") {
      const provinceObj = _.find(provinces, { label: requestState.province });
      newRequestState.province = provinceObj && provinceObj.label ? provinceObj.label : null;
    }

    delete newRequestState.allProducts;
    return newRequestState;
  };

  const stationDetailsContainer = useCallback(
    ({ station, index, withProduct }) => (
      <div key={index}>
        <div className={styles.listInMapContainer}>
          <div className={styles.listInMap}>
            <div className={styles.stationDetailsIcon}>
              <Image src={Seaoil} className={styles.stationLogo} />
              <Image src={StationSchedule} className={styles.stationScheduleIcon} />
              <Image src={StationLocation} className={styles.stationLocationIcon} />
            </div>
            <div>
              <Text bolder>{station.name}</Text>
              <div className={styles.schedule}>
                <Text>
                  {station.opensAt === station.closesAt
                    ? locale.allDay
                    : `${moment(station.opensAt, DateTime.M).format(DateTime.M)} -  ${moment(
                        station.closesAt,
                        DateTime.M
                      ).format(DateTime.M)}`}
                </Text>
                {operation({ open: station.opensAt, close: station.closesAt, bold: true })}
              </div>
              <div className={styles.address}>
                <Text subtitle>{station.address && station.address}</Text>
              </div>

              <div className={styles.locationDirection}>
                {currentLocation && (
                  <>
                    <Text subtitle className={styles.kmAway}>
                      <locale.Populate
                        text={locale.distanceAway}
                        items={[getKmDistance(station.latitude, station.longitude)]}
                      />
                    </Text>
                    <div
                      onClick={() => {
                        stationRef.current.showDirection({
                          lng: station.longitude,
                          lat: station.latitude,
                        });
                        setSelectedStation(index);
                      }}
                      className={styles.directionContaier}
                    >
                      <Image src={Direct} className={styles.directionIcon} />
                      <Text className={styles.directionText}>{locale.getDirection}</Text>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {!withProduct && (
            <div
              className={styles.seeDetailsButton}
              onClick={() => {
                setSelectedStation(index);
                setPinnedMap({ lng: station.longitude, lat: station.latitude });
              }}
            >
              <span className="icon-chevron-right" />
            </div>
          )}
        </div>
        {withProduct && (
          <div className={styles.productDetails}>
            <Text strong>{locale.availableProducts}</Text>

            {station.stationProduct?.diesel && (
              <Product
                cheddar={true}
                classNameContainer={styles.productList}
                className={styles.productListContent}
              >
                <Text subtitle>{locale.diesel}</Text>
              </Product>
            )}
            {station.stationProduct?.gas91 && (
              <Product
                grass={true}
                classNameContainer={styles.productList}
                className={styles.productListContent}
              >
                <Text subtitle>
                  <locale.Populate text={locale.gasName} items={["91"]} />
                </Text>
              </Product>
            )}

            {station.stationProduct?.gas95 && (
              <Product
                salsa={true}
                classNameContainer={styles.productList}
                className={styles.productListContent}
              >
                <Text subtitle>
                  <locale.Populate text={locale.gasName} items={["95"]} />
                </Text>
              </Product>
            )}

            {station.stationProduct?.gas97 && (
              <Product
                deepBlue={true}
                classNameContainer={styles.productList}
                className={styles.productListContent}
              >
                <Text subtitle>
                  <locale.Populate text={locale.gasName} items={["97"]} />
                </Text>
              </Product>
            )}
          </div>
        )}
      </div>
    ),
    [currentLocation, getKmDistance, operation]
  );

  const StationListMap = useCallback(() => {
    if (stationListDataMap?.length > 0) {
      return stationListDataMap.map((s, i) => {
        return stationDetailsContainer({ station: s, index: i });
      });
    }
    return [];
  }, [stationDetailsContainer, stationListDataMap]);

  const StationDetailstMap = useCallback(() => {
    if (selectedStation !== null && stationListDataMap) {
      return stationDetailsContainer({
        station: stationListDataMap[selectedStation],
        index: selectedStation,
        withProduct: true,
      });
    }
    return [];
  }, [stationListDataMap, selectedStation, stationDetailsContainer]);

  const onClearCb = useCallback(() => {
    const defaultFilterState = stationFilterState(fleetId);
    const searchKey = { searchKey: "" };
    const { requestState } = modifyFilters({ ...defaultFilterState, ...searchKey });
    const newRequestState = prepareRequestState(requestState);
    fetchStations(newRequestState);
  }, [fetchStations, fleetId, modifyFilters]);

  const limitedStations = useMemo(() => {
    return fleetStations.length > 0;
  }, [fleetStations]);

  const applyFilters = useCallback(
    (requestState = {}) => {
      updateStationMapListData(requestState);
      fetchStations(requestState);
    },
    [fetchStations, updateStationMapListData]
  );

  const onChangeMapListCb = useCallback(() => {
    if (openMap) {
      setOpenMap(false);
      setPinnedMap(null);
    } else {
      setOpenMap(true);
    }
    const { requestState } = clearFilter(["searchKey"]);
    delete requestState.allProducts;

    applyFilters({ ...requestState, perPage: !openMap ? 1000 : requestState.perPage });
  }, [applyFilters, clearFilter, openMap]);

  const onProductChangeCb = useCallback(
    (productCodes) => {
      modifyFilters({ productCodes, page: 1 });
    },
    [modifyFilters]
  );

  return (
    <div>
      <div>
        <Intro
          title={locale.priceLocqForBusinessStations}
          subtitle={
            limitedStations ? (
              <locale.Populate
                text={locale.redeemFromSelectedStation}
                items={[<span className={styles.limitedStations}>{locale.limitedStations}</span>]}
              />
            ) : (
              locale.redeemFromAnyStation
            )
          }
        />
      </div>
      <div className={styles.filters}>
        <StationFilter
          onProductChangeCb={onProductChangeCb}
          filterState={filterState}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          onFieldChange={(name, { value }) => {
            switch (name) {
              case "province":
                modifyFilters({
                  province: value,
                  city: "all",
                });
                break;
              case "allProducts":
                modifyFilters({
                  allProducts: value,
                  diesel: value || null,
                  gas91: value || null,
                  gas95: value || null,
                  gas97: value || null,
                });
                break;
              default:
                modifyFilter(name, { value: value ? value : null });
                break;
            }
          }}
          resetFilter={() => {
            modifyFilters({ submittedFilter });
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            if (openMap) {
              updateStationMapListData(requestState);
            } else {
              fetchStations(requestState);
            }
          }}
          submitFilter={async () => {
            resetPinnedStation();
            const { requestState } = modifyFilters({ page: 1 });

            if (openMap) {
              fetchStationListMap(requestState);
            } else {
              fetchStations(requestState);
            }
          }}
          filterCount={filterCount}
          currentLocation={currentLocation}
          openMap={openMap}
          onChangeMapList={
            getUserAccess?.fullAccess || getUserAccess?.viewAccess ? onChangeMapListCb : ""
          }
        />
      </div>
      <div className={styles.table}>
        {openMap ? (
          <StationMap
            stations={stationListDataMap}
            pinnedMap={pinnedMap}
            setPinnedMap={setPinnedMap}
            ref={stationRef}
            currentLocation={currentLocation}
            setSelectedStation={setSelectedStation}
            allowAccessLocation={allowAccessLocation}
          >
            <div
              className={styles.mapInfo}
              onScroll={async (e) => {
                if (
                  !fetchingStations &&
                  e.target.scrollTop === e.target.scrollHeight - e.target.offsetHeight &&
                  getStationsResult.count > stationListDataMap.length
                ) {
                  const { requestState } = modifyFilters({ page: Number(filterState.page) + 1 });
                  const newRequestState = prepareRequestState(requestState);
                  const result = await fetchStations(newRequestState);
                  setStationListDataMap([...stationListDataMap, ...result?.stations]);
                }
              }}
            >
              {pinnedMap ? (
                <>
                  <div
                    className={styles.backlink}
                    onClick={() => {
                      resetPinnedStation();
                      const { requestState } = modifyFilters({ page: 1, perPage: 10 });
                      const newRequestState = prepareRequestState(requestState);
                      updateStationMapListData(newRequestState);
                    }}
                  >
                    <span className={styles.backlinkContent}>
                      <span className="icon-chevron-left" />
                      <Text className={styles.text}>{locale.backToPricelocqStations}</Text>
                    </span>
                  </div>
                  <StationDetailstMap />
                </>
              ) : (
                <>
                  <Text strong className={styles.numberOfStations}>
                    <locale.Populate
                      text={locale.numberOfStations}
                      items={[getStationsResult.count]}
                    />
                  </Text>
                  <StationListMap />
                  {fetchingStations && (
                    <div className={styles.stationListMaploading}>
                      <Text align="center">{locale.loading}</Text>
                    </div>
                  )}
                </>
              )}
            </div>
          </StationMap>
        ) : (
          <DataTable
            loading={fetchingStations}
            page={filterState.page}
            pageSize={filterState.perPage}
            columns={stationColumns}
            data={preparedStationListData}
            dataCount={getStationsResult.count}
            onChangePage={onChangePageCb}
            onChangePageSize={onChangePageSizeCb}
            plbStationTable={true}
            onClear={onClearCb}
          />
        )}
      </div>
    </div>
  );
};

export default StationModule;
