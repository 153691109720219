import classNames from "classnames";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { TextField, Text } from "..";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import styles from "./dropdown-paginated.module.scss";
import { debounced } from "utils";

const DropdownPaginated = ({
  name,
  value,
  label,
  onChange,
  request,
  options = [],
  loading,
  total,
  formatRequest,
  placeholder,
  allOption = [],
  ...props
}) => {
  const [data, setData] = useState(options);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [active, setActive] = useState(false);
  const [searching, setSearching] = useState("");
  const optionsRef = useRef();
  const inputRef = useRef();
  //eslint-disable-next-line
  useEffect(
    debounced(async () => {
      setSearching(searchKey);
      const r = await request({
        page: 1,
        searchKey,
      });
      setData([...formatRequest(r)]);
      setPage(1);
      setSearching("");
    }, 1000),
    [searchKey]
  );

  const renderData = useMemo(() => {
    const newData = data.reduce((prev, curr) => {
      if (!prev.some((obj) => obj.label === curr.label && obj.value === curr.value)) {
        prev.push(curr);
      }
      return prev;
    }, []);

    if (searchKey) {
      return data.filter((d) => {
        return d.label.toLowerCase().includes(searchKey.toLowerCase());
      });
    }

    return [...allOption, ...newData];
  }, [searchKey, data, allOption]);

  const renderPlaceholder = useMemo(() => {
    if (label) {
      return label;
    }
    return placeholder;
  }, [placeholder, label]);

  const onFocus = async () => {
    const r = await request({
      page: 1,
      searchKey: "",
    });
    setData([...formatRequest(r)]);
    setPage(1);
    setSearchKey("");
  };
  return (
    <div>
      <div
        onMouseDown={() => {
          setActive(true);
        }}
      >
        <TextField
          ref={inputRef}
          className={classNames(styles.textfield, {
            [`${styles.active}`]: active && label,
          })}
          value={active ? searchKey : label}
          onChange={(name, { value }) => {
            setSearchKey(value);
          }}
          onEnter={async () => {
            const r = await request({
              page: 1,
              searchKey,
            });
            setData([...formatRequest(r)]);
            setPage(1);
          }}
          onFocus={onFocus}
          onBlur={() => {
            optionsRef.current.scrollTop = 0;
            setActive(false);
            setSearchKey("");
          }}
          suffix={
            <ArrowDropDownIcon
              className={styles.clickable}
              onClick={() => {
                if (inputRef) {
                  inputRef.current.focus();
                }
                if (!active) {
                  setActive(true);
                }
              }}
            />
          }
          {...props}
          placeholder={renderPlaceholder}
        />
      </div>
      <div
        className={classNames(styles.options, {
          [`${styles.active}`]: active,
          [`${styles.empty}`]: !data.length,
        })}
        ref={optionsRef}
        onScroll={async (e) => {
          if (!loading && total > data.length) {
            const p = page + 1;
            const r = await request({
              page: p,
              searchKey,
            });
            setPage(p);
            setData([...data, ...formatRequest(r)]);
          }
        }}
      >
        {(searching || (page === 1 && loading)) && (
          <div className={styles.option}>
            <CircularProgress className={styles.circle} />
            <Text subtitle>{searchKey ? `Searching "${searchKey}"...` : "Loading..."}</Text>
          </div>
        )}
        {renderData.map((option, key) => {
          return (
            <div
              key={key}
              className={classNames(styles.option, {
                [`${styles.selected}`]: option.value === value,
              })}
              onMouseDown={() => {
                setActive(false);
                setSearchKey("");
                onChange(name, {
                  value: option.value,
                  label: option.label,
                  ...option,
                });
              }}
            >
              {option.label}
            </div>
          );
        })}
        {!loading && !renderData.length && (
          <div className={styles.option}>
            <Text subtitle>No Data.</Text>
          </div>
        )}
        {loading && !searching && page !== 1 && (
          <div className={styles.option}>
            <CircularProgress className={styles.circle} />
            <Text subtitle>Loading...</Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownPaginated;
