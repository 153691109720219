import React from "react";
import { TextField } from "..";
import styles from "./search-field.module.scss";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { isAlphaNumeric } from "utils/text.utils";

const SearchField = ({ name, onClear, onChange, restrict = true, ...props }) => {
  return (
    <TextField
      onChange={(name, { value }) => {
        if (restrict && !isAlphaNumeric(value)) {
          return;
        }

        onChange(name, { value });
      }}
      {...props}
      prefix={<SearchIcon className={styles.searchIcon} />}
      suffix={
        props.value ? (
          <CloseIcon
            className={styles.clearIcon}
            onClick={() => {
              if (onClear) {
                onClear();
              } else {
                onChange(name, { value: "" });
                props.onEnter();
              }
            }}
          />
        ) : null
      }
    />
  );
};

export default SearchField;
