import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Checkbox, Text, Button } from "components/commons";
import locale from "localization";
import { initialState as formState } from "./refer-plb.state";
import { useForm } from "hooks";
import styles from "../dashboard.module.scss";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

const ReferPLBFriendForm = ({ initialState = {}, submitData }) => {
  const [buttonActionDisabled, setButtonActionDisabled] = useState(false);
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const { fields, modifyForm, submitForm, getFormValues } = useForm({
    initialState: form,
  });

  useEffect(() => {
    const fieldsToBeCheck = { ...fields };
    let counter = 0;
    for (const [, item] of Object.entries(fieldsToBeCheck)) {
      if (item.value) {
        counter++;
      }
    }

    if (counter > 0) {
      setButtonActionDisabled(false);
    } else {
      setButtonActionDisabled(true);
    }
  }, [fields]);
  const submit = async () => {
    const params = getFormValues();
    submitData({ isSkip: false, ...params });
  };

  const onSkip = async () => {
    const params = getFormValues();
    submitData({ isSkip: true, ...params });
  };
  const onChangeRecommendationScore = useCallback(
    (name, { value }) => {
      const fieldsToBeCheck = { ...fields };
      let nameToBeChanged;
      for (const [key, item] of Object.entries(fieldsToBeCheck)) {
        if (item.value) {
          nameToBeChanged = key;
        }
      }
      modifyForm({
        [name]: {
          value,
        },
        [nameToBeChanged]: {
          value: "",
        },
      });
    },
    [modifyForm, fields]
  );

  return (
    <>
      <Text className={styles.headerTitleRating}>{locale.howLikelyAreYouToRefer}</Text>
      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabel}>
          <Checkbox
            icon={<CircleUnchecked />}
            style={{
              "& .MuiSvgIcon-root": {
                fontSize: 70,
                borderRadius: 20,
              },
            }}
            {...fields?.extremelyLike}
            value={fields.extremelyLike.value || null}
            onChange={(name, value) => {
              onChangeRecommendationScore(name, value);
            }}
          />
          <Text className={styles.description}>{locale.extremelyLike}</Text>
        </div>
      </div>

      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabel}>
          <Checkbox
            icon={<CircleUnchecked />}
            style={{
              "& .MuiSvgIcon-root": {
                fontSize: 70,
                borderRadius: 20,
              },
            }}
            {...fields?.veryLikely}
            value={fields.veryLikely.value || null}
            onChange={(name, value) => {
              onChangeRecommendationScore(name, value);
            }}
          />
          <Text className={styles.description}>{locale.veryLikely}</Text>
        </div>
      </div>

      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabel}>
          <Checkbox
            icon={<CircleUnchecked />}
            style={{
              "& .MuiSvgIcon-root": {
                fontSize: 70,
                borderRadius: 20,
              },
            }}
            {...fields?.someWhatLikely}
            value={fields.someWhatLikely.value || null}
            onChange={(name, value) => {
              onChangeRecommendationScore(name, value);
            }}
          />
          <Text className={styles.description}>{locale.someWhatLikely}</Text>
        </div>
      </div>

      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabel}>
          <Checkbox
            icon={<CircleUnchecked />}
            style={{
              "& .MuiSvgIcon-root": {
                fontSize: 70,
                borderRadius: 20,
              },
            }}
            {...fields?.notSoLikely}
            value={fields.notSoLikely.value || null}
            onChange={(name, value) => {
              onChangeRecommendationScore(name, value);
            }}
          />
          <Text className={styles.description}>{locale.notSoLikely}</Text>
        </div>
      </div>

      <div className={styles.checkBoxContainer}>
        <div className={styles.checkBoxLabel}>
          <Checkbox
            icon={<CircleUnchecked />}
            style={{
              "& .MuiSvgIcon-root": {
                fontSize: 70,
                borderRadius: 20,
              },
            }}
            {...fields?.notAtAllLikely}
            value={fields.notAtAllLikely.value || null}
            onChange={(name, value) => {
              onChangeRecommendationScore(name, value);
            }}
          />
          <Text className={styles.description}>{locale.notAtAllLikely}</Text>
        </div>
      </div>
      <div className={styles.skipAndSubmitSatisfactory}>
        <div className={styles.skipSatisfactory}>
          <Button className={styles.skip} onClick={() => submitForm(onSkip)}>
            {locale.skip}
          </Button>
        </div>
        <div className={styles.submitSatisfactory}>
          <Button
            className={styles.continue}
            survey
            disabled={buttonActionDisabled}
            onClick={() => submitForm(submit)}
          >
            {locale.submit}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ReferPLBFriendForm;
