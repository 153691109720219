import { useCallback, useState } from "react";

const usePagination = () => {
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const onLoadMore = useCallback(
    ({ data, total }) => {
      setResult([...result, ...data]);
      setTotal(total);
      setPage(page + 1);
    },
    [result, page]
  );

  return {
    canLoadMore: total > result.length,
    onLoadMore,
    page,
    data: result,
  };
};

export default usePagination;
