import { ActionButton, Field, Modal, Text, TextField } from "components/commons";
import { useApi, useForm, useMount } from "hooks";
import locale from "localization";
import { useMemo } from "react";
import { useCallback } from "react";
import { handleRequest, isNumeric, parseNumber, redirectTo } from "utils";
import { initialState as formState } from "./cashin-card-add-card-modal.state";
import styles from "./cashin-card-add-card-modal.module.scss";
import { createFleetCard } from "apis/fleet.api";
import { ErrorCode } from "enums";
import { createPaymentToken } from "apis";

const CashinCardAddCardModal = ({ ...modalState }) => {
  const { close, active, initialState, fleetId } = modalState;

  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const {
    fields,
    modifyField,
    validateField,
    getFormValues,
    applyFieldErrors,
    isFormSubmittable,
    clearForm,
  } = useForm({
    initialState: form,
  });

  const onChangeExpiryDate = useCallback(
    (name, { value }) => {
      let v = value.toString();
      if (v.includes("/")) {
        v = v.replace("/", "");
      }
      if (isNumeric(v) && v.length <= 4) {
        modifyField(name, {
          value: `${v.substring(0, 2)}${v.length > 2 ? "/" : ""}${v.substring(2, 4)}`,
        });
      }
    },
    [modifyField]
  );

  const { request: createFleetCardRequest, loading: creatingFleetCard } = useApi({
    api: createFleetCard,
  });

  const { request: createPaymentTokenRequest, loading: creatingPaymentToken } = useApi({
    api: createPaymentToken,
    handleOwnError: true,
  });

  const createFleetCardCb = useCallback(async () => {
    const params = getFormValues();
    params.amount = parseNumber(params.amount);

    handleRequest(
      async () => {
        let paymentToken = null;

        const paymentTokenRes = await createPaymentTokenRequest({
          card: {
            number: params.cardNumber,
            expMonth: params.expiryDate.split("/")[0],
            expYear: `20${params.expiryDate.split("/")[1]}`,
            cvc: params.cvv,
          },
        });

        paymentToken = paymentTokenRes.paymentTokenId;

        const paymentRes = await createFleetCardRequest({
          paymentToken: paymentToken,
          fleetId,
        });

        redirectTo(paymentRes.verificationUrl);
      },
      null,
      (err) => {
        if (err.data?.code === "2553") {
          applyFieldErrors({
            cardNumber: locale.cardNumberYouEnterIsIncorrect,
          });
        } else if (err.data?.code === ErrorCode.CardNotSupported) {
          applyFieldErrors({
            cardNumber: locale.weAreOnlyAcceotingTheseCards,
          });
        } else {
          err.showError();
        }
      }
    );
  }, [applyFieldErrors, createFleetCardRequest, createPaymentTokenRequest, fleetId, getFormValues]);

  useMount(() => {
    clearForm();
  });

  return (
    <Modal
      active={active}
      title={`${locale.addNewCard}`}
      close={() => {
        clearForm();
        close();
      }}
      className={styles.modal}
    >
      <Text className={styles.subtitle}>{locale.addingCardWillRedirect}</Text>
      <div>
        <>
          <Field {...fields.nameOnCard}>
            <TextField {...fields.nameOnCard} onChange={modifyField} onBlur={validateField} />
          </Field>
          <Field {...fields.cardNumber}>
            <TextField
              {...fields.cardNumber}
              onChange={(name, { value }) => {
                const v = value.replaceAll(" ", "");
                if (v.length <= 16) {
                  modifyField(name, { value: v });
                }
              }}
              onBlur={validateField}
            />
          </Field>
          <div className={styles.cardInfor}>
            <Field {...fields.expiryDate} className={styles.half}>
              <TextField
                {...fields.expiryDate}
                onChange={onChangeExpiryDate}
                onBlur={validateField}
              />
            </Field>
            <Field {...fields.cvv} className={styles.half}>
              <TextField {...fields.cvv} onChange={modifyField} onBlur={validateField} />
            </Field>
          </div>
        </>
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: () => {
              clearForm();
              close();
            },
            text: locale.cancel,
          },
          {
            loading: creatingFleetCard || creatingPaymentToken,
            onClick: createFleetCardCb,
            text: locale.save,
            disabled: !isFormSubmittable,
          },
        ]}
      />
    </Modal>
  );
};

export default CashinCardAddCardModal;
