import { useState } from "react";

const useSelectAll = ({ total, items }) => {
  const [selected, setSelected] = useState([]);

  return {
    isAll: selected.length === total && total,
    toggleItem: (item) => {
      if (selected.includes(item)) {
        setSelected(
          selected.filter((index) => {
            return index !== item;
          })
        );
      } else {
        setSelected([...selected, item]);
      }
    },
    toggleSelectAll: () => {
      if (selected.length) {
        setSelected([]);
      } else {
        setSelected(
          items.map((item, index) => {
            return index;
          })
        );
      }
    },
    selected,
    unselectAll: () => {
      setSelected([]);
    },
  };
};

export default useSelectAll;
