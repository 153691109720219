import { LitreVolumeState } from "states";
import Validation from "utils/validation.utils";
import locale from "localization";
import { fuelCodeTypeOptions } from "./fuel-code-type.options";

export const initialState = (state = {}) => {
  const {
    vehicleIds = {
      value: "",
      label: "",
    },
    driverIds = {
      value: "",
      label: "",
    },
    fuelCodeType = {
      value: "none",
    },
    mobileNumber = {
      value: "",
    },
    productCode = {
      value: "",
    },
    literVolume = {
      value: "",
    },

    duration = {
      value: 1,
    },
    period = {
      value: {
        startDate: null,
        endDate: null,
      },
    },
    limitOneTime = {
      value: false,
    },
    saveTemplate = {
      value: false,
    },
    sendAsSms = {
      value: false,
    },
    autoRenew = {
      value: false,
    },
  } = state;
  return {
    vehicleIds: {
      name: "vehicleIds",
      ...vehicleIds,
    },
    driverIds: {
      name: "driverIds",
      ...driverIds,
    },
    fuelCodeType: {
      name: "fuelCodeType",
      type: "dropdown",
      placeholder: "Search or select",
      validations: [Validation.required],
      required: true,
      options: fuelCodeTypeOptions,
      ...fuelCodeType,
    },
    productCode: {
      name: "productCode",
      // validations: [Validation.required],
      // required: true,
      ...productCode,
    },
    literVolume: LitreVolumeState(
      [
        Validation.required,
        Validation.min(1, {
          customMessage: locale.minimumAllowedLitre,
          isFormatted: true,
        }),
        Validation.max(10000, {
          customMessage: locale.populate(locale.maximumAllowedRedeemLiters, ["10,000"]),
          isFormatted: true,
        }),
      ],
      { required: true, ...literVolume }
    ),
    period: {
      name: "period",
      minDate: new Date(),
      validations: [Validation.dateRangeRequired],
      required: true,
      type: "date",
      ...period,
    },
    duration: {
      name: "duration",
      validations: [
        Validation.numeric(),
        Validation.min(1),
        Validation.max(1000, {
          customMessage: locale.populate(locale.minMaxDuration, ["1 to 1000"]),
          isFormatted: true,
        }),
      ],
      label: locale.durationInDays,
      required: true,
      ...duration,
    },
    limitOneTime: {
      name: "limitOneTime",
      label: locale.limitCodeOneTime,
      ...limitOneTime,
    },
    saveTemplate: {
      name: "saveTemplate",
      label: locale.saveCodeTemplate,
      ...saveTemplate,
    },
    sendAsSms: {
      name: "sendAsSms",
      label: locale.sendFuelCodeAsSms,
      ...sendAsSms,
    },
    mobileNumber: {
      name: "mobileNumber",
      label: locale.mobileNumber,
      placeholder: locale.mobilePlaceholder,
      validations: [Validation.numeric(), Validation.mobileNumber({ noMessage: true })],
      horizontal: true,
      ...mobileNumber,
    },
    autoRenew: {
      name: "autoRenew",
      label: locale.autoRenewFuelCode,
      ...autoRenew,
    },
  };
};
