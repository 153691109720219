import locale from "localization";

export const myFuelCodesColumns = [
  { key: "fuelCode", text: locale.fuelCode, width: "10%" },
  { key: "fuelCodeType", text: locale.fuelCodeType, width: "10%" },
  { key: "assignedDriver", text: locale.assignedDriver, width: "10%" },
  { key: "assignedVehicle", text: locale.assignedVehicle, width: "10%" },
  { key: "product", text: locale.product, width: "10%" },
  { key: "literVolume", text: locale.volumeLimit, width: "5%" },
  { key: "volumeRedeemed", text: locale.volumeRedeemed, width: "5%" },
  { key: "oneTimeUse", text: `${locale.oneTimeUse}?`, width: "20%" },
  { key: "sendSms", text: `${locale.sentAsSms}`, width: "20%" },
  { key: "autoRenew", text: `${locale.autoRenew}?`, width: "20%" },
  { key: "startDate", text: locale.codeStartDate, width: "12%" },
  { key: "endDate", text: locale.codeEndDate, width: "10%" },
  { key: "createdAt", text: locale.dateGenerated, width: "10%" },
  { key: "status", text: locale.status, width: "3%" },
  { key: "actions", text: locale.actions, width: "3%" },
];
