import classNames from "classnames";
import React from "react";
import styles from "./pill.module.scss";

const Pill = ({ children, grass, cheddar, sky, deepRed, cement }) => {
  return (
    <div>
      <span
        className={classNames(styles.pill, {
          [`${styles.grass}`]: grass,
          [`${styles.cheddar}`]: cheddar,
          [`${styles.deepRed}`]: deepRed,
          [`${styles.sky}`]: sky,
          [`${styles.cement}`]: cement,
        })}
      >
        {children}
      </span>
    </div>
  );
};

export default Pill;
