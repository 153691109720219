import { Modal, Text, ActionButton } from "components/commons";
import React from "react";
import styles from "./cashin-card-add-card-success-modal";

const CashinCardAddCardSuccessModal = ({ close, active, title, content, primaryText }) => {
  return (
    <Modal
      active={active}
      title={title}
      close={() => {
        close();
      }}
      className={styles.modal}
    >
      <div className={styles.content}>
        <Text className={styles.message} align="center">
          {content}
        </Text>
      </div>
      <ActionButton
        primary
        className={styles.primary}
        center
        items={[
          {
            onClick: () => {
              close();
            },
            text: primaryText,
            primary: true,
          },
        ]}
      />
    </Modal>
  );
};

export default CashinCardAddCardSuccessModal;
