import { ApiPath } from "enums";
import { Get, Post, Put, Delete } from "utils";

export const generateFuelCodeTemplate = async ({ ...body }) => {
  const res = await Post(`${ApiPath.FuelCodeTemplate}`, body);
  return res.data;
};

export const getMyFuelCodeTemplate = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.FuelCodeTemplate}/fleet/${fleetId}`, query);
  return res.data;
};

export const getMyFuelCodeTemplates = async ({ fleetId, page, perPage, ...query }) => {
  const res = await Get(
    `${ApiPath.FuelCodeTemplate}/fleet/${fleetId}?page=${page}&perPage=${perPage}`,
    query
  );
  return res.data;
};

export const deleteFuelCodeTemplate = async ({ fuelCodeTemplateId }) => {
  const res = await Delete(`${ApiPath.FuelCodeTemplate}/${fuelCodeTemplateId}`);
  return res.data;
};

export const updateFuelCodeTemplate = async ({ fuelCodeTemplateId, ...body }) => {
  const res = await Put(`${ApiPath.FuelCodeTemplate}/${fuelCodeTemplateId}`, body);
  return res.data;
};
