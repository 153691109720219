import { Products } from "enums";

export const fleetCardRedemptionsFilterState = (fleetId, location) => {
  const { state } = location || {};
  const { searchKey = "" } = state || {};
  return {
    fleetId,
    startDate: null,
    endDate: null,
    dispensedTo: "all",
    paymentStatus: "all",
    productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
    searchKey,
    redemptionStation: {
      value: null,
      label: "All",
    },
    page: 1,
    perPage: 10,
  };
};
