import { DropdownPaginated } from "components/commons";
import React, { useContext, useCallback } from "react";
import { getVehicles } from "apis";
import locale from "localization";
import { useApi } from "hooks";
import { FleetContext } from "contexts";
import { VehicleStatus } from "enums";

const SelectVehicle = ({ placeholder = locale.searchOrSelect, ...props }) => {
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;
  const {
    request,
    loading,
    result = { vehicles: [], count: 0 },
  } = useApi({
    api: getVehicles,
    pageError: true,
  });

  const fetch = useCallback(
    async (params) => {
      return await request({
        fleetId,
        order: "asc",
        orderBy: "plateNumber",
        perPage: 10,
        status: VehicleStatus.Active,
        ...params,
      });
    },
    [request, fleetId]
  );

  return (
    <DropdownPaginated
      {...props}
      request={async (params) => {
        return await fetch(params);
      }}
      total={result.count}
      loading={loading}
      formatRequest={(result) => {
        const { vehicles = [] } = result || {};
        if (vehicles.length > 0) {
          return vehicles.map((d) => {
            return {
              label: d.model + " (" + d.plateNumber + ")",
              value: d.vehicleId,
            };
          });
        }
        return [];
      }}
      placeholder={placeholder}
    />
  );
};

export default SelectVehicle;
