const ApiPath = {
  Login: "ms-profile/user/login",
  Logout: "ms-profile/user/logout",
  Refresh: "ms-profile/user/refresh",
  GetUser: "ms-profile/user",
  GetUserFleet: "ms-fleet/fleet",
  GetUsers: "ms-profile/user/users",
  GetFleet: "ms-fleet/fleet",
  UpdateUserAccess: "ms-profile/user/user-access",
  GetUserFleets: "ms-fleet/user/fleet",
  GetDrivers: "ms-fleet/driver/fleet",
  BuyFuel: "ms-fleet/buy-fuel",
  Driver: "ms-fleet/driver",
  GetVehicles: "ms-fleet/vehicle/fleet",
  Vehicle: "ms-fleet/vehicle",
  GetCashIn: "ms-fleet/cashin",
  GetCashIns: "ms-fleet/cashin/fleet",
  FuelBalance: "ms-fleet/fuel-balance/fleet",
  FuelCode: "ms-fleet/fuel-code",
  FuelCodeTemplate: "ms-fleet/fuel-code/template",
  VirtualStation: "ms-fleet/virtual-station",
  PriceHistory: "ms-pricing/virtual-station/price-history",
  User: "ms-fleet/user",
  Redemption: "ms-fleet/redemption",
  Station: "ms-fleet/station",
  Wallet: "ms-fleet/wallet",
  CashIn: "ms-fleet/cashin/fleet",
  GenerateReport: "ms-fleet/report/v3",
  GenerateReportList: "ms-report/report",
  GeneratePricingReport: "ms-pricing/report/v2",
  Loan: "ms-loan/loan",
  PriceMovement: "ms-pricing/price-movement",
  Promo: "ms-promo/marketing-banner",
  IntroductoryPromo: "ms-fleet/introductory-promo/fleet",
  FleetRedemption: "ms-fleet/redemption",
  SendResetPassword: "ms-fleet/user/reset-password",
  ChangePassword: "ms-profile/user/password/change",
  ValidateToken: "ms-profile/user/validate/token",
  UpdateReport: (id) => `ms-report/report/${id}`,
  Inbox: "ms-inbox/message/user",
  GetFeedback: "ms-feedback/feedback/fleet",
  VipBiz: "ms-loyalty",
  GetGroups: "ms-fleet/group/fleet",
  Group: "ms-fleet/group",
  CardRedemption: "ms-fleet/payment",
  CardManagement: "ms-fleet/fuel-card",
  NonFuel: "ms-fleet/merchant/payment",
  TwoFactorAuth: "ms-profile/user/otp/v2",
};

export default ApiPath;
