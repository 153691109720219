import { Autocomplete } from "components/commons";
import branch from "../../../assets/branch.json";
import React, { useState } from "react";
import { useMemo } from "react";

const SelectBranch = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useMemo(() => {
    const { value } = props.filterState;

    const values = branch
      .filter((f) => f.region === value)
      .map((p) => {
        return {
          label: p.label,
          value: p.label,
        };
      });
    setOptions(values);
  }, [props.filterState]);

  return <Autocomplete {...props} options={options} />;
};

export default SelectBranch;
