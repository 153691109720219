import React, { useContext } from "react";
import styles from "./loans.module.scss";
import locale from "localization";
import { Intro, Image } from "components/commons";
import { useMount } from "hooks";
import LoansForm from "./loans-form";
import { GlobalDominionImage } from "images";
import { AnalyticsContext } from "contexts";
import { Page } from "enums";

const LoansModule = () => {
  const { page } = useContext(AnalyticsContext);

  useMount(() => {
    page({
      name: Page.ViewVehicleFinancing,
    });
  });

  return (
    <div>
      <div className={styles.intro}>
        <Intro title={locale.vehicleFinancing} />
      </div>
      <Image className={styles.image} src={GlobalDominionImage} />
      <div className={styles.form}>
        <LoansForm actionText={locale.submitAndContactMe} />
      </div>
    </div>
  );
};

export default LoansModule;
