import { ApiPath } from "enums";
import { Post, Get, Put, Delete } from "utils";

export const generateReport = async (query) => {
  const res = await Post(`${ApiPath.GenerateReport}`, {
    ...query,
    connectionId: localStorage.getItem("connectionId"),
  });
  return res.data;
};

export const generatePricingReport = async (query) => {
  const res = await Post(`${ApiPath.GeneratePricingReport}`, {
    ...query,
    connectionId: localStorage.getItem("connectionId")
  });
  return res.data;
};

export const generateReportList = async (query) => {
  const res = await Get(`${ApiPath.GenerateReportList}`, query);
  return res.data;
};

export const updateReport = async ({ reportId, ...body }) => {
  const res = await Put(`${ApiPath.UpdateReport(reportId)}`, body);
  return res.data;
};

export const deleteReport = async ({ reportId }) => {
  const res = await Delete(`${ApiPath.UpdateReport(reportId)}`);
  return res;
};