import { ApiPath } from "enums";
import { Get } from "utils";

export const getPromos = async ({ fleetId, ...body }) => {
  const res = await Get(`${ApiPath.Promo}`, body);
  return res.data;
};

export const getIntroductoryPromo = async ({ fleetId, ...body }) => {
  const res = await Get(`${ApiPath.IntroductoryPromo}/${fleetId}`, body);
  return res.data;
};
