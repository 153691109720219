import { Autocomplete, DateRange, Field, SearchField, Button } from "components/commons";
import { Products, Event } from "enums";
import React, { useCallback } from "react";
import { prettifyProduct } from "utils/pretty.utils";
import styles from "./fuel-history-filter.module.scss";
import locale from "localization";

const FuelHistoryFilter = ({
  filterState,
  onDateRangeChange,
  onProductChange,
  onSearchChange,
  onSearch,
  onDownloadFile,
  fetchingReport,
}) => {
  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onProductChangeCb = useCallback(
    (name, { value }) => {
      if (onProductChange) return onProductChange(value || "all");
    },
    [onProductChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  return (
    <div className={styles.filter}>
      <Field className={styles.date} label={locale.date}>
        <DateRange
          value={{
            startDate: filterState.startDate,
            endDate: filterState.endDate,
          }}
          onChange={onDateRangeChangeCb}
        />
      </Field>
      <Field className={styles.productCode} label={locale.product}>
        <Autocomplete
          name="productCode"
          value={filterState.productCode}
          options={[
            { label: locale.all, value: "all" },
            { label: prettifyProduct(Products.Diesel), value: Products.Diesel },
            { label: prettifyProduct(Products.Gas91), value: Products.Gas91 },
            { label: prettifyProduct(Products.Gas95), value: Products.Gas95 },
            { label: prettifyProduct(Products.Gas97), value: Products.Gas97 },
          ]}
          onChange={onProductChangeCb}
        />
      </Field>
      <Field className={styles.search}>
        <SearchField
          placeholder={locale.buyFuelId}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={onDownloadFile}
          loading={fetchingReport}
          eventName={Event.DownloadFuelHistoryReport}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default FuelHistoryFilter;
