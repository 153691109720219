import { Modal, Text, ActionButton } from "components/commons";
import React from "react";
import styles from "./management-edit-confirm-modal.module.scss";
import classNames from "classnames";
import locale from "localization";
// import { prettifyFuelCodeType } from "utils/pretty.utils";

const EditFleetCardModal = ({
  content,
  primary,
  secondary,
  actions = [],
  children,
  loading: loadingState,
  disabled: disableButtonState,
  ...state
}) => {
  const {
    text: primaryText = "Got It",
    loading,
    onClick: primaryOnClick = () => {
      state.close();
    },
  } = primary || {};

  const {
    text: secondaryText = "Cancel",
    onClick: secondaryClick = () => {
      state.close();
    },
  } = secondary || {};

  // const prettifyLabel = (val) => {
  //   switch (val) {
  //     case "fuelCardType":
  //       return locale.cardType;
  //     case "locqpayLimit":
  //       return locale.locqpayLimit;
  //     case "transactionLimit":
  //       return locale.transactionLimit;
  //     case "driverIds":
  //       return locale.assignedDriver;
  //     case "vehicleIds":
  //       return locale.assignedVehicle;
  //     default:
  //       return "";
  //   }
  // };

  // const displayContent = () => {
  //   let map = [];
  //   if (content != null) {
  //     for (const [a, b] of Object.entries(content)) {
  //       if (b !== "") {
  //         map.push(
  //           <div className="content-container">
  //             <Field label={prettifyLabel(a)} className={styles.inputDiv}>
  //               <Text>
  //                 {prettifyLabel(a) === locale.cardType
  //                   ? prettifyFuelCodeType(b)
  //                   : b === 0 || isNaN(b)
  //                   ? "No Limit"
  //                   : b}
  //               </Text>
  //             </Field>
  //           </div>
  //         );
  //       }
  //     }
  //   }

  //   return map;
  // };
  return (
    <Modal {...state}>
      <div className={classNames(styles.container)}>
        <Text className={styles.subtitle}>{locale.pleaseReviewBefore}</Text>
        <Text className={styles.subtitle}>{locale.fleetCardQuestion}</Text>
      </div>
      {children}
      <ActionButton
        right={secondary}
        center={!secondary}
        loading={loadingState}
        items={[
          ...actions,
          ...(secondary
            ? [
                {
                  disabled: disableButtonState,
                  onClick: () => {
                    secondaryClick();
                    state.close();
                  },
                  text: secondaryText,
                  primary: false,
                },
              ]
            : []),
          {
            onClick: () => {
              primaryOnClick();
            },
            text: primaryText,
            primary: true,
            loading: loading || loadingState,
          },
        ]}
      />
    </Modal>
  );
};

export default EditFleetCardModal;
