import { Autocomplete, DateRange, Field, Filter, SearchField, Button } from "components/commons";
import { FuelCodesStatus, Products, Event } from "enums";
import React, { useCallback } from "react";
import { prettifyProduct, prettifyFuelCodesStatus } from "utils/pretty.utils";
import styles from "./my-fuel-codes-filter.module.scss";
import locale from "localization";
import { fuelCodeTypeOptions } from "../generate-fuel-codes/fuel-code-type.options";

const MyFuelCodesFilter = ({
  filterState,
  onDateRangeChange,
  onSearchChange,
  onFieldChange,
  onSearch,
  resetFilter,
  submitFilter,
  clearFilter,
  filterCount,
  onDownloadFile,
  fetchingReport,
}) => {
  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  return (
    <div className={styles.filter}>
      <Filter
        submit={submitFilter}
        close={resetFilter}
        clear={clearFilter}
        filterCount={filterCount}
      >
        <Field
          className={styles.date}
          label={locale.dateGenerated}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <DateRange
            value={{
              startDate: filterState.startDate,
              endDate: filterState.endDate,
            }}
            onChange={onDateRangeChangeCb}
          />
        </Field>
        <Field
          className={styles.productCode}
          label={locale.status}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            name="status"
            value={filterState.status}
            options={mapOptions(
              [
                FuelCodesStatus.Active,
                FuelCodesStatus.Expired,
                FuelCodesStatus.Scheduled,
                FuelCodesStatus.Redeemed,
                FuelCodesStatus.Deactivated,
              ],
              prettifyFuelCodesStatus
            )}
            onChange={onFieldChange}
          />
        </Field>
        <Field
          className={styles.productCode}
          label={locale.product}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            name="productCode"
            value={filterState.productCode}
            options={mapOptions(
              [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
              prettifyProduct
            )}
            onChange={onFieldChange}
          />
        </Field>
        <Field
          className={styles.productCode}
          label={locale.fuelCodeType}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            name="fuelCodeType"
            value={filterState.fuelCodeType}
            options={[
              {
                label: "All",
                value: "all",
              },
              ...fuelCodeTypeOptions,
            ]}
            onChange={onFieldChange}
          />
        </Field>
        <Field
          className={styles.productCode}
          label={locale.oneTimeUse}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            name="oneTimeUse"
            value={filterState.oneTimeUse}
            options={[
              { label: locale.all, value: "all" },
              { label: locale.yes, value: "true" },
              { label: locale.no, value: "false" },
            ]}
            onChange={onFieldChange}
          />
        </Field>
        <Field
          className={styles.productCode}
          label={locale.autoRenew}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            name="autoRenew"
            value={filterState.autoRenew}
            options={[
              { label: locale.all, value: "all" },
              { label: locale.yes, value: "true" },
              { label: locale.no, value: "false" },
            ]}
            onChange={onFieldChange}
          />
        </Field>
        <Field
          className={styles.productCode}
          label={locale.sentAsSms}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            name="sendSms"
            value={filterState.sendSms}
            options={[
              { label: locale.all, value: "all" },
              { label: locale.yes, value: "true" },
              { label: locale.no, value: "false" },
            ]}
            onChange={onFieldChange}
          />
        </Field>
      </Filter>

      <Field className={styles.search}>
        <SearchField
          placeholder={locale.fuelCode}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={onDownloadFile}
          loading={fetchingReport}
          eventName={Event.DownloadFuelCodeReport}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

const mapOptions = (values = [], prettifier = () => {}) => {
  return [
    { label: locale.all, value: "all" },
    ...values.map((value) => ({
      label: prettifier(value),
      value,
    })),
  ];
};

export default MyFuelCodesFilter;
