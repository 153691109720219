import { Image, Modal, Title, Text, ActionButton } from "components/commons";
import { CautionSign } from "images";
import React, { useState } from "react";
import locale from "localization";
import styles from "./error-modal.module.scss";

const ErrorModal = ({ onClick, content, title, ...modal }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal {...modal}>
      <div className={styles.container}>
        <Image src={CautionSign} className={styles.image} />
        <Title small className={styles.title}>
          {locale.ohSnap}
        </Title>

        <Text>{content || locale.somethingWentWrongPleaseTryAgain}</Text>
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: () => {
              modal.close();
            },
            text: locale.cancel,
          },
          {
            onClick: async () => {
              setLoading(true);
              try {
                await onClick();
                setLoading(false);
              } catch (e) {
                setLoading(false);
              }
            },
            text: locale.tryAgain,
            loading,
          },
        ]}
      />
    </Modal>
  );
};

export default ErrorModal;
