import { Autocomplete } from "components/commons";
import province from "../../../assets/province.json";
import React, { useMemo } from "react";
import { locale } from "localization/en";

const SelectProvince = ({ ...props }) => {
  const options = useMemo(() => {
    return province.map((p) => {
      return {
        label: p.label,
        value: p.label,
      };
    });
  }, []);

  return <Autocomplete {...props} options={[{ label: locale.all, value: "all" }, ...options]} />;
};

export default SelectProvince;
