import { DropdownPaginated } from "components/commons";
import React, { useCallback, useContext } from "react";
import { getStationsNonFuel } from "apis/non-fuel.api";
import { useApi } from "hooks";
import { FleetContext } from "contexts";

const SelectStation = ({ placeholder = "", ...props }) => {
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;

  const {
    request,
    loading,
    result = { merchants: [], count: 0 },
  } = useApi({
    api: getStationsNonFuel,
    pageError: true,
  });

  const fetch = useCallback(
    async (params) => {
      if (params.hasOwnProperty("page")) {
        delete params.page;
      }
      return await request({
        fleetId,
        platformType: "plb",
        isPlbOnboarded: true,
        ...params,
      });
    },
    [request, fleetId]
  );

  return (
    <DropdownPaginated
      {...props}
      request={async (params) => {
        return await fetch(params);
      }}
      total={result.count}
      loading={loading}
      formatRequest={(result) => {
        const { merchants = [] } = result || {};
        if (merchants && merchants.length > 0) {
          const newStations = merchants;

          return newStations.map((d) => {
            return {
              label: d.station.name,
              value: d.stationId,
            };
          });
        }
        return [];
      }}
      placeholder={placeholder}
      {...{ allOption: [{ label: "All", value: null }] }}
    />
  );
};

export default SelectStation;
