import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from "@react-google-maps/api";
import { SelectedPin, Pin, CurrentLocation } from "images";
import env from "environments/env";
import styles from "./station-map.module.scss";
import Slide from "@mui/material/Slide";
import locale from "localization";

const containerStyle = {
  width: "100%",
  height: "557px",
};

const StationMap = (
  {
    stations,
    currentLocation,
    pinnedMap,
    setPinnedMap,
    children,
    setSelectedStation,
    allowAccessLocation,
  },
  ref
) => {
  const sliderRef = useRef();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: env.GOOGLE_MAP_API_KEY,
  });

  const offsetLat = pinnedMap ? 0 : 2;
  const offsetLng = pinnedMap ? 0.011 : 5;
  const zoom = pinnedMap ? 15 : 5.7;

  const center = useMemo(() => {
    return {
      lat: Number(pinnedMap?.lat || currentLocation?.lat) - offsetLat,
      lng: Number(pinnedMap?.lng || currentLocation?.lng) - offsetLng, // offset for panel info spacing
    };
  }, [currentLocation, pinnedMap, offsetLat, offsetLng]);

  const defaultPosition = useMemo(() => {
    return {
      lat: 12.852042,
      lng: 117.039645,
    };
  }, []);

  const [infoShow, setInfoShow] = useState(true);
  const [directions, setDirections] = useState(null);

  const pathFinder = useCallback(
    (goingTo) => {
      if (currentLocation && goingTo) {
        const DirectionsService = new window.google.maps.DirectionsService();
        DirectionsService.route(
          {
            origin: new window.google.maps.LatLng(currentLocation?.lat, currentLocation?.lng),
            destination: new window.google.maps.LatLng(goingTo?.lat, goingTo?.lng),
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setPinnedMap({ lat: goingTo?.lat, lng: goingTo?.lng });
              setDirections(result);
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
      }
    },
    [currentLocation, setPinnedMap]
  );

  useImperativeHandle(ref, () => ({
    showDirection(goingTo) {
      pathFinder(goingTo);
    },
    clearDirection() {
      setDirections(null);
    },
  }));

  // map should be initiated before using DirectionsService()
  useEffect(() => {
    if (window.google && pinnedMap) {
      pathFinder(pinnedMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.google]);

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center.lat && center.lng ? center : defaultPosition}
        zoom={zoom}
      >
        {currentLocation && (
          <Marker
            key="current-location"
            position={currentLocation}
            icon={{
              url: CurrentLocation,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(20, 20),
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
        )}
        {stations?.map((station, index) => {
          const markerKey = station.latitude + station.longitude;
          const selectedMarker = pinnedMap?.lat + pinnedMap?.lng;
          const isSelected = markerKey === selectedMarker;
          let iconUrl = isSelected ? SelectedPin : Pin;
          const markerSize = { anchor: isSelected ? 20 : 15, scale: isSelected ? 40 : 30 };
          const stationLocations = { lat: station.latitude, lng: station.longitude };

          return (
            <Marker
              key={markerKey}
              position={stationLocations}
              onClick={() => {
                if (!isSelected) {
                  setPinnedMap(stationLocations);
                  setSelectedStation(index);
                }
              }}
              icon={{
                url: iconUrl,
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(markerSize.anchor, markerSize.anchor),
                scaledSize: new window.google.maps.Size(markerSize.scale, markerSize.scale),
              }}
            />
          );
        })}
        {children && (
          <>
            {!infoShow && (
              <div
                className={styles.openTab}
                onClick={() => {
                  setInfoShow(!infoShow);
                }}
              >
                <span className="icon-chevron-right" />
              </div>
            )}
            <Slide direction="right" in={infoShow} container={sliderRef.current} key="info-child">
              <div className={styles.infoContainer}>
                <div
                  className={styles.closeTab}
                  onClick={() => {
                    setInfoShow(!infoShow);
                  }}
                >
                  <span className="icon-chevron-left" />
                </div>
                {children}
              </div>
            </Slide>
          </>
        )}

        {!allowAccessLocation && (
          <div className={styles.notAllowAccessLocation} key="access-location-child">
            {locale.priceLocqDOesNotHaveAccessLocation}
          </div>
        )}

        {directions && (
          <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />
        )}
      </GoogleMap>
    )
  );
};

export default forwardRef(StationMap);
