import { Path } from "enums";
import BuyFuelModule from "modules/fuel/buy-fuel/buy-fuel.module";
import FuelHistoryModule from "modules/fuel/fuel-history/fuel-history.module";
import GenerateFuelCodesModule from "modules/fuel/generate-fuel-codes/generate-fuel-codes.module";
import MyFuelCodesModule from "modules/fuel/my-fuel-codes/my-fuel-codes.module";
import PriceHistoryModule from "modules/fuel/price-history/price-history.module";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

const FuelPage = () => {
  return (
    <Switch>
      <Route exact path={Path.BuyFuel} component={BuyFuelModule} />
      <Route exact path={Path.FuelHistory} component={FuelHistoryModule} />
      <Route exact path={Path.FuelGenerateCodes} component={GenerateFuelCodesModule} />
      <Route exact path={Path.PriceHistory} component={PriceHistoryModule} />
      <Route exact path={Path.MyFuelCode} component={MyFuelCodesModule} />
      <Redirect to={Path.BuyFuel} />
    </Switch>
  );
};

export default FuelPage;
