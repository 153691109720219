import { useCallback, useState } from "react";
import styles from "./popper.module.scss";
import classNames from "classnames";

const Popper = ({ children, inline, element }) => {
  const [active, setActive] = useState();

  const togglePopper = useCallback(() => {
    setActive(!active);
  }, [active]);

  if (inline) {
    return (
      <span className={styles.container} onClick={togglePopper}>
        {element}
        <div className={classNames(styles.popper, { [`${styles.active}`]: active })}>
          {children}
        </div>
      </span>
    );
  }
  return (
    <div className={styles.container} onClick={togglePopper}>
      {element}
      <div className={classNames(styles.popper, { [`${styles.active}`]: active })}>{children}</div>
    </div>
  );
};

export default Popper;
