import React from "react";
import { Field, Title } from "..";
import styles from "./counts.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";

const Count = ({ label, children, loading }) => {
  return (
    <Field label={label} className={styles.field}>
      <Title className={styles.title} black>
        {loading ? <Skeleton variant="text" className={styles.loader} /> : children}
      </Title>
    </Field>
  );
};

export default Count;
