import { getGroups } from "apis";
import { Intro, DataTable, PopOverMenu } from "components/commons";
import { FleetContext, AnalyticsContext, UserContext } from "contexts";
import { FleetStatus, Page, UserAccess } from "enums";
import { useApi, useFilter, useMount } from "hooks";
import useModal from "hooks/useModal";
import React, { useCallback, useMemo, useContext } from "react";
import { groupsColumns } from "./groups-columns";
import GroupsFilter from "./groups-filter";
import { groupsFilterState } from "./groups-filter.state";
import GroupForm from "./groups-form";
import GroupStatusModal from "./groups-status-modal";
import styles from "./groups.module.scss";
import locale from "localization";

const GroupsModule = () => {
  const { page } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { userAccess } = useContext(UserContext);
  const { fleetId, status } = fleet;
  const deactivated = status === FleetStatus.Deactivated;
  const statusModal = useModal();

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.Groups;
  });

  const { modifyFilter, modifyFilters, filterState, requestState, submitFilter } = useFilter(
    groupsFilterState(fleetId)
  );
  const groupFormModal = useModal();

  const {
    request: getGroupsRequest,
    loading: fetchingGroups,
    result: getGroupsResult = { groups: [], count: 0 },
  } = useApi({
    api: getGroups,
    pageError: true,
    params: {
      fleetId,
    },
  });

  useMount(() => {
    fetchGroups(requestState);
    page({
      name: Page.ViewManageGroups,
    });
  });

  const fetchGroups = useCallback(
    (rs) => {
      getGroupsRequest(submitFilter({ ...rs }));
    },
    [getGroupsRequest, submitFilter]
  );

  // const changeStatusCb = useCallback(
  //   (driverId, driverName, status) => {
  //     statusModal.show({ status, driverId, driverName });
  //   },
  //   [statusModal]
  // );

  const preparedDriversData = useMemo(() => {
    const { groups } = getGroupsResult;
    if (groups.length > 0) {
      const preparedData = groups.map((d) => {
        const map = new Map();
        map.set("name", d.name);
        // map.set(
        //   "status",
        //   <Pill
        //     grass={d.status === DriverStatus.Active}
        //     cheddar={d.status === DriverStatus.Inactive}
        //   >
        //     {prettifyDriverStatus(d.status)}
        //   </Pill>
        // );
        let options = [];
        let editDetails;

        if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
          editDetails = {
            content: locale.editDetails,
            onClick: () => {
              groupFormModal.show({
                title: locale.editDetails,
                actionText: locale.save,
                create: false,
                groupId: d.groupId,
                initialState: {
                  name: {
                    value: d.name,
                  },
                },
              });
            },
            disabled: deactivated,
          };
          options.push(editDetails);
        }

        map.set("action", <PopOverMenu options={options} />);
        return map;
      });
      return preparedData;
    }
    return [];
  }, [getGroupsResult, groupFormModal, deactivated, getUserAccess]);

  const onStatusChangeCb = useCallback(
    (status) => {
      const { requestState } = modifyFilters({ status, page: 1 });
      fetchGroups(requestState);
    },
    [fetchGroups, modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      fetchGroups(requestState);
    },
    [fetchGroups, modifyFilters]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchGroups(requestState);
    },
    [fetchGroups, modifyFilters]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchGroups(requestState);
    },
    [fetchGroups, modifyFilters]
  );

  return (
    <div>
      <GroupForm
        {...groupFormModal}
        refreshPage={() => {
          getGroupsRequest(requestState);
        }}
      />
      <div>
        <Intro
          title={locale.manageGroups}
          subtitle={locale.manageYourGroups}
          actionText={
            getUserAccess?.fullAccess || getUserAccess.modifyAccess ? locale.addGroup : ""
          }
          actionOnClick={() => {
            groupFormModal.show({
              title: locale.addGroup,
              actionText: locale.addGroup,
              create: true,
              initialState: {
                name: {
                  value: "",
                },
              },
            });
          }}
          actionDisabled={deactivated}
        />
      </div>
      <div className={styles.filters}>
        <div>
          <GroupsFilter
            filterState={filterState}
            onStatusChange={onStatusChangeCb}
            onSearchChange={modifyFilter}
            onSearch={onSearchCb}
          />
        </div>
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingGroups}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={groupsColumns}
          data={preparedDriversData}
          dataCount={getGroupsResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
      <GroupStatusModal refetchDrivers={() => fetchGroups(requestState)} {...statusModal} />
    </div>
  );
};

export default GroupsModule;
