import { Products } from "enums";

export const fuelHistoryState = (fleetId) => ({
  fleetId,
  startDate: null,
  endDate: null,
  productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
  movementType: "all",
  page: 1,
  perPage: 10,
});
