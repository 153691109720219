import locale from "localization";
import { Product } from "components/commons";

export const stationColumns = [
  { key: "stationName", text: `${locale.stationName}`, width: "40%" },
  { key: "city", text: `${locale.city}/${locale.province}`, width: "13%" },
  { key: "operatingHours", text: locale.operatingHours, width: "15%" },
  {
    key: "diesel",
    align: "center",
    text: (
      <div>
        <Product cheddar>{locale.diesel}</Product>
      </div>
    ),
    width: "8%",
  },
  {
    key: "gas91",
    align: "center",
    text: (
      <div>
        <Product grass>{locale.populate(locale.gasName, ["91"])}</Product>
      </div>
    ),
    width: "8%",
  },
  {
    key: "gas95",
    align: "center",
    text: (
      <div>
        <Product salsa>{locale.populate(locale.gasName, ["95"])}</Product>
      </div>
    ),
    width: "8%",
  },
  {
    key: "gas97",
    align: "center",
    text: (
      <div>
        <Product deepBlue>{locale.populate(locale.gasName, ["97"])}</Product>
      </div>
    ),
    width: "8%",
  },
];
