import { useCallback, useEffect, useRef, useState } from "react";

const useWebSocket = ({
  URL = "",
  options = {
    action: "onConnect",
  },
}) => {
  const ws = useRef(null);
  const [message, setMessage] = useState([]);

  const onMessage = useCallback((data) => {
    const messageData = JSON.parse(data);
    if (messageData?.data?.connectionId) {
      localStorage.setItem("connectionId", messageData?.data?.connectionId);
    } else {
      setMessage((oldState) => [messageData?.data, ...oldState]);
    }
  }, []);

  const onConnect = useCallback(() => {
    ws.current.send(JSON.stringify(options));
  }, [options]);

  const onDisconnect = useCallback(() => {
    ws.current.close();
  }, []);

  useEffect(() => {
    if (ws.current?.readyState !== WebSocket.OPEN) {
      ws.current = new WebSocket(URL);
      ws.current.addEventListener("open", onConnect);
      ws.current.addEventListener("close", onDisconnect);
      ws.current.addEventListener("message", (event) => {
        onMessage(event?.data);
      });
    }
    return () => {
      localStorage.removeItem("connectionId");
      ws.current.close();
    };

    //eslint-disable-next-line
  }, []);

  return { message };
};

export default useWebSocket;
