import { Text } from "components/commons";
import React, { useMemo } from "react";
import { prettifyFuelType, formatCurrency } from "utils";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "./upcoming-price-movement.module.scss";
import classNames from "classnames";
import locale from "localization";
import { FuelType } from "enums";
import moment from "moment";

const UpcomingPriceMovement = ({ priceMovement, loading, className }) => {
  const { gas, diesel, effectiveAt } = priceMovement;

  const day = useMemo(() => {
    return moment(effectiveAt).calendar(null, {
      lastWeek: "[Last] dddd hh:mm A",
      sameDay: "[Today] hh:mm A",
      nextDay: "[Tomorrow] hh:mm A",
      nextWeek: "[next] dddd hh:mm A",
      sameElse: () => {
        return effectiveAt;
      },
    });
  }, [effectiveAt]);

  const Movement = ({ price, type }) => {
    const isRollback = price < 0;
    const noMovement = price === 0;
    return (
      <div className={styles.container}>
        <Text
          className={classNames(styles.price, {
            [styles.increase]: !isRollback,
            [styles.rollback]: isRollback,
            [styles.noMovement]: noMovement,
          })}
        >
          <span className={styles.fuelTypeName}>{prettifyFuelType(type)}</span>
          {!noMovement && (
            <span
              className={classNames(styles.arrow, {
                "icon-arrow-up": !isRollback,
                "icon-arrow-down": isRollback,
                [styles.increase]: !isRollback,
                [styles.rollback]: isRollback,
              })}
            />
          )}
          {formatCurrency(Math.abs(price))}
        </Text>
      </div>
    );
  };

  const gasMovemet = useMemo(() => Movement({ price: gas, type: FuelType.Gas }), [gas]);
  const dieselMovemet = useMemo(() => Movement({ price: diesel, type: FuelType.Diesel }), [diesel]);

  return (
    <div className={classNames(styles.upcomingPriceMovement, className)}>
      <div className={styles.textContainer}>
        {loading ? (
          <Skeleton variant="text" className={styles.loader} />
        ) : (
          <locale.Populate
            text={locale.upcomingPriceMovement}
            items={[day, gasMovemet, dieselMovemet]}
          />
        )}
      </div>
    </div>
  );
};

export default UpcomingPriceMovement;
