import { ApiPath } from "enums";
import { Delete, Get, Post, Put } from "utils";

export const getFleet = async ({ fleetId }) => {
  const res = await Get(`${ApiPath.GetFleet}/${fleetId}`);
  return res.data;
};

export const getUserFleets = async () => {
  const res = await Get(`${ApiPath.GetUserFleets}`);
  return res.data;
};

export const getFleetInvite = async ({ token }) => {
  const res = await Get(`${ApiPath.GetFleet}/token/${token}`);
  return res.data;
};

export const acceptInvite = async ({ ...body }) => {
  const res = await Post(`${ApiPath.GetFleet}/invite/accept`, body);
  return res.data;
};

export const getFleetCards = async ({ fleetId }) => {
  const res = await Get(`${ApiPath.GetFleet}/${fleetId}/cards`);
  return res.data;
};

export const deleteFleetCard = async ({ fleetId, cardId }) => {
  const res = await Delete(`${ApiPath.GetFleet}/${fleetId}/cards/${cardId}`);
  return res.data;
};

export const createFleetCard = async ({ fleetId, paymentToken }) => {
  const res = await Post(`${ApiPath.GetFleet}/${fleetId}/cards`, { paymentToken });
  return res.data;
};

export const updateFleetCard = async ({ fleetId, referenceNumber }) => {
  const res = await Put(`${ApiPath.GetFleet}/${fleetId}/cards`, { referenceNumber });
  return res.data;
};

export const getFleetSavings = async ({ fleetId }) => {
  const res = await Get(`${ApiPath.GetFleet}/${fleetId}/savings`);
  return res.data;
};

export const getFleetStations = async ({ fleetId, ...body }) => {
  const res = await Get(`${ApiPath.GetFleet}/${fleetId}/stations`, { body });
  return res.data;
};
