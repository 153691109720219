import locale from "localization";

export const redemptionsColumns = [
  { key: "redemptionId", text: locale.redemptionId, width: "8%" },
  {
    key: "redemptionStation",
    text: `${locale.redemptionStation}/${locale.stationBusinessNames}`,
    width: "20%",
  },
  { key: "driverId", text: locale.driverId, width: "8%" },
  { key: "driverName", text: locale.driversName, width: "8%" },
  { key: "dispensedToPlateNumber", text: locale.dispensedToPlateNumber, width: "8%" },
  { key: "fuelCodeRedemptionDate", text: locale.fuelCodeRedemptionDate, width: "8%" },
  { key: "productVolume", text: `${locale.product}/${locale.volume}`, width: "12%" },
  { key: "averageCreditPrice", text: locale.aveCreditPrice, width: "15%" },
  { key: "pointsEarned", text: locale.pointsEarned, width: "15%" },
  // { key: "pumpPrice", text: locale.redemptionStationPumpPrice, width: "15%" },
  { key: "orNumber", text: locale.orNumber, width: "10%" },
  { key: "status", text: locale.status, width: "10%" },
  { key: "remarks", text: locale.remarks, width: "10%" },
];
