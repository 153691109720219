const ErrorCode = {
  IncorrectCredentials: "P1002",
  InactiveFleetStatus: "F1001",
  InvalidInviteFleetToken: "F1005",
  ExpiredInviteFleetToken: "F1003",
  InsufficientAmount: "W1001",
  CardNotSupported: "PY0036",
  BuyFuelLimitExceeded: "BF1003",
  UserNotFound: "P1005",
  UserInviteStillPending: "U1002",
  FleetNoLongerActive: "F1006",
  UserNotActive: "U1013",
  InvalidForgotPasswordToken: "E9999",
  InvalidPasswordToken: "P1009",
  ExpiredForgotPasswordToken: "P1010",
  BPIError: "C1018",
  InsufficientPoints: "C1000",
  TwoFactorAuth: "P1008",
  OnGoingOTP: "P1028",
  InvalidOTPCode: "P1006",
  ExpiredOTP: "P1007",
  OtpLimitReached: "P1026",
  UserLockOTP: "P1029",
};

export default ErrorCode;
