import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const getBuyFuelHistory = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.BuyFuel}/fleet/${fleetId}`, query);
  return res.data;
};

export const buyFuel = async (query) => {
  const res = await Post(`${ApiPath.BuyFuel}`, query);
  return res.data;
};
