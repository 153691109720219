import { DateRange, Field, Button, GroupCheckbox, Filter, Autocomplete } from "components/commons";
import { Products, Event, PriceMovementType } from "enums";
import React, { useCallback } from "react";
import { prettifyProduct } from "utils/pretty.utils";
import styles from "./price-history-filter.module.scss";
import locale from "localization";
import classNames from "classnames";

const PriceHistoryFilter = ({
  filterState,
  onDateRangeChange,
  onMovementTypeChange,
  onProductChange,
  onDownloadFile,
  fetchingReport,
  submitFilter,
  resetFilter,
  clearFilter,
  filterCount,
}) => {
  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onProductChangeCb = useCallback(
    (value) => {
      if (onProductChange) return onProductChange(value);
    },
    [onProductChange]
  );

  const onMovementTypeChangeCb = useCallback(
    (value) => {
      if (onMovementTypeChange) return onMovementTypeChange(value);
    },
    [onMovementTypeChange]
  );

  return (
    <div className={styles.filter}>
      <Filter
        submit={submitFilter}
        close={resetFilter}
        clear={clearFilter}
        filterCount={filterCount}
      >
        <Field className={styles.date} label={locale.effectiveDate} labelPosition="left">
          <DateRange
            className={styles.dateRange}
            value={{
              startDate: filterState.startDate,
              endDate: filterState.endDate,
            }}
            onChange={onDateRangeChangeCb}
          />
        </Field>
        <Field labelPosition="left" label={locale.priceMovementType}>
          <Autocomplete
            className={styles.dateRange}
            value={filterState.movementType}
            onChange={(name, { value }) => {
              onMovementTypeChangeCb(value);
            }}
            options={[
              {
                label: locale.all,
                value: "all",
              },
              {
                label: locale.initialPriceUponOnboarding,
                value: PriceMovementType.Initial,
              },
              {
                label: locale.nationalPriceMovement,
                value: PriceMovementType.National,
              },
              {
                label: locale.accountSpecificPriceMovement,
                value: PriceMovementType.Account,
              },
              {
                label: locale.discountUpdate,
                value: PriceMovementType.Discount,
              },
            ]}
          />
        </Field>
        <GroupCheckbox
          className={styles.productCode}
          title={locale.productType}
          options={[
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.diesel)}></div>
                  {prettifyProduct(Products.Diesel)}
                </div>
              ),
              value: Products.Diesel,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas91)}></div>
                  {prettifyProduct(Products.Gas91)}
                </div>
              ),
              value: Products.Gas91,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas95)}></div>
                  {prettifyProduct(Products.Gas95)}
                </div>
              ),
              value: Products.Gas95,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas97)}></div>
                  {prettifyProduct(Products.Gas97)}
                </div>
              ),
              value: Products.Gas97,
            },
          ]}
          value={filterState.productCodes}
          onChange={(name, { value }) => {
            onProductChangeCb(value);
          }}
        />
      </Filter>

      {/* <Field className={styles.downloadButton}> */}
      <Button
        startIcon={<span className="icon-download" />}
        exportButton
        onClick={onDownloadFile}
        loading={fetchingReport}
        eventName={Event.DownloadPriceHistoryReport}
      >
        {locale.export}
      </Button>
      {/* </Field> */}
    </div>
  );
};

export default PriceHistoryFilter;
