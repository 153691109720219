import { Path } from "enums";
import Promo from "modules/promo/promo.module";
import React from "react";
import { Route, Switch } from "react-router-dom";

const PromoPage = () => {
  return (
    <Switch>
      <Route exact path={Path.Promo} component={Promo} />
    </Switch>
  );
};

export default PromoPage;
