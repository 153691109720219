import { Field, Modal, TextField, ActionButton } from "components/commons";
import { useApi, useForm, useModal } from "hooks";
import React, { useMemo } from "react";
import { initialState as formState } from "./groups-form.state";
import styles from "./groups.module.scss";
import { createGroup, updateGroup } from "apis/groups.api";
import { useContext } from "react";
import { AnalyticsContext, FleetContext } from "contexts";
import { NotifModal } from "components/modals";
import { Grid } from "@material-ui/core";
import locale from "localization";
import { useEffect } from "react";
import { SuccessAddAccount } from "images";
import { handleRequest } from "utils";
import { Event } from "enums";

const GroupsForm = ({ initialState, actionText, create, groupId, refreshPage, ...state }) => {
  const { track } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;
  const { close } = state;
  const notifModal = useModal();
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    validateField,
    clearForm,
    isFormSubmittable,
  } = useForm({
    initialState: form,
  });

  useEffect(() => {
    if (state.active) {
      clearForm();
    }
    //eslint-disable-next-line
  }, [state.active]);

  const addGroupRequest = useApi({
    api: createGroup,
    handleOwnError: {
      badrequest: true,
    },
  });

  const editGroupRequest = useApi({
    api: updateGroup,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      groupId,
    },
  });

  const loading = addGroupRequest.loading || editGroupRequest.loading;

  const submit = async () => {
    const params = getFormValues();

    const apiRequest = create ? addGroupRequest : editGroupRequest;
    const eventType = create ? Event.AddGroup : Event.EditGroup;

    handleRequest(
      async () => {
        const finalParams = {
          ...params,
          fleetId,
        };
        await apiRequest.request(finalParams, () => submit());
        track(eventType, finalParams);
        close();
        refreshPage();
        notifModal.show({
          image: SuccessAddAccount,
          title: create ? locale.groupSuccessRegistered : locale.groupSuccessEdit,
          contentHighlight: [<b>{params.name}</b>],
          content: create
            ? locale.successfullyRegisteredGroupName
            : locale.successfullyChangeInfoOfGroupName,
          secondary: create
            ? {
                text: locale.registerNewGroup,
                onClick: () => {
                  state.show({
                    title: locale.addGroup,
                    actionText: locale.add,
                    create: true,
                  });
                },
              }
            : null,
        });
      },
      null,
      {
        D1000: () => {},
      }
    );
  };

  return (
    <div>
      <NotifModal {...notifModal} />
      <Modal close={loading ? false : close} {...state} className={styles.modalContent}>
        <div className={styles.formContent}>
          <div className={styles.content}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12}>
                <Field {...fields.name}>
                  <TextField {...fields.name} onChange={modifyField} onBlur={validateField} />
                </Field>
              </Grid>
            </Grid>
          </div>
        </div>
        <ActionButton
          right
          items={[
            {
              onClick: () => {
                state.close();
              },
              disabled: loading,
              text: locale.cancel,
            },
            {
              onClick: () => {
                submitForm(submit);
              },
              disabled: !isFormSubmittable,
              loading,
              text: actionText,
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default GroupsForm;
