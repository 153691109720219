import { batchGenerateFuelCode } from "apis";
import {
  BackLink,
  Button,
  Image,
  Intro,
  Modal,
  PopOverMenu,
  Product,
  Table,
  Text,
} from "components/commons";
import { LeaveModal } from "components/modals";
import { FleetContext, AnalyticsContext, AppContext } from "contexts";
import { DateTime, Path, Products, FleetStatus, FuelCodeType, Event, Page } from "enums";
import { useApi, useModal, useMount } from "hooks";
import locale from "localization";
import moment from "moment";
import { useCallback, useContext, useMemo } from "react";
import { useState } from "react";
import { formatDate, handleRequest, parseLiter, formatVolume } from "utils";
import { prettifyFuelCodeType, prettifyProduct } from "utils/pretty.utils";
import ConfirmGenerateFuelCodesModal from "./confirm-generate-fuel-codes-modal";
import { generateFuelCodesColumns } from "./generate-fuel-codes-columns";
import styles from "./generate-fuel-codes.module.scss";
import SuccessGenerateFuelCodeModal from "./success-generate-fuel-code-modal";
import { EmptyBox } from "images";
import GenerateFuelCodeModal from "./generate-fuel-code-modal";
import FuelCodeForm from "./fuel-code-form";

const GenerateFuelCodesModule = () => {
  const { errorModal } = useContext(AppContext);
  const { page, track } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { fleetId, status } = fleet;
  const deactivated = status === FleetStatus.Deactivated;

  const [fuelCodes, setFuelCodes] = useState([]);

  const addFuelCodeModal = useModal();
  const editFuelCodeModal = useModal();
  const confirmGenerateFuelCodesModal = useModal();
  const successGenerateFuelCodeModal = useModal();

  const { request: batchGenerateFuelCodeRequest, loading: batchGeneratingFuelCode } = useApi({
    api: batchGenerateFuelCode,
    handleOwnError: true,
  });

  const generateFuelCodes = useCallback(async () => {
    const fuelCodesRequest = fuelCodes.map((fc) => {
      let driverDetailsPayload,
        smsDetailsPayload = {};

      if (
        [FuelCodeType.VehicleSpecific, FuelCodeType.AnyDriverAnyVehicle].includes(fc.fuelCodeType)
      ) {
        smsDetailsPayload = fc.sendSms ? { mobileNumber: fc.mobileNumber } : {};
      } else {
        driverDetailsPayload = {
          driverIds: [fc.driverId],
        };
      }
      const obj = {
        fleetId,
        ...driverDetailsPayload,
        vehicleIds: fc.vehicleId ? [fc.vehicleId] : [],
        startDate: formatDate(fc.period.startDate, DateTime.J),
        endDate: formatDate(fc.period.endDate, DateTime.K),
        fuelCodeType: fc.fuelCodeType,
        productCode: fc.productCode,
        literVolume: parseLiter(fc.literVolume),
        sendFuelCode: fc.sendAsSms,
        saveTemplate: fc.saveTemplate,
        maxUsage: fc.limitOneTime ? 1 : 0,
        autoRenew: fc.autoRenew,
        ...smsDetailsPayload,
      };

      if (fc.fuelCodeType === FuelCodeType.AnyDriverAnyVehicle) {
        delete obj.driverIds;
        delete obj.vehicleIds;
      }
      return obj;
    });

    handleRequest(
      async () => {
        await batchGenerateFuelCodeRequest({ body: fuelCodesRequest }, generateFuelCodes);
        track(Event.BatchFuelCode, { fuelCodesRequest });
        confirmGenerateFuelCodesModal.close();
        successGenerateFuelCodeModal.show();
        setFuelCodes([]);
      },
      {
        D1002: () => {
          errorModal.show({
            content: locale.driverFuelCodeError,
            onClick: async () => {
              await generateFuelCodes();
            },
          });
        },
        V1002: () => {
          errorModal.show({
            content: locale.vehicleFuelCodeError,
            onClick: () => {
              generateFuelCodes();
            },
          });
        },
      }
    );
  }, [
    fuelCodes,
    fleetId,
    batchGenerateFuelCodeRequest,
    track,
    confirmGenerateFuelCodesModal,
    successGenerateFuelCodeModal,
    errorModal,
  ]);

  const addFuelCode = useCallback(
    (fuelCode) => {
      track(Event.QueueFuelCode, fuelCode);
      setFuelCodes([...fuelCodes, fuelCode]);
    },
    [fuelCodes, track]
  );

  const updateFuelCode = useCallback(
    (fuelCode, index) => {
      const fuelCodesCopy = [...fuelCodes];

      fuelCodesCopy[index] = fuelCode;

      setFuelCodes([...fuelCodesCopy]);
    },
    [fuelCodes]
  );

  const editFuelCode = useCallback(
    (index) => {
      const d = fuelCodes[index];
      const {
        fuelCodeType,
        productCode,
        literVolume,
        period,
        durationDays,
        limitOneTime,
        saveTemplate,
        sendAsSms,
        mobileNumber,
        autoRenew,
      } = d;

      editFuelCodeModal.show({
        title: locale.editFuelCode,
        onSubmit: (fuelCode) => {
          updateFuelCode(fuelCode, index);
          editFuelCodeModal.close();
        },
        actionText: locale.save,
        initialState: {
          mobileNumber: {
            value: mobileNumber,
          },
          fuelCodeType: {
            value: fuelCodeType,
          },
          driverIds: {
            value: d.driverId,
            label: d.driverName,
            driverMobileNumber: d.driverMobileNumber,
          },
          vehicleIds: {
            value: d.vehicleId,
            label: d.vehicleName,
          },
          productCode: {
            value: productCode,
          },
          literVolume: {
            value: literVolume,
          },
          period: {
            value: {
              startDate: new Date(period.startDate),
              endDate: new Date(period.endDate),
            },
          },
          duration: {
            value: durationDays,
          },
          limitOneTime: {
            value: limitOneTime,
          },
          sendAsSms: {
            value: sendAsSms,
          },
          saveTemplate: {
            value: saveTemplate,
          },
          autoRenew: {
            value: autoRenew,
          },
        },
      });
    },
    [fuelCodes, editFuelCodeModal, updateFuelCode]
  );

  const duplicateFuelCode = useCallback(
    (index) => {
      setFuelCodes([...fuelCodes, fuelCodes[index]]);
    },
    [fuelCodes]
  );

  const deleteFuelCode = useCallback(
    (index) => {
      const fuelCodesCopy = [...fuelCodes];
      fuelCodesCopy.splice(index, 1);
      setFuelCodes(fuelCodesCopy);
    },
    [fuelCodes]
  );

  const preparedDrivers = useMemo(() => {
    const driversMap = new Map();
    fuelCodes.forEach((fc, idx) => {
      if (fc.sendAsSms) {
        driversMap.set(
          idx,
          `${fc.driverMobileNumber || fc.mobileNumber || ""} (${
            fc.driverName || fc.vehicleName || locale.any
          })`
        );
      }
    });
    const driverList = [];
    driversMap.forEach((val) => {
      driverList.push(val);
    });
    return driverList;
  }, [fuelCodes]);

  const preparedFuelCodesData = useMemo(() => {
    const preparedData = fuelCodes.map((fc, index) => {
      const map = new Map();
      map.set("fuelCodeType", prettifyFuelCodeType(fc.fuelCodeType));
      map.set("assignedDriver", fc.driverName || <Text italic>{locale.any}</Text>);
      map.set("assignedVehicle", fc.vehicleName || <Text italic>{locale.any}</Text>);
      map.set(
        "product",
        <Product
          grass={fc.productCode === Products.Gas91}
          salsa={fc.productCode === Products.Gas95}
          deepBlue={fc.productCode === Products.Gas97}
          cheddar={fc.productCode === Products.Diesel}
        >
          {prettifyProduct(fc.productCode)}
        </Product>
      );
      map.set("volumeRedeemLimit", formatVolume(fc.literVolume));
      map.set(
        "startDate",
        <div>
          <Text>{moment(fc.period.startDate).format(DateTime.A)}</Text>
          <Text>{moment(fc.period.startDate).format(DateTime.B)}</Text>
        </div>
      );
      map.set(
        "endDate",
        <div>
          <Text>{moment(fc.period.endDate).format(DateTime.A)}</Text>
          <Text>{moment(fc.period.endDate).endOf("day").format(DateTime.B)}</Text>
        </div>
      );
      map.set("oneTimeUse", fc.limitOneTime ? locale.yes : locale.no);
      map.set("autoRenew", fc.autoRenew ? locale.yes : locale.no);
      map.set("sendSms", fc.sendAsSms ? locale.yes : locale.no);
      map.set(
        "action",
        <PopOverMenu
          options={[
            {
              content: locale.edit,
              onClick: () => {
                editFuelCode(index);
              },
            },
            {
              content: locale.duplicate,
              onClick: () => {
                duplicateFuelCode(index);
              },
            },
            {
              content: locale.delete,
              onClick: () => {
                deleteFuelCode(index);
              },
            },
          ]}
        />
      );
      return map;
    });

    return preparedData;
  }, [fuelCodes, editFuelCode, deleteFuelCode, duplicateFuelCode]);

  useMount(() => {
    page({
      name: Page.ViewGenerateFuelCode,
    });
  });

  return (
    <div>
      <BackLink text={locale.myFuelCodes} path={Path.MyFuelCode} />
      <div className={styles.intro}>
        <Intro
          title={locale.generateFuelCode}
          actionText={locale.addFuelCode}
          actionOnClick={() => {
            addFuelCodeModal.show({
              title: locale.addFuelCode,
              onSubmit: addFuelCode,
              actionText: locale.add,
            });
          }}
          actionDisabled={deactivated}
          actionOutlined={true}
          rounded={false}
          thin={true}
        />
      </div>
      <div className={styles.table}>
        <Table columns={generateFuelCodesColumns} data={preparedFuelCodesData} />
        {preparedFuelCodesData.length <= 0 && (
          <div className={styles.empty}>
            <div>
              <Image src={EmptyBox} />
              <Text className={styles.text}>{locale.youMayCreateMultipleFuelCodes}</Text>
              <Text className={styles.text}>{locale.clickAddFuelToStart}</Text>
            </div>
          </div>
        )}
      </div>
      <Button
        className={styles.continue}
        primary
        onClick={() => {
          confirmGenerateFuelCodesModal.show({ fuelCodeData: fuelCodes });
        }}
        disabled={fuelCodes.length <= 0 || deactivated}
      >
        {locale.continue}
      </Button>
      <Modal {...editFuelCodeModal} className={styles.editFuelCodeModal}>
        <FuelCodeForm
          {...editFuelCodeModal}
          initialState={editFuelCodeModal.initialState}
          submit={(params) => {
            editFuelCodeModal.onSubmit(params);
          }}
          title=""
        />
      </Modal>

      <GenerateFuelCodeModal
        {...addFuelCodeModal}
        onAdd={(fuelCodeValues) => {
          setFuelCodes([...fuelCodes, ...fuelCodeValues]);
        }}
      />
      <ConfirmGenerateFuelCodesModal
        generating={batchGeneratingFuelCode}
        onGenerate={generateFuelCodes}
        fuelCodeCount={fuelCodes.length}
        drivers={preparedDrivers}
        deactivated={deactivated}
        {...confirmGenerateFuelCodesModal}
      />
      <SuccessGenerateFuelCodeModal {...successGenerateFuelCodeModal} />
      <LeaveModal
        isListenerActive={fuelCodes.length > 0}
        message={locale.youWillLoseAllFuelCodes}
      />
    </div>
  );
};

export default GenerateFuelCodesModule;
