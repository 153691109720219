import PaymentStatus from "enums/payment-status";
export const initialFilterState = () => ({
  status: {
    isSelectedAll: true,
    value: [PaymentStatus.Success, PaymentStatus.Voided],
  },
  redemptionStation: {
    value: null,
    label: "All",
  },
  page: 1,
  perPage: 10,
  endDate: null,
  startDate: null,
});
