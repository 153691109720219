import { Modal, Text, ActionButton } from "components/commons";
import locale from "localization";
import React from "react";
import styles from "./loans-success-modal.module.scss";

const LoansSuccessModal = ({ data, close, active }) => {
  const message = locale.loanInquirySentMessage;
  const title = locale.loanInquirySent;
  const buttonMessage = locale.gotIt;

  return (
    <Modal active={active} title={title} close={close} className={styles.modal}>
      <div className={styles.content}>
        <Text className={styles.message} align="center">
          {message}
        </Text>
        <Text className={styles.message} align="left">
          <div className={styles.inquiryId}>
            {locale.inquiryId} <span>{data.inquiryId}</span>
          </div>
        </Text>
      </div>
      <ActionButton
        primary
        className={styles.primary}
        center
        items={[
          {
            onClick: () => {
              close();
            },
            text: buttonMessage,
            primary: true,
          },
        ]}
      />
    </Modal>
  );
};

export default LoansSuccessModal;
