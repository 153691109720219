import classNames from "classnames";
import React from "react";
import styles from "./select-panel.module.scss";
import { Text } from "..";

const SelectPanel = ({
  active,
  title,
  children,
  onClick,
  className,
  classNameContainer,
  disabled,
  enabled,
  ...props
}) => {
  return (
    <div
      className={classNames(styles.container, classNameContainer, {
        [`${styles.inactive}`]: !active,
        [`${styles.disabled}`]: !enabled,
      })}
      onClick={() => {
        if (!disabled) {
          onClick({
            title,
            children,
            active,
            ...props,
          });
        }
      }}
    >
      <div className={classNames(styles.panel, className)}>{children}</div>
      <div className={styles.title}>
        {typeof title === "string" ? (
          <Text strong colored={active}>
            {title}
          </Text>
        ) : (
          title
        )}
      </div>
    </div>
  );
};

export default SelectPanel;
