import locale from "localization";

export const generateFuelCodesColumns = [
  { key: "fuelCodeType", text: locale.fuelCodeType },
  { key: "assignedDriver", text: locale.assignedDriver },
  { key: "assignedVehicle", text: locale.assignedVehicle },
  { key: "product", text: locale.product },
  { key: "volumeRedeemLimit", text: locale.volumeRedeemLimit },
  { key: "startDate", text: locale.startDate },
  { key: "endDate", text: locale.endDate },
  { key: "oneTimeUse", text: `${locale.oneTimeUse}?` },
  { key: "sendSms", text: locale.sendSms },
  { key: "autoRenew", text: locale.autoRenew },
  { key: "action", text: locale.action },
];
