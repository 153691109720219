import { ApiPath } from "enums";
import { Get, Put } from "utils";

export const getFleetCardList = async ({ fleetId, page, perPage, ...query }) => {
  query.fuelCardStatuses = query.fuelCardStatuses.join(",");
  query.fuelCardTypes = query.fuelCardTypes.join(",");
  const res = await Get(
    `${ApiPath.CardManagement}/fleet/${fleetId}?page=${page}&perPage=${perPage}`,
    query
  );
  return res.data;
};

export const updateFuelCard = async ({ fuelCardId, ...query }) => {
  const res = await Put(`${ApiPath.CardManagement}/${fuelCardId}`, query);
  return res.data;
};
