import { Autocomplete } from "components/commons";
import region from "../../../assets/region.json";
import React, { useMemo } from "react";

const SelectRegion = ({ ...props }) => {
  const options = useMemo(() => {
    return region.map((p) => {
      return {
        label: p.label,
        value: p.value,
      };
    });
  }, []);

  return <Autocomplete {...props} options={options} />;
};

export default SelectRegion;
