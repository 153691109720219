import { updateFuelCode } from "apis";
import { Modal, Text, ActionButton } from "components/commons";
import { FuelCodesStatus, Event } from "enums";
import { useApi } from "hooks";
import locale from "localization";
import React, { useCallback, useContext } from "react";
import styles from "./my-fuel-codes-status-modal.module.scss";
import { handleRequest } from "utils";
import { AnalyticsContext } from "contexts";

const MyFuelCodesStatusModal = ({ refetchMyFuelCodes, fuelCodeId, fuelCode, close, active }) => {
  const { track } = useContext(AnalyticsContext);

  const { request: updateFuelCodeRequest, loading: updatingFuelCode } = useApi({
    api: updateFuelCode,
  });

  const updateStatusCb = useCallback(async () => {
    track(Event.DeactivateFuelCode, { fuelCodeId, status: FuelCodesStatus.Deactivated });
    handleRequest(async () => {
      await updateFuelCodeRequest({ fuelCodeId, status: FuelCodesStatus.Deactivated }, () =>
        updateStatusCb()
      );
      refetchMyFuelCodes();
      close();
    });
  }, [track, updateFuelCodeRequest, fuelCodeId, refetchMyFuelCodes, close]);

  const deactivateText = (
    <locale.Populate
      text={locale.youAreAboutDeactivateFuelCode}
      items={[<strong>{fuelCode}</strong>, fuelCode]}
    />
  );

  return (
    <Modal
      active={active}
      title={locale.askDeactivateFuelCode}
      close={close}
      className={styles.modal}
    >
      <div className={styles.content}>
        <Text align="center">{deactivateText}</Text>
      </div>
      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: close,
          },
          {
            loading: updatingFuelCode,
            onClick: updateStatusCb,
            text: locale.yesDeactivateIt,
          },
        ]}
      />
    </Modal>
  );
};

export default MyFuelCodesStatusModal;
