import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getRedemptions = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.Redemption}/fleet/${fleetId}`, query);
  return res.data;
};

export const getFleetConsumption = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.Redemption}/consumption/fleet/${fleetId}`, query);
  return res.data;
};

export const voidRedemption = async ({ ...body }) => {
  const res = await Post(`ms-fleet/redemption/void`, body);
  return res.data;
};

export const updateRedemptionRemarks = async ({ id, ...body }) => {
  const res = await Put(`${ApiPath.FleetRedemption}/${id}/remarks`, body);
  return res.data;
};
