import { AnalyticsContext } from "contexts";
import { Path, Event } from "enums";
import { useMount } from "hooks";
import { useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";

const CashinResultPage = () => {
  const { track } = useContext(AnalyticsContext);

  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const cashInId = query.get("cashInId") || query.get("param1");
  const referenceNumber = query.get("referenceNumber");
  const paymentMethod = query.get("paymentMethod");

  useMount(() => {
    track(Event.CashInResult, {
      cashInId,
      paymentMethod,
      referenceNumber,
      url: window.location.pathname,
    });
  });

  return (
    <CashInWithStatusComponent
      cashInId={cashInId}
      referenceNumber={referenceNumber}
      paymentMethod={paymentMethod}
    />
  );
};

const CashInWithStatusComponent = ({ status, cashInId, referenceNumber, paymentMethod }) => {
  return (
    <Redirect
      to={{
        pathname: `${Path.CashIn}`,
        state: { cashInStatus: status, cashInId, referenceNumber, paymentMethod },
      }}
    />
  );
};

export default CashinResultPage;
