import { Autocomplete, Field, SearchField } from "components/commons";
import React, { useCallback } from "react";
import styles from "./driver-filter.module.scss";
import { DriverStatus } from "enums";
import { prettifyDriverStatus } from "utils";
import locale from "localization";

const DriverFilter = ({ filterState, onStatusChange, onSearchChange, onSearch }) => {
  const onStatusChangeCb = useCallback(
    (name, { value }) => {
      if (onStatusChange) return onStatusChange(value || "all");
    },
    [onStatusChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  return (
    <div className={styles.filter}>
      <Field className={styles.status} label="Status">
        <Autocomplete
          name="status"
          value={filterState.status}
          options={[
            { label: locale.all, value: "all" },
            { label: prettifyDriverStatus(DriverStatus.Active), value: DriverStatus.Active },
            { label: prettifyDriverStatus(DriverStatus.Inactive), value: DriverStatus.Inactive },
          ]}
          onChange={onStatusChangeCb}
        />
      </Field>
      <Field className={styles.search}>
        <SearchField
          placeholder={locale.nameMobileNumberDriverId}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          restrict={false}
        />
      </Field>
    </div>
  );
};

export default DriverFilter;
