import { updateVehicle } from "apis";
import { Modal, Text, ActionButton } from "components/commons";
import { VehicleStatus, Event } from "enums";
import { useApi } from "hooks";
import React, { useCallback, useContext } from "react";
import styles from "./vehicle-status-modal.module.scss";
import locale from "localization";
import { handleRequest } from "utils";
import { AnalyticsContext } from "contexts";

const VehicleStatusModal = ({ refetchVehicles, vehicleId, plateNumber, status, close, active }) => {
  const { track } = useContext(AnalyticsContext);

  const { request: updateVehicleRequest, loading: updatingVehicle } = useApi({
    api: updateVehicle,
  });

  const updateStatusCb = useCallback(async () => {
    const newStatus =
      status === VehicleStatus.Active ? VehicleStatus.Inactive : VehicleStatus.Active;
    if (newStatus === VehicleStatus.Inactive) {
      track(Event.DeactivateVehicle, { vehicleId, status: newStatus });
    }
    handleRequest(async () => {
      await updateVehicleRequest({ vehicleId, status: newStatus }, () => updateStatusCb());
      refetchVehicles();
      close();
    });
  }, [status, track, vehicleId, updateVehicleRequest, refetchVehicles, close]);

  const deactivateText = (
    <locale.Populate
      text={locale.youAreAboutToDeactivateVehicle}
      items={[<strong>{plateNumber}</strong>, plateNumber]}
    />
  );

  const activateText = (
    <locale.Populate
      text={locale.youAreAboutToReactivateVehicle}
      items={[<strong>{plateNumber}</strong>, plateNumber]}
    />
  );

  return (
    <Modal
      active={active}
      title={
        status === VehicleStatus.Active ? locale.askDeactivateVehicle : locale.askReactivateVehicle
      }
      close={close}
      className={styles.modal}
    >
      <div className={styles.content}>
        <Text align="center">
          {status === VehicleStatus.Active ? deactivateText : activateText}
        </Text>
      </div>
      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: close,
          },
          {
            loading: updatingVehicle,
            onClick: updateStatusCb,
            text: status === VehicleStatus.Active ? locale.yesDeactivateIt : locale.yesReactivateIt,
          },
        ]}
      />
    </Modal>
  );
};

export default VehicleStatusModal;
