import locale from "localization";

export const cashInColumns = [
  { key: "cashInId", text: locale.cashInId, width: "12.5%" },
  { key: "referenceNumber", text: locale.referenceNumber, width: "15%" },
  { key: "amount", text: locale.amount, width: "20%" },
  { key: "createdDate", text: locale.createdDate, width: "15%" },
  { key: "fulfilledDate", text: locale.fullfilledDate, width: "15%" },
  { key: "cashInChannel", text: locale.cashInChannel, width: "15%" },
  { key: "status", text: locale.status, width: "12.5%" },
];
