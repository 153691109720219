import { Field, Modal, TextField, ActionButton } from "components/commons";
import { useApi, useForm, useModal } from "hooks";
import React, { useMemo, useEffect } from "react";
import { initialState as formState } from "./vehicle-form.state";
import styles from "./vehicle.module.scss";
import { createVehicle, updateVehicle } from "apis/vehicle.api";
import { useContext } from "react";
import { AnalyticsContext, FleetContext } from "contexts";
import { NotifModal } from "components/modals";
import locale from "localization";
import { handleRequest } from "utils";
import { Event } from "enums";

const VehicleForm = ({
  initialState,
  actionText,
  create,
  status,
  vehicleId,
  refreshPage,
  ...state
}) => {
  const { track } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;
  const { close } = state;
  const notifModal = useModal();
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    applyFieldErrors,
    validateField,
    clearForm,
    isFormSubmittable,
  } = useForm({
    initialState: form,
  });

  useEffect(() => {
    if (state.active) {
      clearForm();
    }
    //eslint-disable-next-line
  }, [state.active]);

  const addVehicleRequest = useApi({
    api: createVehicle,
    handleOwnError: {
      badrequest: true,
    },
  });

  const editVehicleRequest = useApi({
    api: updateVehicle,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      vehicleId,
    },
  });

  const loading = addVehicleRequest.loading || editVehicleRequest.loading;

  const submit = async () => {
    const params = getFormValues();

    const apiRequest = create ? addVehicleRequest : editVehicleRequest;
    const eventType = create ? Event.AddVehicle : Event.EditVehicle;

    handleRequest(
      async () => {
        const finalParams = {
          ...params,
          fleetId,
          status,
        };
        await apiRequest.request(finalParams, () => submit());
        track(eventType, finalParams);
        close();
        refreshPage();
        notifModal.show({
          title: create
            ? locale.vehicleSuccessfullyRegistered
            : locale.vehicleInformationSuccessfullyEdited,
          contentHighlight: [<b>{params.plateNumber}</b>],
          content: create
            ? locale.youHaveSuccessfullyRegisteredPlateNumber
            : locale.youHaveEditedVehicleDetails,
          secondary: create
            ? {
                text: locale.registerNewVehicle,
                onClick: () => {
                  state.show({
                    title: locale.addVehicles,
                    actionText: locale.add,
                    create: true,
                  });
                },
              }
            : null,
        });
      },
      {
        V1000: () => {
          applyFieldErrors({
            plateNumber: locale.vehicleIdAlreadyExists,
          });
        },
      }
    );
  };

  return (
    <div>
      <NotifModal {...notifModal} />
      <Modal close={loading ? false : close} {...state}>
        <div className={styles.formContent}>
          <Field {...fields.plateNumber}>
            <TextField {...fields.plateNumber} onChange={modifyField} onBlur={validateField} />
          </Field>
          <Field {...fields.model}>
            <TextField {...fields.model} onChange={modifyField} />
          </Field>
          <Field {...fields.remarks}>
            <TextField {...fields.remarks} onChange={modifyField} />
          </Field>
        </div>
        <ActionButton
          right
          items={[
            {
              onClick: () => {
                state.close();
              },
              disabled: loading,
              text: locale.cancel,
            },
            {
              onClick: () => {
                submitForm(submit);
              },
              disabled: !isFormSubmittable,
              loading,
              text: actionText,
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default VehicleForm;
