import locale from "localization/en";

export const columnsManual = [
  { key: "locqpayId", text: locale.locqpayId, width: "100px" },
  {
    key: "redemptionStations",
    text: `${locale.redemptionStation} / ${locale.stationBusinessNames}`,
    width: "300px",
  },
  { key: "driversNameAndId", text: `${locale.driversName} / ${locale.id}`, width: "150px" },
  { key: "plateNumber", text: `${locale.plateNumber}`, width: "100px" },
  {
    key: "fleetCardAndTransactionDate",
    text: `${locale.fleetCardNo} / ${locale.transactionDate}`,
    width: "100px",
  },
  {
    key: "product",
    text: `${locale.product}`,
    width: "100px",
  },
  {
    key: "transactionRemarks",
    text: `${locale.transactionRemarks}`,
    width: "150px",
  },
  {
    key: "finalAmountPaid",
    text: `${locale.finalAmountPaid}`,
    width: "100px",
  },
  {
    key: "poNumber",
    text: `${locale.poNumber}`,
    width: "100px",
  },
  {
    key: "orNo",
    text: `${locale.orNo}`,
    width: "100px",
  },
  {
    key: "status",
    text: `${locale.status}`,
    width: "100px",
  },
  {
    key: "remarks",
    text: `${locale.remarks}`,
    width: "150px",
  },
];
