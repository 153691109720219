import { updateDriver } from "apis";
import { Modal, Text, ActionButton } from "components/commons";
import { DriverStatus, Event } from "enums";
import { useApi } from "hooks";
import locale from "localization";
import React, { useCallback, useContext } from "react";
import styles from "./groups-details-modal.module.scss";
import { handleRequest } from "utils";
import { AnalyticsContext } from "contexts";

const GroupsDetailsModal = ({ refetchDrivers, driverId, driverName, status, close, active }) => {
  const { track } = useContext(AnalyticsContext);

  const { request: updateDriverRequest, loading: updatingDriver } = useApi({
    api: updateDriver,
  });

  const updateStatusCb = useCallback(async () => {
    const newStatus = status === DriverStatus.Active ? DriverStatus.Inactive : DriverStatus.Active;
    if (newStatus === DriverStatus.Inactive) {
      track(Event.DeactivateDriver, { driverId, status: newStatus });
    }
    handleRequest(async () => {
      await updateDriverRequest({ driverId, status: newStatus }, () => updateStatusCb());
      refetchDrivers();
      close();
    });
  }, [status, track, driverId, updateDriverRequest, refetchDrivers, close]);

  const deactivateText = (
    <locale.Populate
      text={locale.youAreAboutDeactivateDriver}
      items={[<strong>{driverName}</strong>, driverName]}
    />
  );

  const activateText = (
    <locale.Populate
      text={locale.youAreAboutReactivateDriver}
      items={[<strong>{driverName}</strong>, driverName]}
    />
  );

  return (
    <Modal
      active={active}
      title={
        status === DriverStatus.Active ? locale.askDeactivateDriver : locale.askReactivateDriver
      }
      close={close}
      className={styles.modal}
    >
      <div className={styles.content}>
        <Text align="center">{status === DriverStatus.Active ? deactivateText : activateText}</Text>
      </div>
      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: close,
          },
          {
            loading: updatingDriver,
            onClick: updateStatusCb,
            text: status === DriverStatus.Active ? locale.yesDeactivateIt : locale.yesReactivateIt,
          },
        ]}
      />
    </Modal>
  );
};

export default GroupsDetailsModal;
