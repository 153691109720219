import { ApiPath } from "enums";
import { Get, Post, Put, Delete } from "utils";

export const getGroups = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.GetGroups}/${fleetId}`, query);
  return res.data;
};

export const createGroup = async ({ ...body }) => {
  const res = await Post(`${ApiPath.Group}`, body);
  return res.data;
};

export const updateGroup = async ({ groupId, ...body }) => {
  const res = await Put(`${ApiPath.Group}/${groupId}`, body);
  return res.data;
};

export const deleteGroup = async ({ fleetId, ...query }) => {
  const res = await Delete(`${ApiPath.Group}/${fleetId}`, query);
  return res.data;
};
