import React from "react";
import styles from "./dashboard-chart-header.module.scss";
import moment from "moment";
import { Products, DateTime } from "enums";
import PriceMovement from "components/price-movement/price-movement";

const DashboardChartHeader = ({ data, toolTipData, loading }) => {
  // month switch between hover date or earliest available record
  const monthIndexMax = data?.diesel?.length - 1;
  const monthDisplay = toolTipData?.label || data?.diesel[monthIndexMax]?.x;
  const contractPrice = data?.gas91 && data?.gas95 && data?.gas95;
  let gas91Display,
    gas95Display,
    gas97Display,
    gasPrice = 0;
  let dieselPrice = Object.values(data?.diesel).filter((diesel) =>
    moment(diesel?.x).isSame(monthDisplay)
  )[0]?.y;

  if (contractPrice) {
    gas91Display = Object.values(data?.gas91).filter((gas91) =>
      moment(gas91?.x).isSame(monthDisplay)
    )[0]?.y;
    gas95Display = Object.values(data?.gas95).filter((gas95) =>
      moment(gas95?.x).isSame(monthDisplay)
    )[0]?.y;
    gas97Display = Object.values(data?.gas97).filter((gas97) =>
      moment(gas97?.x).isSame(monthDisplay)
    )[0]?.y;
  } else {
    gasPrice = Object.values(data?.gas).filter((gas) => moment(gas?.x).isSame(monthDisplay))[0]?.y;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.movementDate}>{moment(monthDisplay).format(DateTime.L)}</div>
        <div className={styles.priceMovement}>
          {contractPrice ? (
            <>
              <PriceMovement
                className={styles.price}
                priceMovement={{ type: Products.Diesel, price: dieselPrice }}
                loading={loading}
                gasProduct={true}
                noIndicator={true}
              />
              <PriceMovement
                className={styles.price}
                priceMovement={{ type: Products.Gas91, price: gas91Display }}
                loading={loading}
                gasProduct={true}
                noIndicator={true}
              />
              <PriceMovement
                className={styles.price}
                priceMovement={{ type: Products.Gas95, price: gas95Display }}
                loading={loading}
                gasProduct={true}
                noIndicator={true}
              />
              <PriceMovement
                className={styles.price}
                priceMovement={{ type: Products.Gas97, price: gas97Display }}
                loading={loading}
                gasProduct={true}
                noIndicator={true}
              />
            </>
          ) : (
            <>
              <PriceMovement
                className={styles.price}
                priceMovement={{ type: "diesel", price: dieselPrice }}
                loading={loading}
                gasProduct={true}
              />
              <PriceMovement
                className={styles.price}
                priceMovement={{ type: "gas", price: gasPrice }}
                loading={loading}
                gasProduct={true}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardChartHeader;
