import { ActionButton, Modal } from "components/commons";
import styles from "./leave-modal.module.scss";
import locale from "localization";
import { useModal } from "hooks";
import { Prompt, useHistory } from "react-router-dom";
import { useCallback } from "react";

const LeaveModal = ({ isListenerActive, message }) => {
  const { active, close, show, path } = useModal();

  const history = useHistory();

  const modalCallback = useCallback(
    (nextLocation) => {
      if (isListenerActive && !active) {
        show({ path: nextLocation.pathname });
        return false;
      }
      return true;
    },
    [isListenerActive, show, active]
  );

  return (
    <>
      <Prompt when={isListenerActive} message={modalCallback} />
      <Modal
        active={active}
        title={`${locale.areYouSureYouWantToLeave}?`}
        close={close}
        className={styles.modal}
      >
        <div className={styles.content}>{message}</div>
        <ActionButton
          right
          items={[
            {
              onClick: () => {
                history.push(path);
              },
              text: locale.leaveAnyway,
            },
            {
              onClick: () => {
                close();
              },
              text: locale.stay,
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default LeaveModal;
