export const myFuelCodesState = (fleetId) => ({
  fleetId,
  startDate: null,
  endDate: null,
  productCode: "all",
  page: 1,
  perPage: 10,
  status: "all",
  oneTimeUse: "all",
  fuelCodeType: "all",
  autoRenew: "all",
  sendSms: "all",
});
