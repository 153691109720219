import { getBpiToken } from "apis/bpi.api";
import AppLoader from "components/commons/app-loader/app-loader";
import { AnalyticsContext } from "contexts";
import { Path, Event } from "enums";
import { useApi, useMount } from "hooks";
import { useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";

const BPIResultPage = () => {
  const { track } = useContext(AnalyticsContext);

  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const cashinId = query.get("cashinId") || query.get("param1");
  const code = query.get("code");
  const paymentMethod = query.get("paymentMethod");

  const getTokenRequest = useApi({
    api: getBpiToken,
    params: {
      cashinId,
    },
  });

  const { result: token, loading = true } = getTokenRequest;

  useMount(() => {
    getTokenRequest.request({
      code,
    });
    track(Event.CashInResult, {
      cashinId,
      paymentMethod,
      code,
      url: window.location.pathname,
      bank: "BPI",
    });
  });

  if (!loading && token) {
    return (
      <CashInWithStatusComponent
        cashinId={cashinId}
        code={code}
        paymentMethod={paymentMethod}
        token={token}
      />
    );
  }
  return <AppLoader />;
};

const CashInWithStatusComponent = ({ status, cashinId, code, paymentMethod, token }) => {
  return (
    <Redirect
      to={{
        pathname: `${Path.CashIn}`,
        state: { cashInStatus: status, cashinId, code, paymentMethod, bank: "BPI", token },
      }}
    />
  );
};

export default BPIResultPage;
