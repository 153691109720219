import React from "react";
import { Text } from "..";
import Button from "../button/button";
import Title from "../title/title";
import styles from "./intro.module.scss";

const Intro = ({
  introStyle,
  titleStyle,
  textStyle,
  title,
  subtitle,
  actionText,
  actionClassName,
  actionOnClick,
  actionOutlined,
  actionStartIcon,
  actionDisabled,
  actionLoading,
  actionSecondaryText,
  actionSecondaryClassName,
  actionSecondaryOnClick,
  actionSecondaryStartIcon,
  actionSecondaryDisabled,
  black,
  style,
  containerStyle,
  actionContent,
  small,
}) => {
  return (
    <div className={introStyle || styles.intro} style={containerStyle}>
      <div style={style}>
        <Title className={titleStyle || styles.title} small={small}>
          {title}
        </Title>
        <Text className={textStyle}>{subtitle}</Text>
      </div>
      <div className={styles.buttonContainer}>
        {actionContent || (
          <>
            {actionSecondaryText && (
              <div className={styles.secondaryButtonContainer}>
                <Button
                  startIcon={actionSecondaryStartIcon}
                  onClick={actionSecondaryOnClick}
                  className={actionSecondaryClassName}
                  disabled={actionSecondaryDisabled}
                >
                  {actionSecondaryText}
                </Button>
              </div>
            )}
            {actionText && (
              <div className={styles.primaryButtonContainer}>
                <Button
                  startIcon={actionStartIcon}
                  primary={!actionOutlined}
                  outline={actionOutlined}
                  onClick={actionOnClick}
                  className={actionClassName}
                  disabled={actionDisabled}
                  loading={actionLoading}
                >
                  {actionText}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Intro;
