import {
  Autocomplete,
  DateRange,
  Field,
  SearchField,
  Button,
  Filter,
  GroupCheckbox,
} from "components/commons";
import React, { useCallback } from "react";
import { prettifyFleetCardStatus, prettifyFuelCodeType, prettifyProduct } from "utils/pretty.utils";
import styles from "./management-filter.module.scss";
import locale from "localization";
import FuelCodeType from "enums/fuel-code-type";
import FleetCardStatus from "enums/fleet-card-status";
import classNames from "classnames";
import { Products } from "enums";

const FleetCardManagementFilter = ({
  filterState,
  resetFilter,
  submitFilter,
  clearFilter,
  onDateRangeChange,
  onStatusChange,
  onCardTypeChange,
  onSearchChange,
  onSearch,
  filterCount,
  onDownloadFile,
  fetchingReport,
  getUserAccess,
  onProductChangeCb,
}) => {
  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onCardTypeChangeCb = useCallback(
    (name, { label, value }) => {
      if (onCardTypeChange) return onCardTypeChange({ label, value });
    },
    [onCardTypeChange]
  );

  const onStatusChangeCb = useCallback(
    (name, { label, value }) => {
      if (onStatusChange) return onStatusChange({ label, value });
    },
    [onStatusChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  return (
    <div className={styles.filter}>
      <Filter
        close={resetFilter}
        clear={clearFilter}
        submit={submitFilter}
        filterCount={filterCount}
        disabled={false}
      >
        <Field
          className={styles.date}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          label={locale.dateGenerated}
        >
          <DateRange
            value={{
              startDate: filterState.startDate,
              endDate: filterState.endDate,
            }}
            onChange={onDateRangeChangeCb}
          />
        </Field>
        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.redemptionStation}
          label={locale.status}
        >
          <Autocomplete
            name="fleetCardStatus"
            value={filterState.fuelCardStatuses}
            hasAll
            multiple
            options={[
              {
                label: prettifyFleetCardStatus(FleetCardStatus.Active),
                value: FleetCardStatus.Active,
              },
              {
                label: prettifyFleetCardStatus(FleetCardStatus.Deactivated),
                value: FleetCardStatus.Deactivated,
              },
              {
                label: prettifyFleetCardStatus(FleetCardStatus.Terminated),
                value: FleetCardStatus.Terminated,
              },
            ]}
            onChange={onStatusChangeCb}
          />
        </Field>
        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.redemptionStation}
          label={locale.cardType}
        >
          <Autocomplete
            name="fleetCardType"
            value={filterState.fuelCardTypes}
            hasAll
            multiple
            options={[
              {
                label: prettifyFuelCodeType(FuelCodeType.DriverSpecific),
                value: FuelCodeType.DriverSpecific,
              },
              {
                label: prettifyFuelCodeType(FuelCodeType.VehicleSpecific),
                value: FuelCodeType.VehicleSpecific,
              },
              {
                label: prettifyFuelCodeType(FuelCodeType.DriverVehicleSpecific),
                value: FuelCodeType.DriverVehicleSpecific,
              },
              {
                label: prettifyFuelCodeType(FuelCodeType.AnyDriverAnyVehicle),
                value: FuelCodeType.AnyDriverAnyVehicle,
              },
            ]}
            onChange={onCardTypeChangeCb}
          />
        </Field>
        <GroupCheckbox
          className={styles.productCode}
          title={locale.availableProducts}
          options={[
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.diesel)}></div>
                  {prettifyProduct(Products.Diesel)}
                </div>
              ),
              value: Products.Diesel,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas91)}></div>
                  {prettifyProduct(Products.Gas91)}
                </div>
              ),
              value: Products.Gas91,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas95)}></div>
                  {prettifyProduct(Products.Gas95)}
                </div>
              ),
              value: Products.Gas95,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas97)}></div>
                  {prettifyProduct(Products.Gas97)}
                </div>
              ),
              value: Products.Gas97,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.lubes)}></div>
                  {prettifyProduct(Products.Lubes)}
                </div>
              ),
              value: Products.Lubes,
            },
          ]}
          value={filterState.productCodes}
          onChange={(name, { value }) => {
            onProductChangeCb(value);
          }}
        />
      </Filter>
      <Field className={styles.search}>
        <SearchField
          placeholder={locale.cardDriverVehicle}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          restrict={false}
        />
      </Field>
      <Field className={styles.downloadButton}>
        {(getUserAccess?.fullAccess || getUserAccess?.exportAccess) && (
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={onDownloadFile}
            loading={fetchingReport}
            eventName={Event.DownloadFleetCardReport}
          >
            {locale.export}
          </Button>
        )}
      </Field>
    </div>
  );
};

export default FleetCardManagementFilter;
