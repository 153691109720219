import classNames from "classnames";
import React from "react";
import styles from "./title.module.scss";

const HeaderTag = ({ small, medium, large, children, ...props }) => {
  if (small) {
    return <h3 {...props}>{children}</h3>;
  }
  if (medium) {
    return <h2 {...props}> {children}</h2>;
  }
  return <h1 {...props}>{children}</h1>;
};

const Title = ({ className, bold, black, children, ...props }) => {
  return (
    <HeaderTag
      className={classNames(className, styles.title, {
        [`${styles.indigo}`]: !black,
        [`${styles.night}`]: black,
      })}
      {...props}
    >
      {children}
    </HeaderTag>
  );
};

export default Title;
