import React, { useMemo, useState } from "react";
import styles from "./forgot-password.module.scss";
import { Title, Highlight, Text } from "components/commons";
import locale from "localization";
import { initialState } from "./forgot-password.state";
import { useForm, useApi, useMount } from "hooks";
import { forgotPassword } from "apis";
import { handleRequest } from "utils";
import ForgotPasswordForm from "./forgot-password-form";
import ForgotPasswordSuccess from "./forgot-password-success";
import * as queryString from "query-string";
import { useHistory } from "react-router-dom";
import { ErrorCode } from "enums";

const ForgotPasswordModule = () => {
  const [successEmailSubmit, setSuccessEmailSubmit] = useState("");
  const history = useHistory();
  const parse = queryString.parse(history.location.search);
  const [errorCode] = useState(parse.code || null);
  const emailAddress =
    history.location.state?.email || parse.email || localStorage.getItem("email");

  const { request: forgotPasswordRequest, loading: submittingForgotPassword } = useApi({
    api: forgotPassword,
    handleOwnError: {
      badrequest: true,
    },
    modalError: true,
  });
  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, isFormSubmittable } =
    useForm({
      initialState,
    });
  const handleSubmit = () => {
    const { email } = getFormValues();
    if (!email) {
      applyFieldErrors({
        email: !email ? "" : null,
      });
      return;
    }
    handleRequest(
      async () => {
        const res = await forgotPasswordRequest({ email: email }, () => handleSubmit());
        if (res.message) {
          setSuccessEmailSubmit(email);
        }
      },
      null,
      (err) => {
        const obj = {
          [ErrorCode.UserNotFound]: locale.sorryWeDontRecognizeThisAccount,
          [ErrorCode.FleetNoLongerActive]: locale.thisFleetAccountIsNoLongerActive,
          [ErrorCode.UserInviteStillPending]: locale.thisEmailHasNotYetBeenActivated,
          [ErrorCode.UserNotActive]: locale.fleetNoLongerActive,
        };
        const emailError = obj[err.data.errorCode];
        if (emailError) {
          return applyFieldErrors({
            email: emailError,
          });
        }
      }
    );
  };

  useMount(() => {
    if (emailAddress) {
      modifyField(fields.email.name, { value: emailAddress, dirty: true });
    }
  });

  const state = {
    handleSubmit,
    submittingForgotPassword,
    isFormSubmittable,
    fields,
    modifyField,
    submitForm,
    successEmailSubmit,
  };

  const errorMessage = useMemo(() => {
    const obj = {
      [ErrorCode.InvalidPasswordToken]: locale.passwordTokenAlreadyUsed,
      [ErrorCode.InvalidForgotPasswordToken]: locale.passwordRecoveryLinkInvalid,
      [ErrorCode.ExpiredForgotPasswordToken]: locale.passwordRecoveryLinkInvalid,
    };

    return obj[errorCode];
  }, [errorCode]);

  return (
    <div
      className={styles.forgotContainer}
      style={{
        width: "35%",
      }}
    >
      <Title className={styles.title}>{locale.passwordReset}</Title>
      {errorMessage && (
        <Highlight warning>
          <Text>{errorMessage}</Text>
        </Highlight>
      )}
      {successEmailSubmit === "" ? (
        <ForgotPasswordForm {...state} errorCode={errorCode} />
      ) : (
        <ForgotPasswordSuccess {...state} />
      )}
    </div>
  );
};

export default ForgotPasswordModule;
