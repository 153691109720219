import React from "react";
import classNames from "classnames";

import styles from "./select-bank.module.scss";

const SelectBanks = ({ items, className }) => {
  return (
    <div className={classNames(className, styles.container)}>
      <div className={classNames(styles.panels)}>
        {items.map(
          (item, index) =>
            item.isEnabled && (
              <div
                key={index}
                className={classNames(styles.panelItem, {
                  [`${styles.disabled}`]: !item.enabled,
                  [`${styles.enabled}`]: item.enabled,
                })}
                onClick={() => {
                  item.onClick({ ...item });
                }}
              >
                <div className={styles.panel}>{item.content}</div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default SelectBanks;
