import { useCallback, useMemo, useState } from "react";

import useMount from "./useMount";
import { validateField } from "utils/form.utils";

const useForm = ({ form = {}, validation }) => {
  const [fields, setFields] = useState({});
  const [isDirty, setIsDirty] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [options, setOptions] = useState({});

  const modifyForm = useCallback(
    (formFields, makeAllFieldDirty) => {
      const obj = {
        ...fields,
      };
      let isAllFormDirty = false;
      Object.keys(formFields).forEach((key) => {
        obj[key] = {
          ...obj[key],
          ...formFields[key],
        };
      });
      Object.keys(obj).forEach((key) => {
        const fieldState = validateField(obj[key], obj);
        const isFieldDirty =
          makeAllFieldDirty || obj[key].value !== obj[key].initialValue || obj[key].isDirty;
        obj[key] = {
          ...obj[key],
          ...fieldState,
          isDirty: isFieldDirty,
        };

        if (isFieldDirty) {
          isAllFormDirty = isFieldDirty;
        }
      });
      setFields(obj);
      setIsDirty(isAllFormDirty);
    },
    [fields]
  );

  const modifyField = useCallback(
    (key, { value, ...values }) => {
      const field = {
        ...fields[key],
        ...values,
        isDirty:
          (value !== fields[key].initialValue && fields[key].initialValue) ||
          !fields[key].initialValue,
        data: {
          ...fields[key].data,
          ...values.data,
        },
        value,
      };

      modifyForm(
        {
          ...fields,
          [key]: { ...field },
        },
        false
      );
      // setIsDirty(true);
    },
    [fields, modifyForm]
  );

  const initializeForm = useCallback(
    (values = {}, setInitializedValue = true, options = {}) => {
      const obj = {};
      const isClean = Object.keys(values).length === 0;
      Object.keys(form).forEach((key) => {
        const { value, validations = {}, isFormArray, isFormGroup, data = {} } = form[key];
        const { value: val = value, data: valData = {}, disabled, ...objValue } = values[key] || {};
        obj[key] = {
          ...form[key],
          ...objValue,
          disabled,
          value: isClean ? form[key].value : val,
          initialValue: isClean ? form[key].value : val,
          validations,
          isFormArray,
          isFormGroup,
          error: false,
          message: [],
          isDirty: false,
          data: {
            ...data,
            ...valData,
          },
        };
      });
      setFields(obj);
      setInitialized(setInitializedValue);
      setIsDirty(false);
      if (options) setOptions(options);
    },
    [form]
  );

  useMount(() => {
    initializeForm({}, false);
  });

  const isFormSubmittable = useMemo(() => {
    let isError = false;

    Object.keys(fields).forEach((key) => {
      if (fields[key].error) {
        isError = true;
      }
    });
    if (validation && validation(fields).error) isError = true;
    return !isError && isDirty;
  }, [fields, validation, isDirty]);

  const formFieldsValue = useMemo(() => {
    const obj = {};

    Object.keys(fields).forEach((key) => {
      obj[key] = {
        ...fields[key],
        onChange: (name, values) => {
          modifyField(key, {
            name,
            ...values,
          });
        },
        name: key,
        showErrorIfDirty: true,
      };
    });
    return obj;
  }, [fields, modifyField]);

  const onSubmit = useCallback(
    (submitCallback) => {
      if (isFormSubmittable) {
        const object = {};
        Object.keys(fields).forEach((key) => {
          object[key] = fields[key].value;
        });
        submitCallback({ ...object });
      } else {
        modifyForm({}, true);
      }
    },
    [fields, isFormSubmittable, modifyForm]
  );

  return {
    options,
    initializeForm,
    fields: formFieldsValue,
    isDirty,
    isFormSubmittable,
    modifyForm: (formFieldValue) => {
      modifyForm(formFieldValue, false);
    },
    initialized,
    onSubmit,
  };
};

export default useForm;
