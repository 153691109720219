import Validation from "utils/validation.utils";
import locale from "localization";

export const initialState = (state = {}) => {
  const {
    plateNumber = {
      value: "",
      disabled: false,
    },
    model = {
      value: "",
      disabled: false,
    },
    remarks = {
      value: "",
      disabled: false,
    },
  } = state;
  return {
    plateNumber: {
      name: "plateNumber",
      placeholder: locale.plateNumberPlaceholder,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.alphaNumeric({ removeLast: true }),
        Validation.minlength(6),
      ],
      maxLength: 20,
      label: locale.plateNumber,
      upperCase: true,
      required: true,
      ...plateNumber,
    },
    model: {
      name: "model",
      validations: [Validation.required({ noMessage: true })],
      maxLength: 75,
      label: locale.vehicleModelType,
      placeholder: "Toyota Vios",
      required: true,
      ...model,
    },
    remarks: {
      name: "remarks",
      label: locale.remarksOptions,
      placeholder: locale.enterRemarksHere,
      multiline: true,
      maxLength: 255,
      rowsMax: 4,
      ...remarks,
    },
  };
};
