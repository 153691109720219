import { ApiPath } from "enums";
import { Get } from "utils";

export const getNonFuelTransaction = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.NonFuel}/fleet/${fleetId}`, query);
  return res.data;
};

export const getStationsNonFuel = async ({ ...query }) => {
  const res = await Get(`${ApiPath.NonFuel}/stations`, query);
  return res.data;
};
