const DateTime = {
  A: "D MMM YYYY",
  B: "h:mma",
  C: "YYYY-MM-DDT00:00:00ZZ",
  D: "YYYY-MM-DDT23:59:59ZZ",
  E: "MMM DD, YYYY",
  F: "MMMM YYYY",
  G: "MMM YYYY",
  H: "MMM",
  I: "MMM DD, YYYY hh:mm A",
  J: "YYYY-MM-DDT00:00:00+0800",
  K: "YYYY-MM-DDT23:59:59+0800",
  L: "MMMM DD, YYYY",
  M: "h:mm A",
  N: "YYYY",
  O: "MMM DD",
  P: "DD MMM YYYY",
  Q: "HH:mm:ss",
  R: "ddd MM/DD/YYYY h:mm A",
  S: "DD MMM YYYY h:mm A",
};

export default DateTime;
