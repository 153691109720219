import { DataTable, Intro, Pill, PopOverMenu, Text } from "components/commons";
import { FleetContext, AnalyticsContext, UserContext } from "contexts";
import { Page, Path, UserAccess, Role, Products } from "enums";
import FleetCardStatus from "enums/fleet-card-status";
import { useApi, useFilter, useMount, useModal } from "hooks";
import React, { useCallback, useContext, useMemo } from "react";
import locale from "localization";
import { getFleetCardList, generateReport } from "apis";
import { useHistory } from "react-router-dom";
import { formatAmount, formatDriverName } from "utils";
import { prettifyFleetCardStatus, prettifyFuelCodeType, prettifyProduct } from "utils/pretty.utils";
import styles from "./management.module.scss";
import FleetCardManagementFilter from "./management-filter";
import { fleetCardManagementColumns } from "./management-columns";
import { fleetCardManagementFilterState } from "./management-filter.state";
import FleetCardStatusModal from "./management-status-modal";
import { SuccessModal } from "components/modals";
import classNames from "classnames";

const FleetCardManagementModule = (props) => {
  const { page } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { userAccess } = useContext(UserContext);
  const { location } = useHistory();
  const statusModal = useModal();
  const history = useHistory();
  const successModal = useModal();

  const getUserAccess = userAccess?.find((item) => {
    return item.key === UserAccess.FleetCardManagement;
  });

  useMount(() => {
    fetchManagement(requestState);
    page({
      name: Page.ViewManageFleetCards,
    });
  });

  const {
    request: getManagementRequest,
    loading: fetchingManagement,
    result: getManagementResult = { data: [], count: 0 },
  } = useApi({
    api: getFleetCardList,
    pageError: true,
  });

  const { request: generateReportRequest, loading: fetchingReport } = useApi({
    api: generateReport,
    pageError: true,
  });

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    clearFilter,
    filterCount,
    submitFilter,
    submittedFilter,
  } = useFilter(fleetCardManagementFilterState(fleet.fleetId, location));

  const fetchManagement = useCallback(
    async (rs) => {
      const s = submitFilter(rs);
      await getManagementRequest({ ...s, productCodes: s.productCodes.join(",") });
    },
    [getManagementRequest, submitFilter]
  );

  const changeStatusCb = useCallback(
    (cardNumber, fuelCardId, action) => {
      statusModal.show({ action, cardNumber, fuelCardId });
    },
    [statusModal]
  );

  const setOptions = useCallback(
    (card) => {
      let redemption = {};
      let edit = {};
      let deactivate = {};
      let terminate = {};
      let activate = {};
      if (getUserAccess?.fullAccess || getUserAccess?.viewAccess) {
        redemption = {
          content: locale.viewRedemptions,
          onClick: () => {
            history.push(Path.Redemption, { searchKey: card.cardNumber });
          },
          disabled: false,
        };
      }

      if (getUserAccess?.fullAccess || getUserAccess?.modifyAccess) {
        edit = {
          content: locale.editDetails,
          onClick: () => {
            history.push(Path.EditFleetCardManagement, { card });
          },
          disabled: false,
        };
        deactivate = {
          content: locale.deactivate,
          onClick: () => {
            changeStatusCb(card.cardNumber, card.fuelCardId, FleetCardStatus.Deactivated);
          },
          disabled: false,
        };

        terminate = {
          content: locale.terminated,
          onClick: () => {
            changeStatusCb(card.cardNumber, card.fuelCardId, FleetCardStatus.Terminated);
          },
          disabled: false,
        };

        activate = {
          content: locale.activate,
          onClick: () => {
            changeStatusCb(card.cardNumber, card.fuelCardId, FleetCardStatus.Active);
          },
          disabled: false,
        };
      }

      if (card.status === FleetCardStatus.Active) {
        if (getUserAccess?.role !== Role.SeaoilAccountManager) {
          return [redemption, edit, deactivate, terminate];
        }
        return [edit, deactivate, terminate];
      } else if (card.status === FleetCardStatus.Deactivated) {
        if (getUserAccess?.role !== Role.SeaoilAccountManager) {
          return [redemption, edit, activate];
        }

        return [edit, activate];
      } else if (card.status === FleetCardStatus.Terminated) {
        if (getUserAccess?.role !== Role.SeaoilAccountManager) {
          return [redemption];
        }
        return [];
      } else {
        if (getUserAccess?.role !== Role.SeaoilAccountManager) {
          return [redemption, edit];
        }
        return [edit];
      }
    },
    [changeStatusCb, history, getUserAccess]
  );

  const preparedManagementData = useMemo(() => {
    const { fuelCards } = getManagementResult;

    let options = [];

    if (fuelCards && fuelCards.length > 0) {
      const preparedData = fuelCards.map((d) => {
        const map = new Map();
        options = setOptions(d);
        map.set("cardNumber", d.cardNumber);
        map.set("cardType", prettifyFuelCodeType(d.fuelCardType ?? ""));
        map.set(
          "assignedDriver",
          formatDriverName(d.driver?.firstName, d.driver?.lastName) ?? (
            <Text italic>{locale.any}</Text>
          )
        );
        map.set(
          "product",
          <>
            {d.diesel && (
              <Text>
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.diesel)}></div>
                  {prettifyProduct(Products.Diesel)}
                </div>
              </Text>
            )}
            {d.gas91 && (
              <Text>
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas91)}></div>
                  {prettifyProduct(Products.Gas91)}
                </div>
              </Text>
            )}
            {d.gas95 && (
              <Text>
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas95)}></div>
                  {prettifyProduct(Products.Gas95)}
                </div>
              </Text>
            )}
            {d.gas97 && (
              <Text>
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas97)}></div>
                  {prettifyProduct(Products.Gas97)}
                </div>
              </Text>
            )}
            {d.lubes && (
              <Text>
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.lubes)}></div>
                  {prettifyProduct(Products.Lubes)}
                </div>
              </Text>
            )}
          </>
        );
        map.set("assignedVehicle", d.vehicle?.plateNumber ?? <Text italic>{locale.any}</Text>);
        map.set("locqpayLimit", d.locqpayLimit ?? "No Limit");
        map.set("locqpayPayment", formatAmount(d.locqpayPaymentThisMonth ?? ""));
        map.set("transactionLimit", d.transactionLimit ?? "No Limit");
        map.set("transactionThisMonth", d.transactionThisMonth ?? "");
        map.set(
          "status",
          <Pill
            grass={d.status === FleetCardStatus.Active}
            cheddar={d.status === FleetCardStatus.Deactivated}
            deepRed={d.status === FleetCardStatus.Terminated}
          >
            {prettifyFleetCardStatus(d.status ?? "")}
          </Pill>
        );
        map.set("action", <PopOverMenu options={options} />);

        return map;
      });
      return preparedData;
    }
    return [];
  }, [getManagementResult, setOptions]);

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate, page: 1 });
    },
    [modifyFilters]
  );

  const onStatusChangeCb = useCallback(
    (value) => {
      modifyFilters({ fuelCardStatuses: value.value, page: 1 });
    },
    [modifyFilters]
  );

  const onCardTypeChangeCb = useCallback(
    (value) => {
      modifyFilters({ fuelCardTypes: value.value, page: 1 });
    },
    [modifyFilters]
  );

  const onProductChangeCb = useCallback(
    (productCodes) => {
      modifyFilters({ productCodes, page: 1 });
    },
    [modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1, perPage: 10 });
      fetchManagement(requestState);
    },
    [modifyFilters, fetchManagement]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchManagement(requestState);
    },
    [modifyFilters, fetchManagement]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchManagement(requestState);
    },
    [modifyFilters, fetchManagement]
  );
  const onPaymentStatusChangeCb = useCallback(
    (name, { value }) => {
      modifyFilters({ fuelCardStatuses: value, page: 1 });
      // fetchRedemption(requestState);
    },
    [modifyFilters]
  );

  const onDownloadFileCb = async () => {
    const { requestState } = modifyFilters({ reportType: "fuel-card-list" });
    delete requestState.perPage;
    delete requestState.page;
    const generateResult = await generateReportRequest(requestState);
    if (generateResult?.downloadUrl) {
      window.open(generateResult?.downloadUrl, "_blank");
    } else {
      successModal.show({
        title: locale.successTitle,
        content: locale.reportGenerated,
        // contentHighlight: [<strong>{user?.email}</strong>, <strong>business@pricelocq.com</strong>],
      });
    }
  };

  return (
    <div>
      <SuccessModal {...successModal} />
      <Intro title={locale.fleetCardManagement} subtitle={locale.manageFleetCards} />
      <div className={styles.filters}>
        <FleetCardManagementFilter
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onStatusChange={onStatusChangeCb}
          onCardTypeChange={onCardTypeChangeCb}
          onProductChangeCb={onProductChangeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          onDownloadFile={onDownloadFileCb}
          fetchingReport={fetchingReport}
          filterCount={filterCount}
          resetFilter={() => {
            modifyFilters({ submittedFilter });
          }}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchManagement(requestState);
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchManagement(requestState);
          }}
          getUserAccess={getUserAccess}
        />
      </div>

      <div className={styles.table}>
        <DataTable
          loading={fetchingManagement}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={fleetCardManagementColumns}
          data={preparedManagementData}
          dataCount={getManagementResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
          onPaymentStatusChangeCb={onPaymentStatusChangeCb}
        />
      </div>
      <FleetCardStatusModal
        refetchFuelCards={() => fetchManagement(requestState)}
        {...statusModal}
      />
    </div>
  );
};

export default FleetCardManagementModule;
