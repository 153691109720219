import { Products } from "enums";

export const stationFilterState = (fleetId) => ({
  fleetId,
  page: 1,
  perPage: 10,
  province: "all",
  city: "all",
  platformType: "plb",
  productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
});
