import {
  AUBIcon,
  BankOfCommerceIcon,
  Bdo,
  BDONetworkBankIcon,
  BPIIcon,
  ChinaBankIcon,
  EastwestIcon,
  LandBankIcon,
  MaybankIcon,
  MetroBankIcon,
  PNBIcon,
  PSBankIcon,
  RCBCIcon,
  RobisonsBankIcon,
  SecurityBankIcon,
  UCPBankIcon,
  Unionbank,
} from "images";
import locale from "localization";
import { BankCodes, CashInChannel, CashInMethod } from "enums";
import BankProcessor from "enums/bank-processor";
import env from "environments/env";
import { sortByKeyName } from "utils/object.utils";

export const bankItems = {
  [BankCodes.BdoPersonal]: {
    title: locale.bancoDeOro,
    fee: 0,
    image: Bdo,
    isEnabled: true,
    processor: BankProcessor.Brankas,
    method: CashInMethod.OnlineBanking,
    max: 50000,
    shortTitle: "BDO",
  },
  [BankCodes.BDO]: {
    title: locale.bancoDeOro,
    fee: 20,
    image: Bdo,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
    shortTitle: "BDO",
  },
  [BankCodes.BDONetwork]: {
    title: locale.bdoNetworkBank,
    fee: 15,
    image: BDONetworkBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
  },
  [BankCodes.Unionbank]: {
    title: locale.unionBank,
    fee: 0,
    image: Unionbank,
    isEnabled: true,
    processor: BankProcessor.Brankas,
    method: CashInMethod.OnlineBanking,
    max: 500000,
  },
  [BankCodes.UnionbankOTC]: {
    title: locale.unionBank,
    fee: 0,
    image: Unionbank,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
  },
  [BankCodes.AUB]: {
    title: locale.aubAsiaUnitedBank,
    fee: 0,
    image: AUBIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 500000,
    shortTitle: "AUB",
  },
  [BankCodes.BankOfCommerce]: {
    title: locale.bankOfCommerce,
    fee: 10,
    image: BankOfCommerceIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 500000,
  },
  [BankCodes.BPI]: {
    title: locale.bpiBankOfPhil,
    fee: 0,
    image: BPIIcon,
    isEnabled: true,
    processor: BankProcessor.BPI,
    method: CashInMethod.OnlineBanking,
    max: 250000,
    shortTitle: "BPI",
  },
  [BankCodes.BPIOTC]: {
    title: locale.bpiBankOfPhil,
    fee: 100,
    image: BPIIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
    shortTitle: "BPI",
  },
  [BankCodes.Chinabank]: {
    title: locale.chinabank,
    fee: 15,
    image: ChinaBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 500000,
  },
  [BankCodes.ChinabankOTC]: {
    title: locale.chinabank,
    fee: 50,
    image: ChinaBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 100000,
  },
  [BankCodes.Eastwest]: {
    title: locale.eastwestBank,
    fee: 0,
    image: EastwestIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 500000,
  },
  [BankCodes.EastwestOTC]: {
    title: locale.eastwestBank,
    fee: 0,
    image: EastwestIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
  },
  [BankCodes.Landbank]: {
    title: locale.landBank,
    fee: 10,
    image: LandBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 250000,
  },
  [BankCodes.LandbankOTC]: {
    title: locale.landBank,
    fee: 50,
    image: LandBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
  },
  [BankCodes.Maybank]: {
    title: locale.mayBank,
    fee: 0,
    image: MaybankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 0,
  },
  [BankCodes.MetroBank]: {
    title: locale.metroBank,
    fee: 0,
    image: MetroBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 500000,
  },
  [BankCodes.MetroBankOTC]: {
    title: locale.metroBank,
    fee: 50,
    image: MetroBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
  },
  [BankCodes.PNB]: {
    title: locale.pnbPhilNatBank,
    fee: 0,
    image: PNBIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 500000,
    shortTitle: "PNB",
  },
  [BankCodes.PNBOTC]: {
    title: locale.pnbPhilNatBank,
    fee: 25,
    image: PNBIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
    shortTitle: "PNB",
  },
  [BankCodes.PSBank]: {
    title: locale.psBank,
    fee: 25,
    image: PSBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 500000,
  },
  [BankCodes.RCBC]: {
    title: locale.rcbcRizalCommercialBank,
    fee: 5,
    image: RCBCIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 50000,
    shortTitle: "RCBC",
  },
  [BankCodes.RCBCOTC]: {
    title: locale.rcbcRizalCommercialBank,
    fee: 25,
    image: RCBCIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
    shortTitle: "RCBC",
  },
  [BankCodes.Robinsons]: {
    title: locale.robinsonsBank,
    fee: 0,
    image: RobisonsBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 500000,
  },
  [BankCodes.RobinsonsOTC]: {
    title: locale.robinsonsBank,
    fee: 0,
    image: RobisonsBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
  },
  [BankCodes.UCPB]: {
    title: locale.ucpb,
    fee: 0,
    image: UCPBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 500000,
    shortTitle: "UCPB",
  },
  [BankCodes.UCPBOTC]: {
    title: locale.ucpb,
    fee: 25,
    image: UCPBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
    shortTitle: "UCPB",
  },
  [BankCodes.SecurityBankOTC]: {
    title: locale.securityBank,
    fee: 50,
    image: SecurityBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
  },
  [BankCodes.SecurityBank]: {
    title: locale.securityBank,
    fee: 0,
    image: SecurityBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 500000,
  },
  [BankCodes.BOGX]: {
    title: "Bogus Bank OTC",
    fee: 0,
    // image: SecurityBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OverTheCounter,
    max: 500000,
  },
  [BankCodes.BOG]: {
    title: "Bogus Bank",
    fee: 0,
    // image: SecurityBankIcon,
    isEnabled: true,
    processor: BankProcessor.DragonPay,
    method: CashInMethod.OnlineBanking,
    max: 500000,
  },
};

export const banks = [
  BankCodes.Unionbank,
  BankCodes.AUB,
  BankCodes.BdoPersonal,
  BankCodes.BankOfCommerce,
  BankCodes.BPI,
  BankCodes.Chinabank,
  BankCodes.Landbank,
  BankCodes.MetroBank,
  BankCodes.PNB,
  BankCodes.RCBC,
  BankCodes.Robinsons,
  BankCodes.UCPB,
  BankCodes.BOG,
  BankCodes.SecurityBank,
]
  .filter((item) => {
    return (item === BankCodes.BOG && env.ID !== "prod") || item !== BankCodes.BOG;
  })
  .map((item) => {
    return {
      ...bankItems[item],
      code: item,
    };
  })
  .sort(sortByKeyName("title"));

export const otcs = [
  BankCodes.AUB,
  BankCodes.BDO,
  BankCodes.BDONetwork,
  BankCodes.BPIOTC,
  BankCodes.ChinabankOTC,
  BankCodes.Eastwest,
  BankCodes.LandbankOTC,
  BankCodes.MetroBankOTC,
  BankCodes.PNBOTC,
  BankCodes.RCBCOTC,
  BankCodes.RobinsonsOTC,
  BankCodes.SecurityBankOTC,
  BankCodes.UnionbankOTC,
  BankCodes.UCPBOTC,
  BankCodes.BOGX,
]
  .filter((item) => {
    return (item === BankCodes.BOGX && env.ID !== "prod") || item !== BankCodes.BOGX;
  })
  .map((item) => {
    const itemObj =
      item === BankCodes.AUB
        ? {
            title: locale.aubAsiaUnitedBank,
            fee: 0,
            image: AUBIcon,
            isEnabled: true,
            processor: BankProcessor.DragonPay,
            method: CashInMethod.OverTheCounter,
            max: 50000,
            shortTitle: "AUB",
          }
        : {};
    return {
      ...bankItems[item],
      ...itemObj,
      code: item,
      processor: BankProcessor.DragonPay,
      mode: CashInChannel.OTC,
    };
  })
  .sort(sortByKeyName("title"));
