import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import styles from "./tooltip-warning.module.scss";

const TooltipWarning = ({ title, placement }) => {
  return (
    <Tooltip
      title={title}
      classes={{
        tooltip: styles.customTooltip,
        arrow: styles.customArrow,
      }}
      placement={placement}
      arrow
    >
      <ErrorOutlineIcon color="primary" />
    </Tooltip>
  );
};

export default TooltipWarning;
