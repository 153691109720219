import React, { useMemo, useContext, useCallback } from "react";
import locale from "localization";
import { Button, Field, Image, Text } from "components/commons";
import SelectBanks from "components/commons/select-bank/select-banks";

import styles from "./select-bank-form.module.scss";
import { initialState as formState } from "./online-banking.state";
import { useForm, useApi, useMount, useModal } from "hooks";
import { formatNumber, parseNumber, formatAmount, prettifyCashInStatus } from "utils";
import { InputAmount } from "components/field";
import { createCashIn, dragonPayProcessors } from "apis/cash-in.api";
import { AnalyticsContext, FeatureFlagContext, FleetContext, UserContext } from "contexts";
import { redirectTo } from "utils";
import { handleRequest } from "utils";
import { BankCodes, CashInStatus, Event, Path } from "enums";
import env from "environments/env";
import { banks } from "./banks";
import { CircularProgress } from "@mui/material";
import BankProcessor from "enums/bank-processor";
import { SuccessModal } from "components/modals";
import { SuccessPurchaseImage } from "images";
import Validation from "utils/validation.utils";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const SelectBankForm = ({
  initialState,
  balance,
  disabled,
  title,
  subtitle,
  bankList: bankListItems = banks,
  paymentFollowUp = "2 days",
}) => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { track } = useContext(AnalyticsContext);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const minimumCashin = useMemo(() => {
    return !hasFeature("CASHIN_100") ? 10000 : 100;
  }, [hasFeature]);
  const form = useMemo(() => {
    return formState(initialState, { minimumCashin });
  }, [initialState, minimumCashin]);

  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet || {};
  const successModal = useModal();
  const {
    fields,
    modifyField,
    modifyForm,
    isFormSubmittable,
    submitForm,
    getFormValues,
    clearForm,
  } = useForm({
    initialState: form,
  });

  const {
    request: createOnlineBankingCashInRequest,
    loading: creatingOnlineBankingRequest,
    ...apiRequest
  } = useApi({
    api: createCashIn,
  });

  const { result: dragonPayData = [], ...dragonPayProcessorsRequest } = useApi({
    api: dragonPayProcessors,
  });

  const onChangeBankOption = useCallback(
    (item) => {
      if (item.code) {
        modifyForm({
          bankCode: {
            value: item.code,
          },
          processor: {
            value: item.processor,
          },
          method: {
            value: item.method,
          },
          amount: {
            fee: item.fee,
            validations: [
              Validation.required(),
              Validation.min(minimumCashin, {
                customMessage: locale.populate(locale.minimumCashInIsAmount, [
                  formatAmount(minimumCashin),
                ]),
                isFormatted: true,
              }),
              Validation.max(item.max, {
                customMessage: locale.populate(locale.maximumAmountSelectedBank, [
                  formatAmount(item.max, ""),
                ]),
                isFormatted: true,
              }),
            ],
          },
        });
      }
    },
    [modifyForm, minimumCashin]
  );

  const submit = async () => {
    const params = getFormValues();
    params.amount = parseNumber(params.amount);
    const isBrankas = params.processor === BankProcessor.Brankas;
    const isBPI = params.processor === BankProcessor.BPI;
    const bankEnum = isBrankas && env.ID !== "prod" ? BankCodes.Dummy : params.bankCode;
    const finalParams = {
      amount: params.amount,
      method: params.method,
      fleetId: fleetId,
    };
    const selectedBankData = bankList.find((bank) => {
      return params.bankCode === bank.code;
    });

    if (isBrankas) {
      finalParams.bankCode = bankEnum;
    } else {
      finalParams.paymentProcessor = params.processor;
      finalParams.mode = bankEnum;
    }

    handleRequest(
      async () => {
        track(Event.CashInOnlineBanking, finalParams);
        const paymentRes = await createOnlineBankingCashInRequest(finalParams);
        track(Event.SuccessCashIn, { res: paymentRes, param: finalParams });
        if (paymentRes.status === CashInStatus.Failed) {
          apiRequest.showError();
          track(Event.FailedCashIn, { err: paymentRes, param: finalParams });
        } else if (
          isBPI ||
          isBrankas ||
          paymentRes?.mustRedirect ||
          selectedBankData?.data?.mustRedirect
        ) {
          if (paymentRes.checkoutUrl) {
            redirectTo(paymentRes.checkoutUrl);
          } else {
            track(Event.FailedCashIn, { err: paymentRes, param: finalParams });
          }
        } else {
          successModal.show({
            referenceNumber: paymentRes.referenceNumber,
            status: paymentRes.status || CashInStatus.Pending,
            redirectURL: paymentRes.checkoutUrl,
          });
        }
      },
      null,
      (err = {}) => {
        track(Event.FailedCashIn, { err, param: finalParams });
        if (err.showError) {
          err.showError();
        }
      }
    );
  };

  useMount(() => {
    dragonPayProcessorsRequest.request();
  });

  const bankList = useMemo(() => {
    return bankListItems
      .filter((bank) => {
        if (bank.code === BankCodes.BPI) {
          return hasFeature("CASHIN_BPI");
        }
        return (
          (hasFeature("CASHIN_DRAGONPAY") && bank.processor === BankProcessor.DragonPay) ||
          bank.processor !== BankProcessor.DragonPay
        );
      })
      .map((bank) => {
        let image = "";
        let isEnabled = bank.processor !== BankProcessor.DragonPay;
        let dragonPayBank = {};
        if (!isEnabled) {
          dragonPayData.forEach((item) => {
            if (item.procId === bank.code) {
              image = item.logo;
              isEnabled = true;
              dragonPayBank = { ...item };
            }
          });
        }
        return {
          ...bank,
          enabled: isEnabled,
          image: bank.image || image,
          max: dragonPayBank?.maxAmount || bank.max || 50000,
          fromApiMaxAmount: dragonPayBank?.maxAmount,
          data: dragonPayBank,
          fee: dragonPayBank.customerFee || bank.fee,
        };
      });
  }, [dragonPayData, bankListItems, hasFeature]);

  useEffect(() => {
    clearForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankListItems]);

  return (
    <div className={styles.container}>
      <div className={styles.step}>
        <Text header bolder>
          {title || locale.stepOneSelectBank}
        </Text>
        {subtitle && <Text subtitle>{subtitle}</Text>}
      </div>
      <SuccessModal
        {...successModal}
        image={SuccessPurchaseImage}
        title={locale.paymentInstructionsSent}
        content={locale.pleaseFollowThePayment}
        contentHighlight={[<b>{user?.email}</b>, paymentFollowUp]}
        primary={{
          text: locale.newCashIn,
          onClick: () => {
            clearForm();
            successModal.close();
          },
        }}
        secondary={{
          text: locale.goToCashInHistory,
          onClick: () => {
            history.push(Path.CashInHistory);
          },
        }}
      >
        <div
          style={{
            margin: "20px 0px",
          }}
        >
          {[
            [locale.referenceNumber, successModal.referenceNumber],
            [locale.status, prettifyCashInStatus(successModal.status)],
            // [
            //   "Payment Instruction",
            //   <a href={successModal.redirectURL} target="_blank" rel="noreferrer">
            //     Click here
            //   </a>,
            // ],
          ]
            .filter((item) => {
              return item[1];
            })
            .map((item, key) => {
              return (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "160px",
                    }}
                  >
                    <Text subtitle>{item[0]}</Text>
                  </div>
                  <div>
                    <Text>{item[1]}</Text>
                  </div>
                </div>
              );
            })}
        </div>
      </SuccessModal>
      <div className={styles.banks}>
        <SelectBanks
          items={bankList.map((b) => ({
            onClick: (item) => {
              if (b.enabled) {
                onChangeBankOption(b);
              }
            },
            enabled: b.enabled,
            value: b.code,
            content: (
              <div className={styles.panel}>
                <Image src={b.image} />
                <div className={styles.title}>
                  <div className={styles.bankname}>{b.title}</div>
                  <div className={styles.bankfee}>
                    {
                      <locale.Populate
                        text={locale.bankTransactionFee}
                        items={[formatAmount(b.fee)]}
                      />
                    }
                  </div>
                </div>
                {fields.bankCode.value === b.code && (
                  <div className={styles.selectedbank}>
                    <span className="icon-checkmark" />
                  </div>
                )}
              </div>
            ),
            isEnabled: b.isEnabled,
          }))}
        />
        {dragonPayProcessorsRequest.loading && <CircularProgress />}
      </div>

      <div className={styles.step}>
        <Text header bolder>
          {locale.stepTwoEnterAmount}
        </Text>
        <div className={styles.amountSummary}>
          {[
            [locale.currentBalance, balance],
            [locale.minimumCashIn, minimumCashin],
            [locale.fee, fields.amount.fee || 0],
          ].map((item, index) => {
            return (
              <div key={index}>
                <Text strong>{item[0]}</Text>
                <Text strong label>
                  ₱{formatNumber(item[1], 2)}
                </Text>
              </div>
            );
          })}
        </div>
        <Field {...fields.amount}>
          <InputAmount disabled={disabled} innerLabel onChange={modifyField} {...fields.amount} />
        </Field>
      </div>
      <Button
        loading={creatingOnlineBankingRequest}
        className={styles.continue}
        primary
        disabled={!isFormSubmittable || disabled}
        onClick={() => {
          submitForm(submit);
        }}
      >
        {locale.continue}
      </Button>
    </div>
  );
};

export default SelectBankForm;
