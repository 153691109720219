import { InnerLabelTextfield, TextField } from "components/commons";
import React from "react";
import { checkIfValidByDecimalPlaces, formatNumber, parseNumber } from "utils";

const InputLitre = ({ className, onChange, onBlur, innerLabel, ...props }) => {
  const componentProps = {
    ...props,
    className,
    onChange: (name, { value }) => {
      if (checkIfValidByDecimalPlaces(value, 3) || !value) {
        onChange(name, { value });
      }
    },
    onBlur: (name, { value }) => {
      if (value) {
        const formattedValue = `${formatNumber(value)}${!innerLabel ? " L" : ""}`;
        onChange(name, { value: formattedValue });
        onBlur(name, { value });
      }
    },
    onFocus: () => {
      if (props.value) {
        const num = Number(parseNumber(props.value ? props.value.toString().replace(" L", "") : 0));
        onChange(props.name, { value: num || "" });
      }
    },
  };
  if (innerLabel) {
    return <InnerLabelTextfield {...componentProps} prefix="L" />;
  }
  return <TextField {...componentProps} />;
};

export default InputLitre;
