import classNames from "classnames";
import { Button, Image, Modal, Text, Title } from "components/commons";
import { PromoTicket } from "images";
import React from "react";
import styles from "./view-promo.module.scss";
import CloseIcon from "@material-ui/icons/Close";

const ViewPromoModal = ({ active, close, onClick, ...promo }) => {
  return (
    <Modal
      active={active}
      closable={false}
      modalContainerClassName={styles.container}
      headerClassName={styles.container}
      className={styles.container}
      contentClassName={styles.container}
    >
      <div className={styles.imageContainer}>
        <div className={styles.image}>
          <Image
            className={classNames(styles.img, {
              [`${styles.defaultImage}`]: !promo.featuredImage,
            })}
            src={promo.featuredImage || PromoTicket}
          />
        </div>
        <div className={styles.close} onClick={() => close()}>
          <CloseIcon className={styles.icon} />
        </div>
      </div>

      <div className={styles.content}>
        <Title medium className={styles.title}>
          {promo.title}
        </Title>
        <Text>{promo.subtitle}</Text>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: promo.longDescription }}
        ></div>
        <Button
          primary
          className={styles.button}
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              window.open(promo.redirectUrl, "_blank");
            }
          }}
        >
          {promo.buttonLabel}
        </Button>
      </div>
    </Modal>
  );
};

export default ViewPromoModal;
