import { ApiPath } from "enums";
import {  Post, Get } from "utils";

export const linkVipBiz = async ({ ...query }) => {
  const res = await Post(`${ApiPath.VipBiz}/loyalty/connect-biz`, query);
  return res.data;
};

export const getLoyaltyPoints = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.VipBiz}/loyalty/points-biz/fleet/${fleetId}`, query);
  return res.data;
};