import Validation from "utils/validation.utils";
import locale from "localization";
import { formatAmount } from "utils";

export const initialState = (state = {}, { minimumCashin = 10000 }) => {
  const {
    bankCode = {
      value: "",
      name: "bankCode",
      validations: [Validation.required()],
      required: true,
      method: "",
    },
    method = {
      value: "",
      name: "method",
      validations: [Validation.required()],
      required: true,
    },
    processor = {
      value: "",
      name: "processor",
      validations: [Validation.required()],
      required: true,
    },
    amount = {
      value: "",
      name: "amount",
      validations: [
        Validation.required(),
        Validation.min(minimumCashin, {
          customMessage: locale.populate(locale.minimumCashInIsAmount, [
            formatAmount(minimumCashin),
          ]),
          isFormatted: true,
        }),
        Validation.max(500000, {
          customMessage: locale.populate(locale.maximumAmountSelectedBank, ["500,000.00"]),
          isFormatted: true,
        }),
      ],
      required: true,
    },
  } = state;
  return {
    bankCode,
    amount,
    method,
    processor,
  };
};
