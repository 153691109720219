import React from "react";
import classNames from "classnames";
import styles from "./image.module.scss";

const Image = ({ style, className, src, alt, onClick }) => {
  return (
    <img
      style={style}
      className={classNames(className, styles.image)}
      onClick={onClick}
      src={src}
      alt={alt}
    />
  );
};

export default Image;
