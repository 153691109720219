import locale from "localization";

export const driverColumns = [
  { key: "avatar", text: "", width: "5%" },
  { key: "name", text: locale.name, width: "30%" },
  { key: "driverLicenseId", text: locale.driverId, width: "15%" },
  { key: "mobileNumber", text: locale.mobileNumber, width: "15%" },
  { key: "status", text: locale.status, width: "30%" },
  { key: "options", text: "", width: "5%" },
];
