import { DropdownPaginated } from "components/commons";
import React, { useCallback, useContext } from "react";
import { getStations } from "apis";
import { useApi } from "hooks";
import { FleetContext } from "contexts";

const SelectStation = ({ placeholder = "", ...props }) => {
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;

  const {
    request,
    loading,
    result = { stations: [], count: 0 },
  } = useApi({
    api: getStations,
    pageError: true,
  });

  const fetch = useCallback(
    async (params) => {
      return await request({
        perPage: 9,
        fleetId,
        isPlbOnboarded: true,
        ...params,
      });
    },
    [request, fleetId]
  );

  return (
    <DropdownPaginated
      {...props}
      request={async (params) => {
        return await fetch(params);
      }}
      total={result.count}
      loading={loading}
      formatRequest={(result) => {
        const { stations = [] } = result || {};
        if (stations && stations.length > 0) {
          const newStations = stations;

          return newStations.map((d) => {
            return {
              label: d.name,
              value: d.stationId,
            };
          });
        }
        return [];
      }}
      placeholder={placeholder}
      {...({ allOption: [{ label: "All", value: null }] })}
    />
  );
};

export default SelectStation;
