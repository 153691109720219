import { LitreVolumeState } from "states";
import { parseNumber } from "utils";
import Validation from "utils/validation.utils";
import locale from "localization";

export const initialState = (state = {}) => {
  const {
    productCode = {
      value: "",
      name: "productCode",
      validations: [Validation.required],
      required: true,
      creditPrice: 0,
    },
    literVolume = LitreVolumeState(
      [
        Validation.required,
        Validation.min(1, {
          customMessage: locale.minimumOrderIs1Liter,
        }),
      ],
      { required: true, placeholder: "0.000" }
    ),
    buyType = {
      value: "liters",
      name: "buyType",
    },
    amount = {
      value: "",
      name: "amount",
      placeholder: "0.00",
      disabled: true,
      validations: [
        Validation.required,
        (value, parent) => {
          const { productCode } = parent;
          const t = Number(parseNumber(value.value)) / productCode.creditPrice;
          if (t < 1 && value.value.toString()) {
            return { error: true, message: locale.minimumOrderIs1Liter };
          }
          return {};
        },
      ],
      required: true,
    },
  } = state;
  return {
    productCode,
    literVolume,
    buyType,
    amount,
  };
};
