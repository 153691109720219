import {
  Intro,
  BackLink,
  TextField,
  Field,
  Autocomplete,
  Checkbox,
  Loader,
  Text,
} from "components/commons";
import { SelectDriver, SelectVehicle } from "components/field";
import locale from "localization";
import { Path, FuelCodeType, Products } from "enums";
import styles from "./management-edit.module.scss";
import { useHistory } from "react-router-dom";
import { useForm, useModal, useApi } from "hooks";
import { useCallback, useMemo } from "react";
import { initialState } from "./management-edit-form-template.state";
import EditFleetCardModal from "./management-edit-confirm-modal";
import { SuccessModal } from "components/modals";
import { updateFuelCard } from "apis";
import { handleRequest } from "utils";
import { InputLimit } from "components/field";
import Validation from "utils/validation.utils";
import classNames from "classnames";
import { prettifyProduct } from "utils/pretty.utils";

const EditFleetCardManagementModule = (props) => {
  const history = useHistory();
  const { card } = props.location.state;
  const confirmModal = useModal();
  const successModal = useModal();

  const form = useMemo(() => {
    return initialState(card);
  }, [card]);

  const { fields, modifyField, modifyForm, getFormValues, isFormSubmittable } = useForm({
    initialState: form,
  });

  const onToggleLocqpay = useCallback(
    (name, value) => {
      modifyForm({
        [name]: {
          name: "noLimitLocqpay",
          value: value.value,
        },
        [fields.locqpayLimit.name]: {
          value: value.value ? "0" : fields.locqpayLimit.value,
          disabled: value.value,
        },
      });
    },
    [modifyForm, fields]
  );

  const onToggleTransaction = useCallback(
    (name, value) => {
      modifyForm({
        [name]: {
          name: "noLimitTransaction",
          value: value.value,
        },
        [fields.transactionLimit.name]: {
          value: value.value ? "0" : fields.transactionLimit.value,
          disabled: value.value,
        },
      });
    },
    [modifyForm, fields]
  );

  const { request: updateFuelCardRequest, loading: updatingFuelCard } = useApi({
    api: updateFuelCard,
  });

  const submit = (fuelCardId) => {
    const params = getFormValues();
    const numberLOCQPayLimit =
      typeof params.locqpayLimit === "number"
        ? params.locqpayLimit ?? 0
        : params.locqpayLimit === null
        ? 0
        : parseFloat(params.locqpayLimit.replaceAll(",", ""));

    const request = {
      fuelCardType: params.cardType,
      locqpayLimit: numberLOCQPayLimit,
      transactionLimit: parseInt(params.transactionLimit ?? 0),
      driverId: params.driverIds === "" ? null : params.driverIds,
      vehicleId: params.vehicleIds === "" ? null : params.vehicleIds,
      diesel: params.diesel,
      gas91: params.gas91,
      gas95: params.gas95,
      gas97: params.gas97,
      lubes: params.lubes,
      remarks: params.remarks,
    };

    handleRequest(async () => {
      confirmModal.close();
      await updateFuelCardRequest({ fuelCardId, ...request }, () => submit());
      successModal.show({
        title: locale.successTitle,
        content: locale.detailsSuccessfullyUpdated,
        primary: {
          text: locale.gotIt,
          onClick: () => {
            history.push(Path.FleetCardManagement);
          },
        },
      });
    });
  };

  const handleRequestData = () => {
    const params = getFormValues();

    const request = {
      fuelCardType: params.cardType,
      locqpayLimit: params.locqpayLimit,
      transactionLimit: params.transactionLimit,
      driverId: params.driverIds === "" ? null : params.driverIds,
      vehicleId: params.vehicleIds === "" ? null : params.vehicleIds,
      remarks: params.remarks,
    };

    // if (card.fuelCardType === params.cardType) {
    //   delete request.fuelCardType;
    // }

    // if (parseInt(card.locqpayLimit) === params.locqpayLimit) {
    //   delete request.locqpayLimit;
    // }

    // if (parseInt(card.transactionLimit) === params.transactionLimit) {
    //   delete request.transactionLimit;
    // }

    // if (card.driver?.driverId === params.driverIds) {
    //   delete request.driverIds;
    // }

    // if (card.vehicle?.vehicleId === params.vehicleIds) {
    //   delete request.vehicleIds;
    // }

    return request;
  };

  const changeFuelCodeType = useCallback(
    (name, value) => {
      let withData = {};
      if (value !== null) {
        const newState = {
          [FuelCodeType.DriverSpecific]: {
            [fields.driverIds.name]: {
              required: true,
              disabled: false,
            },
            [fields.vehicleIds.name]: {
              required: false,
              disabled: false,
            },
          },
          [FuelCodeType.DriverVehicleSpecific]: {
            [fields.driverIds.name]: {
              required: true,
              disabled: false,
            },
            [fields.vehicleIds.name]: {
              required: true,
              disabled: false,
            },
          },
          [FuelCodeType.VehicleSpecific]: {
            [fields.driverIds.name]: {
              required: false,
              disabled: false,
            },
            [fields.vehicleIds.name]: {
              required: true,
              disabled: false,
            },
          },
          [FuelCodeType.AnyDriverAnyVehicle]: {
            [fields.driverIds.name]: {
              required: false,
              disabled: true,
              label: "Any",
              value: "",
            },
            [fields.vehicleIds.name]: {
              required: false,
              disabled: true,
              label: "Any",
              value: "",
            },
          },
        }[value];
        const driverIds = newState[fields.driverIds.name];
        const vehicleIds = newState[fields.vehicleIds.name];

        withData = {
          [fields.driverIds.name]: {
            value: "",
            label: "",
            required: true,
            ...driverIds,
            validations: driverIds.required ? [Validation.required] : [],
          },
          [fields.vehicleIds.name]: {
            value: "",
            label: "",
            ...vehicleIds,
            validations: vehicleIds.required ? [Validation.required] : [],
          },
        };
      }

      modifyForm({
        [name]: { value },
        ...withData,
      });
    },
    [modifyForm, fields.driverIds.name, fields.vehicleIds.name]
  );

  return (
    <div>
      <EditFleetCardModal {...confirmModal} />
      <SuccessModal {...successModal} />
      <div>
        <Loader open={updatingFuelCard} />
        <BackLink text={locale.fleetCardManagement} path={Path.FleetCardManagement} />
        <Intro
          actionText={locale.save}
          actionOnClick={() => {
            confirmModal.show({
              title: locale.saveChangesQuestion,
              content: handleRequestData(),
              secondary: {
                text: locale.cancel,
              },
              primary: {
                text: locale.saveDetails,
                onClick: () => {
                  submit(card.fuelCardId);
                },
              },
            });
          }}
          actionClassName={styles.primarySaveButton}
          actionSecondaryText={locale.cancel}
          actionSecondaryOnClick={() => {
            history.goBack();
          }}
          actionSecondaryClassName={styles.secondarySaveButton}
          title={locale.editDetails}
          actionDisabled={
            !isFormSubmittable ||
            (!fields.diesel.value &&
              !fields.gas91.value &&
              !fields.gas95.value &&
              !fields.gas97.value &&
              !fields.lubes.value)
          }
        />

        <Field
          labelPosition={"top"}
          label={locale.products}
          childrenClassName={styles.fieldContent}
        >
          <div className={styles.contentProducts}>
            {[Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97, Products.Lubes].map(
              (option, key) => {
                return (
                  <div
                    key={key}
                    className={classNames(
                      "flex items-center",
                      {
                        [`${styles.checked}`]: fields[option].value,
                      },
                      styles.product
                    )}
                  >
                    <Checkbox
                      className={styles.checkbox}
                      value={fields[option].value}
                      onChange={() => {
                        modifyField(fields[option].name, { value: !fields[option].value });
                      }}
                    />
                    <Text>
                      <div className="flex items-center">
                        <div className={classNames(styles.circle, styles[option])}></div>
                        {prettifyProduct(option)}
                      </div>
                    </Text>
                  </div>
                );
              }
            )}
          </div>
        </Field>

        <Field
          labelPosition={"top"}
          label={locale.cardNumber}
          childrenClassName={styles.fieldContent}
        >
          <TextField {...fields.cardNumber} disabled={true} upperCase />
        </Field>
        <Field
          labelPosition={"top"}
          childrenClassName={styles.fieldContent}
          label={locale.cardType}
        >
          {/* <Dropdown
            {...fields.cardType}
            onChange={(value) => {
              changeFuelCodeType(fields.cardType.name, value);
            }}
          /> */}
          <Autocomplete
            {...fields.cardType}
            value={fields.cardType.value}
            onChange={(name, { value }) => {
              changeFuelCodeType(fields.cardType.name, value);
            }}
          />
        </Field>
        {(fields.cardType.value === FuelCodeType.DriverSpecific ||
          fields.cardType.value === FuelCodeType.DriverVehicleSpecific) && (
          <Field
            labelPosition={"top"}
            label={locale.assignedDriver}
            childrenClassName={styles.fieldContent}
          >
            <SelectDriver {...fields.driverIds} onChange={modifyField} />
          </Field>
        )}
        {(fields.cardType.value === FuelCodeType.VehicleSpecific ||
          fields.cardType.value === FuelCodeType.DriverVehicleSpecific) && (
          <Field
            labelPosition={"top"}
            childrenClassName={styles.fieldContent}
            label={locale.assignedVehicle}
          >
            <SelectVehicle {...fields.vehicleIds} onChange={modifyField} />
          </Field>
        )}

        <Field
          labelPosition={"top"}
          label={locale.locqpayLimit}
          childrenClassName={styles.fieldContent}
          {...fields.locqpayLimit}
        >
          <InputLimit onChange={modifyField} {...fields.locqpayLimit} />
        </Field>
        <div className={styles.privacy}>
          <Checkbox {...fields.noLimitLocqpay} onChange={onToggleLocqpay} />
          <span>{locale.noLimit}</span>
        </div>
        <Field
          labelPosition={"top"}
          label={locale.locqpayPayment}
          childrenClassName={styles.fieldContent}
        >
          <TextField {...fields.locqpayPaymentThisMonth} />
        </Field>
        <Field
          labelPosition={"top"}
          label={locale.transactionLimit}
          childrenClassName={styles.fieldContent}
          {...fields.transactionLimit}
        >
          <TextField {...fields.transactionLimit} onChange={modifyField} />
        </Field>
        <div className={styles.privacy}>
          <Checkbox {...fields.noLimitTransaction} onChange={onToggleTransaction} />
          <span>{locale.noLimit} </span>
        </div>
        <Field
          labelPosition={"top"}
          label={locale.transactionThisMonth}
          childrenClassName={styles.fieldContent}
        >
          <TextField {...fields.transactionThisMonth} />
        </Field>
        <Field labelPosition={"top"} label={locale.remarks} childrenClassName={styles.fieldContent}>
          <TextField {...fields.remarks} onChange={modifyField} />
        </Field>
      </div>
    </div>
  );
};

export default EditFleetCardManagementModule;
