import React, { useMemo, useContext } from "react";
import locale from "localization";
import { Button, Field, Text } from "components/commons";

import styles from "./vip-biz-points-form.module.scss";
import { initialState as formState } from "./vip-biz-points.state";
import { useForm, useApi, useModal } from "hooks";
import { formatNumber, parseNumber, formatAmount } from "utils";
import { InputAmount } from "components/field";
import { createCashIn } from "apis/cash-in.api";
import { AnalyticsContext, FleetContext, UserContext } from "contexts";
import { Path, CashInStatus, Event, CashInMethod } from "enums";
import { NotifModal } from "components/modals";
import { SuccessPurchaseImage } from "images";
import { useHistory } from "react-router-dom";

const VipBizPointsForm = ({
  initialState,
  balance,
  disabled,
  title,
  subtitle,
  refetch,
  paymentFollowUp = "2 days",
  setPaymentMethod,
}) => {
  const { track } = useContext(AnalyticsContext);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const maximumPoints = useMemo(() => {
    return balance;
  }, [balance]);
  const form = useMemo(() => {
    return formState(initialState, { maximumPoints });
  }, [initialState, maximumPoints]);

  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet || {};
  const successModal = useModal();
  const {
    fields,
    modifyField,
    isFormSubmittable,
    submitForm,
    getFormValues,
    clearForm,
    applyFieldErrors,
  } = useForm({
    initialState: form,
  });

  const { request: createVIPPointsCashInRequest, loading: creatingVIPPointsRequest } = useApi({
    api: createCashIn,
    modalError: false,
  });

  const submit = async () => {
    const params = getFormValues();
    params.points = parseNumber(params.points);
    const finalParams = {
      amount: params.points,
      method: "vip-locqpay",
      paymentProcessor: "vip",
      fleetId: fleetId,
    };
    try {
      track(Event.CashInOnlineBanking, finalParams);
      const paymentRes = await createVIPPointsCashInRequest(finalParams);
      if (paymentRes) {
        successModal.show({
          referenceId: paymentRes?.cashinId,
          points: paymentRes?.amount,
          amount: paymentRes?.amount,
          status: CashInStatus.Success,
          redirectURL: paymentRes.checkoutUrl,
        });
        refetch();
      }
    } catch (err) {
      applyFieldErrors({
        points: err?.data?.message,
      });
    }
  };

  return (
    <div className={styles.container}>
      <NotifModal
        {...successModal}
        close={() => {
          refetch();
          successModal.close();
        }}
        image={SuccessPurchaseImage}
        title={locale.vipPointRedemptionTitle}
        content={locale.vipPointsDescription}
        contentHighlight={[<b>{user?.email}</b>, paymentFollowUp]}
        primary={{
          text: locale.newCashIn,
          onClick: () => {
            refetch();
            clearForm();
            setPaymentMethod(CashInMethod.Card);
            successModal.close();
          },
        }}
        secondary={{
          text: locale.buyFuel,
          onClick: () => {
            history.push(Path.BuyFuel);
          },
        }}
        actions={[
          {
            text: locale.goToCashInHistory,
            onClick: () => {
              history.push(Path.CashInHistory);
            },
          },
        ]}
      >
        <div
          style={{
            margin: "20px 0px",
          }}
        >
          {[
            [locale.cashInId, successModal.referenceId],
            [locale.paymentMethod, locale.vipPointsRedemption],
            [locale.pointsRedeemed, successModal.points],
            [
              locale.amount,
              successModal?.amount
                ? formatAmount(successModal?.amount)
                : `₱ ${fields?.points.value}`,
            ],
          ]
            .filter((item) => {
              return item[1];
            })
            .map((item, key) => {
              return (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "160px",
                    }}
                  >
                    <Text subtitle>{item[0]}</Text>
                  </div>
                  <div>
                    <Text>{item[1]}</Text>
                  </div>
                </div>
              );
            })}
        </div>
      </NotifModal>

      <div className={styles.step}>
        <Text header bolder>
          {title || locale.enterVipBizPoints}
        </Text>
        <div className={styles.amountSummary}>
          {[[locale.availableVipBizPoints, balance]].map((item, index) => {
            return (
              <div key={index}>
                <Text strong>{item[0]}</Text>
                <Text strong label>
                  {formatNumber(item[1], 2)}
                </Text>
              </div>
            );
          })}
        </div>
        <Field {...fields.points}>
          <InputAmount
            disabled={disabled}
            innerLabel={false}
            onChange={modifyField}
            {...fields.points}
          />
        </Field>
        <div className={styles.equivalentAmount}>
          <Text bolder>{locale.equivalentLOCQPayAmount}</Text>
          <Text label>₱ {fields?.points?.value ? fields?.points?.value : "0.00"}</Text>
        </div>
      </div>
      <Button
        loading={creatingVIPPointsRequest}
        className={styles.continue}
        primary
        disabled={!isFormSubmittable || disabled}
        onClick={() => {
          submitForm(submit);
        }}
      >
        {locale.continue}
      </Button>
    </div>
  );
};

export default VipBizPointsForm;
