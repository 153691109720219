import locale from "localization";

export const initialState = {
  newPassword: {
    name: "newPassword",
    placeholder: "",
    value: "",
    maxLength: 75,
    label: locale.pleaseEnterNewPassword,
    required: true,
  },
  confirmNewPassword: {
    name: "confirmNewPassword",
    placeholder: "",
    value: "",
    maxLength: 75,
    label: locale.confirmNewPassword,
    required: true,
    validations: [
      (field, form) => {
        const password = form.newPassword;

        return {
          error: password.value !== field.value,
          message: "",
        };
      },
    ],
  },
};
