import { Field, Image, Modal, TextField, ActionButton } from "components/commons";
import { useApi, useForm, useModal } from "hooks";
import React, { useMemo } from "react";
import { initialState as formState } from "./driver-form.state";
import styles from "./driver.module.scss";
import { createDriver, updateDriver } from "apis/driver.api";
import { useContext } from "react";
import { AnalyticsContext, FleetContext } from "contexts";
import { NotifModal } from "components/modals";
import { Grid } from "@material-ui/core";
import locale from "localization";
import { useEffect } from "react";
import { DefaultProfile, CarWithLady } from "images";
import { handleRequest } from "utils";
import { Event } from "enums";

const DriverForm = ({ initialState, actionText, create, driverId, refreshPage, ...state }) => {
  const { track } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;
  const { close } = state;
  const notifModal = useModal();
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    applyFieldErrors,
    validateField,
    clearForm,
    isFormSubmittable,
  } = useForm({
    initialState: form,
  });

  useEffect(() => {
    if (state.active) {
      clearForm();
    }
    //eslint-disable-next-line
  }, [state.active]);

  const addDriverRequest = useApi({
    api: createDriver,
    handleOwnError: {
      badrequest: true,
    },
  });

  const editDriverRequest = useApi({
    api: updateDriver,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      driverId,
    },
  });

  const loading = addDriverRequest.loading || editDriverRequest.loading;

  const submit = async () => {
    const params = getFormValues();

    const apiRequest = create ? addDriverRequest : editDriverRequest;
    const eventType = create ? Event.AddDriver : Event.EditDriver;

    handleRequest(
      async () => {
        const finalParams = {
          ...params,
          fleetId,
        };
        await apiRequest.request(finalParams, () => submit());
        track(eventType, finalParams);
        close();
        refreshPage();
        notifModal.show({
          image: CarWithLady,
          title: create ? locale.driverSuccessRegistered : locale.driverSuccessEdited,
          contentHighlight: [<b>{params.firstName}</b>, <b>{params.lastName}</b>],
          content: create
            ? locale.successfullyRegisteredDriverName
            : locale.successfullyChangeInfoOfDriverName,
          secondary: create
            ? {
                text: locale.registerNewDriver,
                onClick: () => {
                  state.show({
                    title: locale.addDriver,
                    actionText: locale.add,
                    create: true,
                  });
                },
              }
            : null,
        });
      },
      {
        D1000: () => {
          applyFieldErrors({
            driverLicenseId: locale.driverLicenseIdExists,
          });
        },
      }
    );
  };

  return (
    <div>
      <NotifModal {...notifModal} />
      <Modal close={loading ? false : close} {...state} className={styles.modalContent}>
        <div className={styles.formContent}>
          <Image src={DefaultProfile} className={styles.defaultAvatar} />
          <div className={styles.content}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field {...fields.firstName}>
                  <TextField {...fields.firstName} onChange={modifyField} onBlur={validateField} />
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field {...fields.lastName}>
                  <TextField {...fields.lastName} onChange={modifyField} onBlur={validateField} />
                </Field>
              </Grid>
            </Grid>
            <Field {...fields.driverLicenseId}>
              <TextField
                {...fields.driverLicenseId}
                onChange={modifyField}
                onBlur={validateField}
                upperCase
              />
            </Field>
            <Field {...fields.mobileNumber}>
              <TextField {...fields.mobileNumber} onChange={modifyField} onBlur={validateField} />
            </Field>
          </div>
        </div>
        <ActionButton
          right
          items={[
            {
              onClick: () => {
                state.close();
              },
              disabled: loading,
              text: locale.cancel,
            },
            {
              onClick: () => {
                submitForm(submit);
              },
              disabled: !isFormSubmittable,
              loading,
              text: actionText,
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default DriverForm;
