import React from "react";
import Count from "./count";
import styles from "./counts.module.scss";

const Counts = ({ items, ...props }) => {
  return (
    <div className={styles.container}>
      <div>
        {items.map((item, index) => {
          return (
            <Count key={index} {...props} {...item}>
              {item.content}
            </Count>
          );
        })}
      </div>
    </div>
  );
};

export default Counts;
