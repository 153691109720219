import * as queryString from "query-string";
import React, { useMemo, useState } from "react";
import { useForm, useApi, useMount } from "hooks";
import { initialState } from "./activate-account.state";
import styles from "./activate-account.module.scss";
import { ErrorCode, Path, Event } from "enums";
import {
  Field,
  TextField,
  PasswordField,
  Button,
  Image,
  Text,
  Title,
  Footer,
  Loader,
} from "components/commons";
import classNames from "classnames";
import { PriceLOCQLogo } from "images";
import locale from "localization";
import { handleRequest, redirectTo } from "utils";
import { getFleetInvite, acceptInvite } from "apis/fleet.api";
import { useHistory } from "react-router-dom";
import ResultActivateAccount from "./result-activate-account";
import { isPasswordValid } from "utils/text.utils";

const ActivateAccountModule = (props) => {
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const parse = queryString.parse(props.location.search);

  const { request: acceptInviteRequest, loading: acceptingInvite } = useApi({
    api: acceptInvite,
    handleOwnError: {
      badrequest: true,
    },
  });

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    applyFieldErrors,
    isFormSubmittable,
    clearForm,
  } = useForm({
    initialState,
  });

  const { request: getRequestFleetInvite, loading: fetchingFleetInvite } = useApi({
    api: getFleetInvite,
    params: {
      token: parse.token,
    },
    pageError: true,
    modalError: false,
    handleOwnError: {
      badrequest: true,
    },
  });

  useMount(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("fleetId");
    handleRequest(
      async () => {
        const inviteResult = await getRequestFleetInvite();
        if (inviteResult && inviteResult.email) {
          clearForm();
          return modifyField("email", {
            value: inviteResult.email,
          });
        }
      },
      {
        [ErrorCode.InvalidInviteFleetToken]: () => {
          redirectTo(`${Path.Auth}?code=${ErrorCode.InvalidInviteFleetToken}`);
        },
        [ErrorCode.ExpiredInviteFleetToken]: () => {
          redirectTo(`${Path.Auth}?code=${ErrorCode.ExpiredInviteFleetToken}`);
          // history.push(`${Path.Auth}?code=${ErrorCode.ExpiredInviteFleetToken}`);
        },
      }
      // (err) => {
      //   if (err.data?.errorCode) {
      //     // history.push(`${Path.Auth}?code=${err.data?.errorCode}`);
      //   }
      //   return err.showError(false);
      // }
    );
  });

  const onBlurPasswordField = () => {
    const { password, confirmPassword } = getFormValues();
    let validations = {};
    if (password) {
      if (!isPasswordValid(password)) {
        Object.assign(validations, {
          password: locale.use8CharWithMix,
        });
      }
      if (password !== confirmPassword && password && confirmPassword) {
        Object.assign(validations, {
          confirmPassword: locale.passwordsDoNotMatch,
        });
      }
      return applyFieldErrors(validations);
    }
  };

  useMemo(() => {
    const { password, confirmPassword } = getFormValues();
    if (password === "") {
      return applyFieldErrors(
        {
          password: locale.use8CharWithMix,
        },
        false
      );
    }
    setPasswordValid(password === confirmPassword && isPasswordValid(password));
    // eslint-disable-next-line
  }, [fields.password.value, fields.confirmPassword.value]);

  const handleActivateAccount = async () => {
    const { firstName, lastName, password } = getFormValues();
    handleRequest(
      async () => {
        await acceptInviteRequest({ firstName, lastName, password, token: parse.token }, () =>
          handleActivateAccount()
        );
        setActive(true);
      },
      null,
      (err) => {
        if (err.data?.errorCode === ErrorCode.InvalidInviteFleetToken) {
          history.push(`${Path.Auth}?code=${ErrorCode.InvalidInviteFleetToken}`);
        }
        return err.showError();
      }
    );
  };

  return (
    <div className={styles.container}>
      <Loader open={fetchingFleetInvite} />

      <div className={classNames(styles.header)}>
        <Image src={PriceLOCQLogo} className={styles.logoImage} />
      </div>

      <div className={styles.content}>
        <div className={styles.contentContainer}>
          <div className={styles.activateAccount}>
            <Title className={styles.title}>{locale.activateAccount}</Title>
            <div className={styles.panel}>
              <Title className={styles.subtitle}>{locale.basicInformation}</Title>
              <Field {...fields.firstName} horizontal>
                <TextField {...fields.firstName} onChange={modifyField} />
              </Field>
              <Field {...fields.lastName} horizontal>
                <TextField {...fields.lastName} onChange={modifyField} />
              </Field>
              <Field {...fields.email} horizontal>
                <TextField {...fields.email} onChange={modifyField} />
              </Field>
            </div>
            <div className={styles.panel}>
              <Title className={styles.subtitle}>{locale.setPassword}</Title>
              <Field {...fields.password} horizontal>
                <PasswordField
                  {...fields.password}
                  onChange={modifyField}
                  onBlur={onBlurPasswordField}
                />
                {fields.password.value && !fields.password.error && (
                  <Text label className={styles.labelMessage}>
                    {locale.use8CharWithMix}
                  </Text>
                )}
              </Field>
              <Field {...fields.confirmPassword} horizontal>
                <PasswordField
                  {...fields.confirmPassword}
                  onChange={modifyField}
                  onBlur={onBlurPasswordField}
                />
              </Field>
            </div>

            <Button
              primary
              className={styles.activateButton}
              loading={acceptingInvite}
              disabled={!isFormSubmittable || !passwordValid}
              onClick={() => {
                submitForm(handleActivateAccount);
              }}
              eventName={Event.ActivateAccount}
            >
              {locale.activateAccount}
            </Button>
          </div>
        </div>
      </div>
      <Footer />
      <ResultActivateAccount active={active} />
    </div>
  );
};

export default ActivateAccountModule;
