import React from "react";
import styles from "./select-cards.module.scss";
import { prettifyCardType } from "utils/pretty.utils";
import { Image } from "..";
import { CardType } from "enums";
import { Jcb, Mastercard, Visa } from "images";
import { Add, Check, DeleteOutline } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { locale } from "localization/en";
import classNames from "classnames";

const SelectCards = ({
  fleetCards,
  className,
  onSelectCard,
  selectedCard,
  onDeleteCard,
  onAddCard,
  disabled = false
}) => {
  return (
    <div className={className}>
      <div className={styles.panel}>
        {fleetCards.length > 0 ? (
          fleetCards.map((item, index) => {
            let icon;
            switch (item.cardType) {
              case CardType.Mastercard:
                icon = Mastercard;
                break;
              case CardType.Visa:
                icon = Visa;
                break;
              case CardType.Jcb:
                icon = Jcb;
                break;
              default:
                icon = Mastercard;
                break;
            }

            return (
              <div key={index} className={styles.panelItem}>
                <div
                  className={styles.cardInfo}
                  onClick={() => {
                    onSelectCard(item);
                  }}
                >
                  <div className={styles.cardDetails}>
                    <Image src={icon} className={styles.icon} />
                    <label>
                      {prettifyCardType(item.cardType)} {item.cardNumber}
                    </label>
                  </div>
                  {selectedCard && selectedCard.fleetCardId === item.fleetCardId && <Check />}
                </div>
                <IconButton
                  {...(!disabled ? { onClick: () => {onDeleteCard(item)}} : {})}
                >
                  <DeleteOutline className={disabled? styles.deleteDisabled : styles.delete} />
                </IconButton>
              </div>
            );
          })
        ) : (
          <div key={0} className={styles.panelItem}>
            <div className={styles.cardInfo}>
              <div className={classNames(styles.cardDetails, styles.noCards)}>
                {locale.noCardsAreCurrentlySaved}
              </div>
            </div>
          </div>
        )}
        <div key={fleetCards.length + 1} className={styles.panelItem}>
          <div
            className={styles.cardInfo}
            {...(!disabled ? { onClick: () => {onAddCard()}} : {})}
          >
            <div className={classNames(styles.cardDetails, disabled ? styles.addCardDisabled : styles.addCard)}>
              <Add className={styles.addIcon} />
              {locale.addNewCard}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCards;
