import { locale } from "localization/en";
import styles from "./non-fuel.module.scss";
import { Text, Pill, Product, PopOver } from "components/commons";
import { capitalize, formatAmount } from "utils/text.utils";
import { prettifyPaymentStatus, prettifyProduct } from "utils/pretty.utils";
import { DateTime, Products } from "enums";
import PaymentStatus from "enums/payment-status";
import moment from "moment";

export const mapDataToList = ({ merchantPayments = {} }) => {
  const {
    merchantPaymentId,
    stationName,
    stationBusinessName,
    driver,
    vehicle,
    cardNumber,
    paidAt,
    remarks,
    status,
    amount,
    poNumber,
    orNumber,
    transactionRemarks,
  } = merchantPayments;

  return {
    locqpayId: merchantPaymentId,
    redemptionStations: (
      <div>
        <Text>{stationName ? `${stationName}` : locale.any}</Text>
        <Text className={styles.subBusinessId}>{stationBusinessName}</Text>
      </div>
    ),
    driversNameAndId: (
      <div>
        <Text>
          {driver?.firstName
            ? `${capitalize(driver?.firstName)} ${capitalize(driver?.lastName)}`
            : locale.any}
        </Text>
        <Text className={styles.subBusinessId}>{driver?.driverLicenseId || locale.any}</Text>
      </div>
    ),
    plateNumber: (
      <div>
        <Text>{vehicle?.plateNumber || locale.any}</Text>
      </div>
    ),
    fleetCardAndTransactionDate: (
      <div>
        <Text>{cardNumber}</Text>
        <Text className={styles.subBusinessId}>{moment(paidAt).format(DateTime.S)}</Text>
      </div>
    ),
    product: (
      <Product purple={locale.lubes === Products.Lubes}>{prettifyProduct(locale.lubes)}</Product>
    ),
    transactionRemarks: transactionRemarks,
    finalAmountPaid: <div className="nowrap">{`${formatAmount(amount)}`}</div>,
    poNumber: poNumber,
    orNo: orNumber,
    status: (
      <Pill grass={status === PaymentStatus.Success} cement={status === PaymentStatus.Voided}>
        {prettifyPaymentStatus(status)}
      </Pill>
    ),
    remarks:
      status === PaymentStatus.Success ? (
        ""
      ) : (
        <PopOver content={<div className={styles.remarks}>{remarks}</div>}>
          {status === PaymentStatus.Voided && <div className="link">View</div>}
        </PopOver>
      ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { status, redemptionStation, ...fs } = filterState;
  return {
    ...fs,
    status: !status.isSelectedAll ? status.value.join(",") : null,
    redemptionStationIds: redemptionStation?.value,
  };
};
