import { deleteFleetCard } from "apis/fleet.api";
import { ActionButton, Image, Modal, Text } from "components/commons";
import { CardType } from "enums";
import { useApi } from "hooks";
import { Jcb, Mastercard, Visa } from "images";
import locale from "localization";
import { useCallback } from "react";
import { handleRequest } from "utils";
import { prettifyCardType } from "utils/pretty.utils";
import styles from "./cashin-card-remove-card-modal.module.scss";

const CashinCardRemoveCardModal = ({ ...modalState }) => {
  const { close, active, fleetId, cardType, cardNumber, fleetCardId, onRemoveCardSuccess } =
    modalState;
  let icon;

  switch (cardType) {
    case CardType.Mastercard:
      icon = Mastercard;
      break;
    case CardType.Visa:
      icon = Visa;
      break;
    case CardType.Jcb:
      icon = Jcb;
      break;
    default:
      icon = Mastercard;
      break;
  }

  const { request: deleteFleetCardRequest, loading: removingFleetCard } = useApi({
    api: deleteFleetCard,
  });

  const removeFleetCardCb = useCallback(async () => {
    handleRequest(async () => {
      await deleteFleetCardRequest({ fleetId, cardId: fleetCardId }, () => removeFleetCardCb());
      onRemoveCardSuccess({ cardType, cardNumber });
    });
  }, [onRemoveCardSuccess, cardType, cardNumber, deleteFleetCardRequest, fleetId, fleetCardId]);

  return (
    <Modal
      active={active}
      title={`${locale.removeThisCard}`}
      close={close}
      className={styles.modal}
    >
      <div>
        <div className={styles.header}>
          <Image src={icon} className={styles.icon} />
          <Text className={styles.title}>
            {cardType === CardType.Mastercard && prettifyCardType(cardType)}&nbsp;&nbsp;{cardNumber}
          </Text>
        </div>

        <Text className={styles.content}>{locale.ifYouRemoveThisCard}</Text>
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: () => {
              close();
            },
            text: locale.cancel,
          },
          {
            loading: removingFleetCard,
            onClick: removeFleetCardCb,
            text: locale.remove,
          },
        ]}
      />
    </Modal>
  );
};

export default CashinCardRemoveCardModal;
