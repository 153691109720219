import React from "react";
import MaterialModal from "@material-ui/core/Modal";
import styles from "./modal.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import Title from "../title/title";
import { Text } from "components/commons";

const Modal = ({
  active,
  close,
  title,
  children,
  closable = true,
  className,
  modalContainerClassName,
  removePadding = false,
  subtitle = false,
  headerClassName,
  contentClassName,
}) => {
  return (
    <MaterialModal
      open={active}
      onClose={closable ? close : null}
      className={classNames(styles.container, modalContainerClassName, {
        [`${styles.removePadding}`]: removePadding,
      })}
    >
      <div className={classNames(styles.content, className)}>
        <div className={classNames(styles.header, headerClassName)}>
          <div>
            {title ? (
              <>
                <Title small className={styles.title}>
                  {title}
                </Title>
                {subtitle && (
                  <Text subtitle align="left">
                    {subtitle}
                  </Text>
                )}
              </>
            ) : null}
          </div>
          {closable && (
            <div className={styles.close} onClick={() => close()}>
              <CloseIcon className={styles.icon} />
            </div>
          )}
        </div>
        <div className={classNames(contentClassName, styles.modalContent)}>{children}</div>
      </div>
    </MaterialModal>
  );
};

export default Modal;
