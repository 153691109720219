import React from "react";
import { MenuItem, Select as MaterialSelect } from "@material-ui/core";
import styles from "./dropdown.module.scss";
import classNames from "classnames";
import { useCallback } from "react";

const Dropdown = ({
  value = "none",
  error,
  placeholder,
  options = [],
  className,
  dropdownClassName,
  onChange,
  disabled,
  open = null,
  renderValue,
  onClick,
  disableScrollLock = false,
  id = "menu",
}) => {
  const onChangeCb = useCallback(
    (e) => {
      if (onChange) {
        return onChange(e.target.value, e);
      }
    },
    [onChange]
  );

  const onClickCb = useCallback(
    (e) => {
      if (onClick) {
        return onClick(e.target.value, e);
      }
    },
    [onClick]
  );

  return (
    <div className={classNames(styles.container, className)}>
      <MaterialSelect
        className={classNames(styles.dropdown, dropdownClassName, {
          [`${styles.disabled}`]: disabled,
        })}
        error={error}
        value={value}
        renderValue={renderValue}
        variant="outlined"
        placeholder={placeholder}
        SelectDisplayProps={{ style: { fontSize: "14px", fontWeight: 500 } }}
        onChange={onChangeCb}
        disabled={disabled}
        open={open}
        onClick={onClickCb}
        id={id}
      >
        {placeholder && (
          <MenuItem value="none" disabled>
            <span className={styles.placeholder}>{placeholder}</span>
          </MenuItem>
        )}
        {options.map((o) => {
          return (
            <MenuItem key={o.value} value={o.value} disabled={o.disabled}>
              {o.label}
            </MenuItem>
          );
        })}
      </MaterialSelect>
    </div>
  );
};

export default Dropdown;
