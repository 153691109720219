export const otpState = {
  form: {
    otp: {
      validations: {
        isRequired: true,
        isNumeric: true,
        maxLength: 6,
      },
      maxLength: 6,
      label: "One Time Pin (Enter the 6-digit code sent to your mobile number)",
    },
    account: {
      isRequired: true,
      label: "My BPI Accounts",
      placeholder: "Select BPI Account",
    },
  },
};
