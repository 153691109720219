import { exportCSV, parseCSVFile } from "utils/object.utils";
import { isAlphaNumericWithSymbols, isMobileNumberValid11, isNumeric } from "utils/text.utils";
import React, { useContext } from "react";
import ImportModal from "components/modals/import-modal/import-modal";
import { ActionButton, Modal, Text } from "components/commons";
import locale from "localization";
// import { useCallback } from "react";
import { useModal } from "hooks";
import { FleetContext } from "contexts";

const ImportDriverModal = ({ refresh, request = {}, ...importModal }) => {
  const resultModal = useModal();
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet || {};
  const validateFile = async ({ file }, onParse) => {
    const items = await parseCSVFile(file, [
      {
        text: "first_name",
        key: "firstName",
        validation: (text, row) => {
          const error = !isAlphaNumericWithSymbols(text.toString()) || !text.trim();
          return {
            error,
            message: "Invalid first name",
            errorType: "name",
          };
        },
      },
      {
        text: "last_name",
        key: "lastName",
        validation: (text, row) => {
          const error = !isAlphaNumericWithSymbols(text.toString()) || !text.trim();
          return {
            error,
            message: "Invalid last name",
            errorType: "name",
          };
        },
      },
      {
        text: "driver_id",
        key: "driverLicenseId",
        validation: (text = "", row) => {
          const error =
            text.trim().length !== 11 || !isAlphaNumericWithSymbols(text) || !text.trim();
          return {
            error,
            message: "Invalid driver ID",
            errorType: "driverId",
          };
        },
      },
      {
        text: "mobile_number",
        key: "contactNumber",
        validation: (text, row) => {
          if (text.toString().length === 10 && text[0] === "9" && isNumeric(text)) {
            return {
              error: false,
              message: "",
            };
          }
          const error = !isMobileNumberValid11(text);
          return {
            error,
            message: "Invalid mobile number",
            errorType: "mobileNumber",
          };
        },
      },
    ]);
    return items;
  };

  return (
    <div>
      <Modal
        {...resultModal}
        close={() => {
          resultModal.close();
          refresh();
        }}
      >
        {resultModal.content}
        <ActionButton
          right={!resultModal.isSuccess}
          center={resultModal.isSuccess}
          items={
            resultModal.isSuccess
              ? [
                  {
                    text: locale.goToListOfDrivers,
                    onClick: () => {
                      resultModal.close();
                      refresh();
                    },
                    primary: true,
                  },
                ]
              : [
                  {
                    text: locale.gotIt,
                    onClick: () => {
                      resultModal.close();
                      refresh();
                    },
                  },
                  {
                    text: locale.retry,
                    onClick: () => {
                      resultModal.close();
                      importModal.show();
                    },
                  },
                ]
          }
        />
      </Modal>
      <ImportModal
        title={locale.importDriversByCSV}
        {...importModal}
        loading={request.loading}
        onSubmit={async (file) => {
          const parsedFile = await validateFile(file);
          const errors = [];
          [
            {
              type: "name",
              label: "Wrong First Name or Last Name format (row):",
            },
            {
              type: "driverId",
              label: "Wrong Driver ID format (row):",
            },
            {
              type: "mobileNumber",
              label: locale.wrongMobileNumber,
            },
          ].forEach((errorType) => {
            const et = parsedFile.errorType[errorType.type];
            if (parsedFile.errorType[errorType.type]) {
              errors.push({
                title: errorType.label,
                items: et,
              });
            }
          });
          if (parsedFile.hasBlankRows) {
            errors.push({
              title: "Other error:",
              items: ["There are blank rows or columns in your file"],
            });
          }

          if (parsedFile?.invalidFormat) {
            resultModal.show({
              title: locale.importFailed,
              isSuccess: false,
              content: (
                <ErrorImport
                  success={false}
                  errors={[
                    {
                      title: "Other error:",
                      items: ["Invalid CSV File"],
                    },
                  ]}
                />
              ),
            });
          } else if (parsedFile?.passed.length) {
            await request.request({
              ...file,
              fleetId,
            });

            if (parsedFile.errors.length) {
              resultModal.show({
                title: locale.importFailed,
                isSuccess: false,
                content: <ErrorImport errors={errors} success />,
              });
            } else {
              resultModal.show({
                title: locale.importSuccessful,
                isSuccess: true,
                content: (
                  <div
                    style={{
                      width: "400px",
                      textAlign: "center",
                    }}
                  >
                    <Text>{locale.youMayGoToDriversPage}</Text>
                  </div>
                ),
              });
            }

            importModal.close();
          } else {
            importModal.close();

            resultModal.show({
              title: locale.importFailed,
              content: <ErrorImport errors={errors} />,
            });
          }
        }}
      >
        <Text
          subtitle
          style={{
            margin: "0px 0px 20px 0px",
          }}
        >
          {locale.followTheFollowingColumnOrderAndLeaveRow1}
          <div
            style={{
              margin: "20px 0px",
            }}
          >
            {[
              locale.driverFirstName,
              locale.driverLastName,
              locale.driverId,
              locale.driverContactNumber,
            ].map((column, index) => {
              return (
                <div key={index}>{locale.populate(locale.columnNTitle, [index + 1, column])}</div>
              );
            })}
          </div>
          <locale.Populate
            text={locale.downloadASampleTemplate}
            items={[
              <span
                colored
                style={{
                  color: "#753bbd",
                  fontWeight: "700",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  exportCSV({
                    rows: [
                      "first_name,last_name,driver_id,mobile_number",
                      "Gener,Enriquez,D0012121111,09123456789",
                      "Bagwis,Cristobal,D0012131111,09123456788",
                      "Rommel,Moreno,D0012141111,09123456787",
                    ],
                    filename: "sample",
                  });
                }}
              >
                {locale.sampleTemplate}
              </span>,
            ]}
          />
        </Text>
      </ImportModal>
    </div>
  );
};

const ErrorImport = ({ errors = [] }) => {
  return (
    <div
      style={{
        width: "420px",
      }}
    >
      <div>
        <Text>{locale.fileUploadSuccess}</Text>
      </div>
      {errors.map((error, index) => {
        return (
          <div
            key={index}
            className="flex"
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                width: "250px",
              }}
            >
              <Text subtitle>{error.title}</Text>
            </div>
            <div>
              <Text>
                {error.items.slice(0, 5).join(", ")}
                {error.items.length > 5 && `, and ${error.items.length - 5} more others.`}
              </Text>
            </div>
          </div>
        );
      })}

      {/* {Boolean(otherError.length) && (
        <div
          className="flex"
          style={{
            marginTop: "20px",
          }}
        >
          <div
            style={{
              width: "250px",
            }}
          >
            <Text subtitle>{locale.errorInStatusMessage}</Text>
          </div>
          <div>
            <Text>
              {otherError.slice(0, 5).join(", ")}
              {otherError.length > 5 && `, and ${otherError.length - 5} more others.`}
            </Text>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ImportDriverModal;
