import { ActionButton, Modal, Tabs, Text } from "components/commons";
import React, { useCallback, useContext, useState } from "react";
import locale from "localization";
import FuelCodeTemplateList from "./fuel-code-template-list";
import styles from "./generate-fuel-code-modal.module.scss";
import FuelCodeForm from "./fuel-code-form";
import { generateFuelCodeTemplate, updateFuelCodeTemplate, deleteFuelCodeTemplate } from "apis";
import { useApi, useModal } from "hooks";
import { FleetContext } from "contexts";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const GenerateFuelCodeModal = ({ onAdd, ...modal }) => {
  const { fleet } = useContext(FleetContext);
  const { fleetId, fleetStations } = fleet;
  const [active, setActive] = useState(0);
  const { request: addFuelCodeTemplateRequest, loading: addingFuelCodeTemplate } = useApi({
    api: generateFuelCodeTemplate,
    params: {
      fleetId,
    },
  });

  const { request: editFuelCodeTemplateRequest, loading: editingFuelCodeTemplate } = useApi({
    api: updateFuelCodeTemplate,
    params: {
      fleetId,
    },
  });

  const { request: deleteFuelCodeTemplateRequest, loading: deletingFuelCodeTemplate } = useApi({
    api: deleteFuelCodeTemplate,
    params: {
      fleetId,
    },
  });

  const notifModal = useModal();

  const onClose = useCallback(() => {
    setActive(0);
    modal.close();
  }, [modal, setActive]);

  const loading = addingFuelCodeTemplate || editingFuelCodeTemplate || deletingFuelCodeTemplate;

  const templateFormModal = useModal();

  return (
    <div>
      <Modal {...modal} className={styles.container} close={onClose} title="">
        <Tabs
          active={active}
          // header={
          //   <div>
          //     <Title small className={styles.title}>
          //       {modal.title}
          //     </Title>
          //   </div>
          // }
          onTabChange={(index) => {
            setActive(index);
          }}
          content={[
            {
              title: locale.withTemplate,
              content: (
                <FuelCodeTemplateList
                  close={modal.close}
                  onAdd={async (fuelCodes) => {
                    onAdd(fuelCodes);
                    onClose();
                  }}
                  onDeleteTemplate={(fuelCodeTemplateId, callback) => {
                    notifModal.show({
                      title: `${locale.deleteTemplate}?`,
                      content: locale.youAreAboutToDeleteTemplate,
                      hasCancel: true,
                      onOkText: locale.delete,
                      submit: async () => {
                        await deleteFuelCodeTemplateRequest({
                          fuelCodeTemplateId,
                        });
                        callback();
                        notifModal.close();
                      },
                    });
                  }}
                  showAddTemplateModal={(callback) => {
                    templateFormModal.show({
                      actionText: locale.saveTemplate,
                      formTitle: locale.addTemplate,
                      initialState: {
                        period: {
                          disabled: true,
                          hidden: true,
                          value: {
                            startDate: null,
                            endDate: null,
                          },
                        },
                        saveTemplate: {
                          hidden: true,
                        },
                      },
                      submit: async (params) => {
                        notifModal.show({
                          hasCancel: true,
                          onOkText: locale.continue,
                          title: `${locale.saveTemplate}?`,
                          content: locale.youAreAboutToUpdateTemplate,
                          submit: async () => {
                            await addFuelCodeTemplateRequest(params);
                            templateFormModal.close();
                            notifModal.close();
                            callback();
                          },
                        });
                      },
                    });
                  }}
                  showEditTemplateModal={(value, callback) => {
                    templateFormModal.show({
                      actionText: locale.saveTemplate,
                      formTitle: locale.editFuelCodeTemplate,
                      initialState: {
                        mobileNumber: {
                          value: value.mobileNumber,
                        },
                        period: {
                          disabled: true,
                          hidden: true,
                          value: {
                            startDate: null,
                            endDate: null,
                          },
                        },
                        duration: {
                          value: value.durationDays,
                        },
                        literVolume: {
                          value: value.volumeRedeemLimit,
                        },
                        productCode: {
                          value: value.productCode,
                        },
                        fuelCodeType: {
                          value: value.fuelCodeType,
                        },
                        limitOneTime: {
                          value: value.maxUsage !== 0,
                        },
                        sendAsSms: {
                          value: value.sendSms,
                        },
                        autoRenew: {
                          value: value.autoRenew,
                        },
                        saveTemplate: {
                          hidden: true,
                        },
                        driverIds: {
                          value: value.driver?.driverId,
                          label:
                            `${value.driver?.firstName} ${value.driver?.lastName}`.trim() || "",
                          driverMobileNumber: value.driver?.mobileNumber,
                        },
                        vehicleIds: {
                          value: value.vehicle?.vehicleId,
                          label: value.vehicle
                            ? `${value.vehicle?.model} (${value.vehicle?.plateNumber})`.trim()
                            : "",
                        },
                      },
                      submit: (params) => {
                        notifModal.show({
                          hasCancel: true,
                          onOkText: locale.continue,
                          title: `${locale.saveTemplate}?`,
                          content: locale.youAreAboutToUpdateTemplate,
                          submit: async () => {
                            await editFuelCodeTemplateRequest({
                              ...params,
                              fuelCodeTemplateId: value.fuelCodeTemplateId,
                            });
                            notifModal.close();
                            templateFormModal.close();
                            callback();
                          },
                        });
                      },
                    });
                  }}
                />
              ),
            },
            {
              title: locale.withoutTemplate,
              content: (
                <div>
                  <FuelCodeForm
                    close={onClose}
                    title={modal.title}
                    loading={addingFuelCodeTemplate}
                    actionText={locale.add}
                    submit={async (params) => {
                      if (params.saveTemplate) {
                        await addFuelCodeTemplateRequest(params);
                      }
                      onAdd([params]);
                      onClose();
                    }}
                    fleetStations={fleetStations}
                  />
                </div>
              ),
            },
          ]}
        />
      </Modal>
      <Modal {...notifModal}>
        <div className="">{notifModal.content}</div>
        <div className="text-right">
          <ActionButton
            loading={loading}
            center
            items={[
              ...(notifModal.hasCancel
                ? [
                    {
                      text: locale.cancel,
                      onClick: () => {
                        notifModal.close();
                      },
                    },
                  ]
                : []),
              {
                text: notifModal.onOkText,
                onClick: () => {
                  notifModal.submit();
                },
                primary: true,
              },
            ]}
          />
        </div>
      </Modal>
      <Modal {...templateFormModal} className={styles.container}>
        <div
          className={styles.backlink}
          onClick={() => {
            templateFormModal.close();
          }}
        >
          <span className={styles.backlinkContent}>
            <ChevronLeftIcon />
            <Text className={styles.backlinkText}>{locale.fuelCodeTemplates}</Text>
          </span>
        </div>
        <FuelCodeForm
          close={templateFormModal.close}
          title={templateFormModal.formTitle}
          loading={loading}
          initialState={templateFormModal.initialState}
          actionText={templateFormModal.actionText}
          submit={(params) => {
            templateFormModal.submit(params);
          }}
          fleetStations={fleetStations}
        />
      </Modal>
    </div>
  );
};

export default GenerateFuelCodeModal;
