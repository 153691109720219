import { Path } from "enums";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import StationListModule from "modules/station/station-list/station-list.module";

const StationPage = () => {
  return (
    <Switch>
      <Route exact path={Path.StationList} component={StationListModule} />
      <Redirect to={Path.StationList} />
    </Switch>
  );
};

export default StationPage;
