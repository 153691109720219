import locale from "localization";

export const fleetCardManagementColumns = [
  { key: "cardNumber", text: locale.cardNumber, width: "8%" },
  { key: "cardType", text: locale.cardType, width: "8%" },
  { key: "assignedDriver", text: locale.assignedDriver, width: "8%" },
  { key: "product", text: locale.product, width: "8%" },
  { key: "assignedVehicle", text: locale.assignedVehicle, width: "8%" },
  { key: "locqpayLimit", text: locale.locqpayLimit, width: "8%" },
  { key: "locqpayPayment", text: locale.locqpayPayment, width: "8%" },
  { key: "transactionLimit", text: locale.transactionLimit, width: "8%" },
  { key: "transactionThisMonth", text: locale.transactionThisMonth, width: "8%" },
  { key: "status", text: locale.status, width: "8%" },
  { key: "action", text: locale.action, width: "8%" },
];
