import { isEmailValid, isNumber } from "./text.utils";

export const validateField = (field, form) => {
  const { value, validations = {}, isDisabled } = field;
  if (isDisabled && isDisabled(field, form)) {
    return {
      error: false,
      messages: [],
    };
  }
  const { custom } = validations;

  const validateResult = validateValue(validations, value);
  let error = validateResult.error;
  const messages = validateResult.messages;

  if (custom && Array.isArray(custom)) {
    custom.forEach((validation) => {
      const state = validation(value, field, form);
      if (state.error) error = true;
      if (state.message) messages.push(state.message);
    });
  }

  return {
    error,
    messages,
  };
};

export const validateValue = (field, value) => {
  const messages = [];
  let error = false;
  const {
    isRequired = false,
    isNumber: isNumberValidate = false,
    isRequiredArray = false,
    isEmail = false,
    maxLength = 0,
  } = field || {};
  const validation = [
    {
      isEnabled: isRequired,
      condition: value && value?.toString().trim().length !== 0,
      message: "This field is required",
    },
    {
      isEnabled: isNumberValidate,
      condition: value && value?.toString().trim().length !== 0 ? isNumber(value) : true,
      message: "This field should be numeric",
    },
    {
      isEnabled: isRequiredArray,
      condition: Array.isArray(value) && value?.length > 0,
      message: "This field is required",
    },
    {
      isEnabled: isEmail,
      condition:
        value && value?.toString().trim().length !== 0 ? isEmailValid(value?.toString()) : true,
      message: "This field is not a valid email",
    },
    {
      isEnabled: maxLength > 0,
      condition: value && value.trim() ? value.length >= maxLength : true,
      message: `Maxlength is ${maxLength}`,
    },
  ];

  validation.forEach(({ isEnabled, condition, message }) => {
    if (isEnabled && !condition) {
      error = true;
      messages.push(message);
    }
  });

  return {
    error,
    messages,
  };
};
