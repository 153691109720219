import Validation from "utils/validation.utils";
import locale from "localization";

export const initialState = (state = {}) => {
  const {
    name = {
      value: "",
      disabled: false,
    },
  } = state;
  return {
    name: {
      name: "name",
      placeholder: locale.enterGroupName,
      validations: [Validation.required({ noMessage: true }), Validation.name()],
      maxLength: 75,
      label: locale.groupName,
      required: true,
      ...name,
    },
  };
};
