import { Get, Post } from "utils";

export const getBpiToken = async ({ ...query }) => {
  const res = await Get(`ms-fleet/cashin/bpi/token`, query);
  return res.data;
};

export const getBpiAccount = async ({ ...query }) => {
  const res = await Post(`ms-fleet/cashin/bpi/accounts`, query);
  return res.data;
};

export const initiateBpiPayment = async ({ ...query }) => {
  const res = await Post(`ms-fleet/cashin/bpi/payment/initiate`, query);
  return res.data;
};

export const processBpiPayment = async ({ ...query }) => {
  const res = await Post(`ms-fleet/cashin/bpi/payment/process`, query);
  return res.data;
};
