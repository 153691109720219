import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const login = async ({ email, password, portalType, token }) => {
  const data = { email, password, portalType };
  if (token) {
    data.token = token;
  }

  const res = await Post(`${ApiPath.Login}`, data);
  return res.data;
};

export const logout = async () => {
  const res = await Get(`${ApiPath.Logout}`);
  return res.data;
};

export const refresh = async ({ refreshToken }) => {
  const res = await Post(`${ApiPath.Refresh}`, { refreshToken });
  return res.data;
};

export const forgotPassword = async ({ email }) => {
  const res = await Post(`${ApiPath.SendResetPassword}/fleet`, { email });
  return res;
};

export const resetPassword = async ({ password, token }) => {
  const res = await Post(`${ApiPath.ChangePassword}`, { password, token });
  return res.data;
};

export const validateResetPasswordToken = async ({ token }) => {
  const res = await Get(`${ApiPath.ValidateToken}`, { token });
  return res;
};

export const validateOTPTwoFactorAuth = async ({ email, otp }) => {
  const res = await Post(`${ApiPath.TwoFactorAuth}`, { email, otp });
  return res.data;
};
