import { FuelIcon, Modal, Title, Text, ActionButton } from "components/commons";
import React from "react";
import locale from "localization";
import { formatAmount, formatAmountPerLiter, formatNumber } from "utils";
import styles from "./confirm-buy-fuel.module.scss";
import { prettifyProduct } from "utils/pretty.utils";
import classNames from "classnames";

const ConfirmBuyFuel = ({
  loading,
  liter,
  type,
  amount,
  productCode,
  literVolume,
  purchase,
  total,
  ...modal
}) => {
  return (
    <Modal {...modal} title={locale.askConfirmWithPayment}>
      <div>
        <div className={styles.confirmation}>
          <Title medium className={styles.title}>
            <locale.Populate
              text={liter === 1 ? locale.nLiter : locale.nLiters}
              items={[formatNumber(liter)]}
            />
          </Title>
          <Text>
            <locale.Populate
              text={locale.ofText}
              className={styles.fuel}
              items={[
                <span className={styles.fuel}>
                  <FuelIcon className={styles.icon} type={productCode.value} />
                  {prettifyProduct(productCode.value)}
                </span>,
              ]}
            />
          </Text>
          <Text>{locale.isAboutToBePurchased}</Text>
          <Text>{locale.kindlyReviewTheDetails}</Text>
        </div>
        <div className={styles.table}>
          <div className={styles.item}>
            <div className={styles.label}>{locale.productPrice}</div>
            <div>
              <Text strong>{formatAmountPerLiter(productCode.creditPrice)}</Text>
            </div>
          </div>
          <div className={styles.item}>
            <div className={classNames(styles.label, styles.uppercase)}>{locale.totalCaps}</div>
            <div>
              <Text strong>{formatAmount(total)}</Text>
            </div>
          </div>
        </div>
        <ActionButton
          right
          items={[
            {
              text: locale.cancel,
              onClick: () => modal.close(),
            },
            {
              text: locale.pay,
              onClick: () => purchase(),
              loading,
            },
          ]}
        />
      </div>
    </Modal>
  );
};

export default ConfirmBuyFuel;
