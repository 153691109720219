import { exportCSV, parseCSVFile } from "utils/object.utils";
import React, { useContext } from "react";
import ImportModal from "components/modals/import-modal/import-modal";
import { ActionButton, Modal, Text } from "components/commons";
import locale from "localization";
// import { useCallback } from "react";
import { useModal } from "hooks";
import { FleetContext } from "contexts";
import { isAlphaNumeric } from "utils/text.utils";

const ImportVehicleModal = ({ refresh, request = {}, ...importModal }) => {
  const resultModal = useModal();
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet || {};
  const validateFile = async ({ file }, onParse) => {
    const items = await parseCSVFile(file, [
      {
        text: "plate number",
        key: "plateNumber",
        validation: (text, row) => {
          const error = text.length < 6 || text.length > 8 || !isAlphaNumeric(text);
          return {
            error,
            message: "Invalid plate number",
            errorType: "plateNumber",
          };
        },
      },
      {
        text: "vehicle type",
        key: "vehicleType",
      },
      {
        text: "remarks",
        key: "remarks",
      },
    ]);
    return items;
  };

  return (
    <div>
      <Modal
        {...resultModal}
        close={() => {
          resultModal.close();
          refresh();
        }}
      >
        {resultModal.content}
        <ActionButton
          right={!resultModal.isSuccess}
          center={resultModal.isSuccess}
          items={
            resultModal.isSuccess
              ? [
                  {
                    text: locale.goToListOfVehicles,
                    onClick: () => {
                      resultModal.close();
                      refresh();
                    },
                    primary: true,
                  },
                ]
              : [
                  {
                    text: locale.gotIt,
                    onClick: () => {
                      resultModal.close();
                      refresh();
                    },
                  },
                  {
                    text: locale.retry,
                    onClick: () => {
                      resultModal.close();
                      importModal.show();
                    },
                  },
                ]
          }
        />
      </Modal>
      <ImportModal
        title={locale.importVehicleByCsv}
        {...importModal}
        loading={request.loading}
        onSubmit={async (file) => {
          const parsedFile = await validateFile(file);
          const errors = [];
          [
            {
              type: "plateNumber",
              label: locale.wrongPlateNumberFormat,
            },
          ].forEach((errorType) => {
            const et = parsedFile.errorType[errorType.type];
            if (parsedFile.errorType[errorType.type]) {
              errors.push({
                title: errorType.label,
                items: et,
              });
            }
          });
          if (parsedFile.hasBlankRows) {
            errors.push({
              title: "Other error:",
              items: ["There are blank rows or columns in your file"],
            });
          }
          if (parsedFile?.invalidFormat) {
            resultModal.show({
              title: locale.importFailed,
              isSuccess: false,
              content: (
                <ErrorImport
                  errors={[
                    {
                      title: "Other error:",
                      items: ["Invalid CSV File"],
                    },
                  ]}
                />
              ),
            });
          } else if (parsedFile?.passed.length) {
            await request.request({
              ...file,
              fleetId,
            });

            if (parsedFile.errors.length) {
              resultModal.show({
                title: locale.importFailed,
                isSuccess: false,
                content: <ErrorImport errors={errors} success />,
              });
            } else {
              resultModal.show({
                title: locale.importSuccessful,
                isSuccess: true,
                content: (
                  <div
                    style={{
                      width: "400px",
                      textAlign: "center",
                    }}
                  >
                    <Text>{locale.youMayGoToVehiclesPage}</Text>
                  </div>
                ),
              });
            }

            importModal.close();
          } else {
            importModal.close();

            resultModal.show({
              title: locale.importFailed,
              content: <ErrorImport errors={errors} />,
            });
          }
        }}
      >
        <Text
          subtitle
          style={{
            margin: "0px 0px 20px 0px",
          }}
        >
          {locale.followTheFollowingColumnOrderAndLeaveRow1}
          <div
            style={{
              margin: "20px 0px",
            }}
          >
            {[locale.vehiclePlateNumber, locale.vehicleType, locale.remarks].map(
              (column, index) => {
                return (
                  <div key={index}>{locale.populate(locale.columnNTitle, [index + 1, column])}</div>
                );
              }
            )}
          </div>
          <locale.Populate
            text={locale.downloadASampleTemplate}
            items={[
              <span
                colored
                style={{
                  color: "#753bbd",
                  fontWeight: "700",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  exportCSV({
                    rows: [
                      "plate number,vehicle type,remarks",
                      "UPV899,Mitsubishi L300,White",
                      "NIX303,Toyota Vios,Maroon",
                      "NCE7847,Toyota Hilux,Black",
                    ],
                    filename: "sample",
                  });
                }}
              >
                {locale.sampleTemplate}
              </span>,
            ]}
          />
        </Text>
      </ImportModal>
    </div>
  );
};

const ErrorImport = ({ errors = [] }) => {
  return (
    <div
      style={{
        width: "420px",
      }}
    >
      <div>
        <Text>{locale.fileUploadSuccess}</Text>
      </div>
      {errors.map((error, index) => {
        return (
          <div
            key={index}
            className="flex"
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                width: "250px",
              }}
            >
              <Text subtitle>{error.title}</Text>
            </div>
            <div>
              <Text>
                {error.items.slice(0, 5).join(", ")}
                {error.items.length > 5 && `, and ${error.items.length - 5} more others.`}
              </Text>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ImportVehicleModal;
