import { Text, FuelIcon } from "components/commons";
import { Balance } from "enums";
import { locale } from "localization/en";
import React from "react";
import { formatAmount, formatVolume } from "utils";
import { prettifyBalance } from "utils/pretty.utils";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "./balance-card.module.scss";

const BalanceCard = ({ balance, type, loading }) => {
  return (
    <div className={styles.balanceCard}>
      <div className={styles.nameBalance}>
        <FuelIcon type={type} />
        <div>
          {loading ? (
            <Skeleton variant="rect" className={styles.loaderAmount} />
          ) : (
            <Text className={styles.title}>
              {type === Balance.Peso ? formatAmount(balance, "") : formatVolume(balance, "")}
              <span className={styles.literSign}>{type === Balance.Peso ? "PHP" : "L"}</span>
            </Text>
          )}
          {loading ? (
            <Skeleton variant="text" className={styles.loaderText} />
          ) : (
            <Text className={styles.subtitle}>{`${prettifyBalance(type)} ${locale.balance}`}</Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default BalanceCard;
