import { Path } from "enums";
import FleetCardManagementModule from "modules/fleet-card/management/management.module";
import EditFleetCardManagementModule from "modules/fleet-card/management/edit/management-edit.module";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

const FleetCardPage = () => {
  return (
    <Switch>
      <Route exact path={Path.FleetCardManagement} component={FleetCardManagementModule} />
      <Route exact path={Path.EditFleetCardManagement} component={EditFleetCardManagementModule} />
      <Redirect to={Path.FleetCardManagement} />
    </Switch>
  );
};

export default FleetCardPage;
