import React from "react";
import { Button } from "..";
import styles from "./tabs.module.scss";

const Tabs = ({ onTabChange, active = 0, content, header }) => {
  return (
    <div className="custom-tab">
      <ul className={styles.customtab}>
        {content.map(({ title }, index) => (
          <Button
            className={`${styles.navlink} ${index === active ? "active" : ""}`}
            onClick={() => onTabChange(index)}
            actionOutlined={true}
            rounded={true}
            key={index}
            tabSelectable={index !== active}
            outline={true}
            tiny={true}
          >
            {title}
          </Button>
        ))}
      </ul>
      {header}
      <div className="tab-content p-3">{content[active] && content[active].content}</div>
    </div>
  );
};

export default Tabs;
