import { Autocomplete } from "components/commons";
import city from "../../../assets/cities.json";
import provinceOptions from "../../../assets/province.json";
import React, { useState } from "react";
import { useMemo } from "react";
import locale from "localization/en";

const SelectCity = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useMemo(() => {
    const { province } = props.filterState;
    const selectedProvince = provinceOptions.find((pro) => {
      return pro.label === province;
    });
    const values =
      selectedProvince || province === "all"
        ? city
            .filter((f) => {
              return f.province === selectedProvince?.value || province === "all";
            })
            .map((p) => {
              return {
                label: p.label,
                value: p.label,
              };
            })
        : [];
    setOptions(values);
  }, [props.filterState]);

  return <Autocomplete {...props} options={[{ label: locale.all, value: "all" }, ...options]} />;
};

export default SelectCity;
