import { Autocomplete } from "components/commons";
import loanableProducts from "../../../assets/loanableProducts.json";
import React, { useMemo } from "react";

const SelectLoanProducts = ({ ...props }) => {
  const options = useMemo(() => {
    return loanableProducts.map((p) => {
      return {
        label: p.label,
        value: p.value,
      };
    });
  }, []);

  return <Autocomplete {...props} options={[...options]} />;
};

export default SelectLoanProducts;
