import { prettifyPaymentStatus } from "utils/pretty.utils";
import PaymentStatus from "enums/payment-status";

export const mapOptions = (values = [], prettifier = () => {}) => {
  return [
    ...values.map((value) => ({
      label: prettifier(value),
      value,
    })),
  ];
};

export const redemptionStatusOptions = mapOptions(
  [PaymentStatus.Success, PaymentStatus.Voided],
  prettifyPaymentStatus
);
