import { Field, Modal, ActionButton, TextField } from "components/commons";
import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  forwardRef,
  useEffect,
  useContext,
  useImperativeHandle,
} from "react";
import locale from "localization";
import { useApi, useForm } from "hooks";
import { initialState as formState } from "./otp.state";
import { validateOTPTwoFactorAuth, login, getUserV2 } from "apis";
import { parseNumber } from "utils";
import { ErrorCode, Path } from "enums";
import { AnalyticsContext } from "contexts";
import { useHistory } from "react-router-dom";

const TwoFactorAuthOtpModal = (
  { initialState, password, remember, email, cashinId, ...state },
  ref
) => {
  const { identify } = useContext(AnalyticsContext);
  const { close } = state;
  const [countInvalidOTP, setCountInvalidOTP] = useState(0);
  const [resendLimit, setResendLimit] = useState(0);
  const [timer, setTimer] = useState(600); // Initial timer value (in seconds)
  const [timerThreeTimes, setTimerFiveTimes] = useState(3600); // Initial timer value (in seconds)
  const [isTimerRunning, setIsTimerRunning] = useState(false); // Flag to track if timer is running
  const timerRef = useRef(null); // Ref for the timer interval
  const timerRefFiveTimes = useRef(null); // Ref for the timer interval
  const [isTimerRunningFiveTimes, setIsTimerRunningFiveTimes] = useState(false); // Flag to track if timer is running
  const history = useHistory();
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const {
    fields,
    modifyField,
    submitForm,
    isFormSubmittable,
    modifyForm,
    applyFieldErrors,
    makeFormInvalid,
    clearForm,
  } = useForm({
    initialState: form,
  });

  const otpProcessRequest = useApi({
    api: validateOTPTwoFactorAuth,
    modalError: false,
  });

  const { request: loginRequest, loading: loggingIn } = useApi({
    api: login,
    modalError: false,
  });

  const { request: getUserRequest } = useApi({
    api: getUserV2,
    pageError: true,
  });

  const { loading } = otpProcessRequest;

  useEffect(() => {
    if (state.active) {
      clearForm();
      setResendLimit(0);
      setCountInvalidOTP(0);
    }
    //eslint-disable-next-line
  }, [state.active]);

  // Helper function to format time as mm:ss
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  // Function to start the timer
  const startTimer = useCallback(() => {
    setIsTimerRunning(true);
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(timerRef.current);
          setIsTimerRunning(false);
          return 0;
        }
      });
    }, 1000);
  }, []);

  //for invalid five times
  const startTimerThreeTimes = useCallback(() => {
    setIsTimerRunningFiveTimes(true);
    timerRefFiveTimes.current = setInterval(() => {
      setTimerFiveTimes((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(timerRefFiveTimes.current);
          setIsTimerRunningFiveTimes(false);
          return 0;
        }
      });
    }, 1000);
  }, []);

  // Function to handle timer expiration
  useEffect(() => {
    if (timer === 0) {
      clearInterval(timerRef.current);
      setIsTimerRunning(false);
      setTimer(600);
    }
  }, [timer]);

  //for five times invalid
  useEffect(() => {
    if (timerThreeTimes === 0) {
      clearInterval(timerRefFiveTimes.current);
      setIsTimerRunningFiveTimes(false);
      setCountInvalidOTP(0);
      setTimerFiveTimes(3600);
    }
  }, [timerThreeTimes]);

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
    };
  }, [timerRef]);

  useImperativeHandle(ref, () => ({
    handleStartTimer() {
      handleResetFive();
      if (!isTimerRunning) {
        startTimer();
      } else {
        handleReset();
        setTimer(600);
        startTimer();
      }
    },
    handleResetTimer() {
      handleReset();
    },
  }));

  const handleReset = useCallback(() => {
    clearInterval(timerRef.current);
    setTimer(600);
    setIsTimerRunning(false);
  }, [timerRef]);

  const handleResetFive = useCallback(() => {
    clearInterval(timerRefFiveTimes.current);
    setTimer(0);
    setIsTimerRunningFiveTimes(false);
  }, [timerRefFiveTimes]);

  const handleSubmitOTP = async () => {
    try {
      const result = await otpProcessRequest.request({
        email,
        otp: parseNumber(fields?.otp?.value),
      });

      if (result?.token) {
        const res = await loginRequest({
          email,
          password,
          portalType: "fleet",
          token: result.token,
        });
        localStorage.setItem("accessToken", res?.AccessToken);
        localStorage.setItem("refreshToken", res.RefreshToken);

        const userDetails = await getUserRequest();

        if (
          userDetails &&
          userDetails.userAccesses.find((value) => value.portal === "fleet") !== undefined
        ) {
          localStorage.setItem("userDetails", JSON.stringify(userDetails));
          identify(userDetails.userId, {
            userId: userDetails.userId,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
          });

          close();
          clearForm();
          setCountInvalidOTP(0);
          setResendLimit(0);

          if (remember) {
            localStorage.setItem("email", email);
          } else {
            localStorage.removeItem("email");
          }

          history.push(Path.SelectUserFleet);
        }
      }
    } catch (error) {
      makeFormInvalid(true);
      switch (error?.data?.errorCode) {
        case ErrorCode.InvalidOTPCode:
          if (countInvalidOTP === 3) {
            if (timerThreeTimes === 3600) {
              handleReset();
              setTimer(600);
              startTimerThreeTimes();
            }

            applyFieldErrors({
              otp: locale.enteredInvalidOTPThreeTimes,
            });
          } else {
            setCountInvalidOTP(countInvalidOTP + 1);

            applyFieldErrors({
              otp: locale.otpInvalidCode,
            });
          }

          break;
        case ErrorCode.ExpiredOTP:
          applyFieldErrors({
            otp: locale.expiredOTP,
          });
          break;
        case ErrorCode.UserLockOTP:
          handleReset();
          handleResetFive();
          setTimerFiveTimes(3600);
          startTimerThreeTimes();
          applyFieldErrors({
            otp: locale.lockedAccount,
          });
          break;
        default:
          applyFieldErrors({
            otp: error?.data?.message,
          });
          break;
      }
    }
  };

  const resendOTP = useCallback(async () => {
    let requestParams = {
      email,
      password,
      portalType: "fleet",
    };

    try {
      setResendLimit(resendLimit + 1);
      handleReset();
      setTimer(600);
      startTimer();
      await loginRequest({
        ...requestParams,
      });
    } catch (error) {
      makeFormInvalid(true);
      switch (error?.data?.errorCode) {
        case ErrorCode.OtpLimitReached:
          clearInterval(timerRef.current);
          setIsTimerRunning(false);
          setTimer(600);
          setResendLimit(0);
          applyFieldErrors({
            otp: locale.reachedResendLimit,
          });
          startTimer();
          break;
        case ErrorCode.UserLockOTP:
          handleReset();
          handleResetFive();
          setTimerFiveTimes(3600);
          startTimerThreeTimes();
          setResendLimit(0);
          applyFieldErrors({
            otp: locale.lockedAccount,
          });
          startTimer();
          break;
        default:
          clearInterval(timerRef.current);
          setIsTimerRunning(false);
          setTimer(600);
          setResendLimit(0);
          startTimer();
          break;
      }
    }
  }, [
    resendLimit,
    startTimer,
    handleReset,
    applyFieldErrors,
    makeFormInvalid,
    loginRequest,
    email,
    password,
    startTimerThreeTimes,
    handleResetFive,
  ]);

  const closeModal = () => {
    close();
    clearInterval(timerRef.current);
    setIsTimerRunning(false);
    setTimer(600);
    setResendLimit(0);
  };

  return (
    <Modal
      close={() => {
        closeModal();
      }}
      {...state}
      title={locale.verifyThatItsYou}
      style={{
        width: "600px",
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <locale.Populate
          text={locale.enterTheCodeThatWasSentToYourEmail}
          items={[<b>{email}</b>]}
        />
      </div>

      <Field
        childrenStyle={{
          minWidth: "400px",
          maxWidth: "400px",
        }}
        {...fields.otp}
      >
        <TextField {...fields.otp} onChange={modifyField} />
      </Field>

      {isTimerRunning ? (
        <span>
          {<locale.Populate text={locale.resendCodeIn} items={[<b>{formatTime(timer)} </b>]} />}
        </span>
      ) : (
        <>
          {isTimerRunningFiveTimes ? (
            <span>
              {
                <locale.Populate
                  text={locale.resendCodeIn}
                  items={[<b>{formatTime(timerThreeTimes)} </b>]}
                />
              }
            </span>
          ) : (
            <span
              onClick={() => {
                resendOTP();
              }}
              style={{ color: "#753bbd", fontWeight: "500", textDecoration: "underline" }}
            >
              {locale.resend}
            </span>
          )}
        </>
      )}

      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              modifyForm({
                otp: {
                  value: "",
                  isDirty: false,
                },
              });
              closeModal();
            },
          },
          {
            loading: loading || loggingIn,
            disabled: !isFormSubmittable,
            text: locale.verify,
            onClick: () => {
              submitForm(handleSubmitOTP);
            },
          },
        ]}
      />
    </Modal>
  );
};

export default forwardRef(TwoFactorAuthOtpModal);
