import classNames from "classnames";
import React from "react";
import { Text } from "..";
import styles from "./field.module.scss";

const Field = ({
  children,
  childrenClassName,
  label,
  horizontal,
  className,
  message,
  error,
  labelPosition,
}) => {
  return (
    <div
      className={classNames(className, styles.container, {
        [`${styles.horizontal}`]: horizontal,
        [`${styles.labelLeft}`]: labelPosition === "left",
      })}
    >
      {label && <Text label>{label}</Text>}
      <div
        className={classNames(
          {
            [`${styles.formContainer}`]: horizontal,
          },
          childrenClassName
        )}
      >
        {children}
        {message && (
          <Text className={classNames(styles.message, { [`${styles.error}`]: error })}>
            {message}
          </Text>
        )}
      </div>
    </div>
  );
};

export default Field;
