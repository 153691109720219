import React from "react";
import styles from "./login.module.scss";
import { Path } from "enums";
import { Image, Footer } from "components/commons";
import classNames from "classnames";
import { Logo, GuyPresentingImage } from "images";

import { Route, Switch } from "react-router-dom";
import LoginForm from "./login.form";
import ForgotPasswordModule from "../forgot-password/forgot-password.module";
import ResetPasswordModule from "../reset-password/reset-password.module";

const LoginModule = (props) => {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.header)}>
        <Image src={Logo} className={styles.logoImage} />
      </div>

      <div className={styles.content}>
        <div className={styles.contentContainer}>
          <div className={styles.image}>
            <Image src={GuyPresentingImage} className={styles.contentImage} />
          </div>

          <Switch>
            <Route exact path={Path.Auth} render={() => <LoginForm {...props} />} />
            <Route exact path={Path.ForgotPassword} render={() => <ForgotPasswordModule />} />
            <Route exact path={Path.ResetPassword} render={() => <ResetPasswordModule />} />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginModule;
