export const checkIfObjectValuesAreEqual = (obj1, obj2) => {
  // sorting of keys must match, else this will not work
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const sortByKeyName = (key, string = true) => {
  return (a, b) => {
    if (a && b && a[key] && b[key]) {
      const first = string && a[key] ? a[key].toString().toLowerCase() : a[key];
      const second = string && b[key] ? b[key].toString().toLowerCase() : b[key];
      if (first < second) return -1;

      if (first > second) return 1;
    }

    return 0;
  };
};

export const parseCSVFile = async (file, columns = []) => {
  const fileContent = await file.text();
  const rows = fileContent.split("\n");
  const errors = [];
  const rowErrors = [];
  const errorType = {};
  let hasBlankRows = false;
  let hasValue = false;
  let invalidFormat = false;
  const items = rows
    .reverse()
    .filter((row) => {
      if (!row.replaceAll(",", "").trim() && !hasValue) {
        return false;
      }
      hasValue = true;
      return true;
    })
    .reverse()
    .filter((row, index) => {
      const r = row.replaceAll("\r", "").split(",");
      if (index === 0) {
        r.forEach((text, i) => {
          const row = i + 2;
          const columnText = columns[i]?.text;
          const isValid = text === columnText;
          if (!isValid) {
            invalidFormat = true;
            errors.push(`Column ${row} should be '${columnText}'`);
            rowErrors.push(`Invalid header at Column ${row}`);
          }
        });
      }
      return index !== 0;
    })
    .map((row, index) => {
      const r = row.replaceAll("\r", "").split(",");
      const obj = {};
      if (!r.length) {
        hasBlankRows = true;
      }
      r.forEach((text, i) => {
        obj[columns[i]?.key] = text;
      });
      const messages = [];
      r.forEach((text, i) => {
        if (!text.trim()) {
          hasBlankRows = true;
        }
        const row = index + 2;
        if (columns[i]?.validation) {
          const errorStatus = columns[i]?.validation(text, obj);
          if (errorStatus.error) {
            messages.push(errorStatus.message);
            if (errorStatus.errorType) {
              if (
                !errorType[errorStatus.errorType] ||
                !errorType[errorStatus.errorType].includes(row)
              ) {
                errorType[errorStatus.errorType] = errorType[errorStatus.errorType]
                  ? [...errorType[errorStatus.errorType], row]
                  : [row];
              }
            }
          }
        }
      });
      if (messages.length) {
        errors.push(`Row ${index + 1}: ${messages.join(", ")}`);
        rowErrors.push(index + 1);
        obj.error = true;
      }
      return { ...obj, rowNumber: index + 1 };
    });
  return {
    items,
    passed: items.filter((item) => !item.error),
    errors,
    rowErrors,
    errorType,
    hasBlankRows,
    invalidFormat,
  };
};

export const exportCSV = ({ rows, filename }) => {
  const csvContent = "data:text/csv;charset=utf-8," + rows.join("\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(link);

  link.click();
};
