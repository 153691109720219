import { Image, Modal, Title, ActionButton } from "components/commons";
import { CautionSign } from "images";
import React, { useState } from "react";
import locale from "localization";
import styles from "./deactivated-modal.module.scss";

const ErrorModal = ({ onClick, ...modal }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal {...modal}>
      <div className={styles.container}>
        <Image src={CautionSign} className={styles.image} />
        <Title small className={styles.title}>
          {locale.deactivatedFleetAccount}
        </Title>
      </div>
      <ActionButton
        center
        items={[
          {
            onClick: async () => {
              setLoading(true);
              try {
                await onClick();
                setLoading(false);
              } catch (e) {
                setLoading(false);
              }
            },
            text: locale.reload,
            loading,
            primary: true,
          },
        ]}
      />
    </Modal>
  );
};

export default ErrorModal;
