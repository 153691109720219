import { FleetContext } from "contexts";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { getMyFuelCodeTemplates } from "apis";
import { useApi, useModal, useMount } from "hooks";
import {
  ActionButton,
  Checkbox,
  DataTable,
  Intro,
  Modal,
  Pill,
  PopOverMenu,
  Product,
  Text,
} from "components/commons";
import locale from "localization";
import styles from "./add-fuel-code-modal.module.scss";
import { prettifyFuelCodeType, prettifyProduct } from "utils/pretty.utils";
import useSelectAll from "hooks/useSelectAll";
import { FuelCodesStatus, Products } from "enums";
import { formatVolume, prettifyFuelCodesStatus } from "utils";
import { pluralize } from "utils/text.utils";
import moment from "moment";

const FuelCodeTemplateList = ({
  close,
  onAdd,
  showAddTemplateModal,
  showEditTemplateModal,
  onDeleteTemplate,
}) => {
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const notifModal = useModal();
  const {
    request,
    loading,
    result = { count: 0, active: 0, fuelCodeTemplates: [] },
  } = useApi({
    api: getMyFuelCodeTemplates,
    params: {
      fleetId,
      page,
      perPage,
    },
    pageError: true,
  });

  const { isAll, toggleSelectAll, selected, toggleItem } = useSelectAll({
    total: result.fuelCodeTemplates.length,
    items: result.fuelCodeTemplates,
  });

  const fetchFuelCodes = useCallback(
    (params) => {
      request({
        page,
        perPage,
        ...params,
      });
    },
    [page, perPage, request]
  );

  useMount(() => {
    request();
  });

  const preparedMyFuelCodeTemplatesData = useMemo(() => {
    const { fuelCodeTemplates } = result;
    if (fuelCodeTemplates && fuelCodeTemplates.length > 0) {
      const preparedData = fuelCodeTemplates.map((d, index) => {
        const map = new Map();
        let data = { idx: index, data: d };

        map.set(
          "checkBox",
          <div>
            <Checkbox
              name={"templateId" + index}
              onChange={() => {
                toggleItem(index);
              }}
              contents={data}
              value={selected.includes(index)}
            />
          </div>
        );
        map.set("fuelCode", d.fuelCode);
        map.set("fuelCodeType", prettifyFuelCodeType(d.fuelCodeType));
        map.set(
          "assignedDriver",
          d.driver != null ? (
            <div>
              <Text>{`${d.driver.firstName} ${d.driver.lastName}`}</Text>
            </div>
          ) : (
            <Text italic>{locale.any}</Text>
          )
        );
        map.set(
          "assignedVehicle",
          d.vehicle != null ? (
            <div>
              <Text>{d.vehicle.plateNumber || ""}</Text>
            </div>
          ) : (
            <Text italic>{locale.any}</Text>
          )
        );
        map.set(
          "product",
          <Product
            grass={d.productCode === Products.Gas91}
            salsa={d.productCode === Products.Gas95}
            deepBlue={d.productCode === Products.Gas97}
            cheddar={d.productCode === Products.Diesel}
          >
            {prettifyProduct(d.productCode)}
          </Product>
        );
        map.set("volumeRedeemLimit", formatVolume(d.volumeRedeemLimit));
        map.set("duration", d.durationDays);
        map.set("maxUsage", d.maxUsage === 1 ? "Yes" : "No");
        map.set("sendSms", d.sendSms ? locale.yes : locale.no);
        map.set("autoRenew", d.autoRenew ? locale.yes : locale.no);
        map.set(
          "status",
          <Pill
            sky={d.status === FuelCodesStatus.Scheduled}
            deepRed={d.status === FuelCodesStatus.Deactivated}
            grass={d.status === FuelCodesStatus.Active}
            cement={d.status === FuelCodesStatus.Expired}
          >
            {prettifyFuelCodesStatus(d.status)}
          </Pill>
        );
        map.set(
          "action",
          <PopOverMenu
            options={[
              {
                content: locale.edit,
                onClick: () => {
                  showEditTemplateModal(d, () => {
                    fetchFuelCodes({ page: 1 });
                  });
                },
              },
              {
                content: locale.delete,
                onClick: () => {
                  onDeleteTemplate(d.fuelCodeTemplateId, () => {
                    fetchFuelCodes({ page: 1 });
                  });
                },
              },
            ]}
          />
        );
        return map;
      });
      return preparedData;
    }
    return [];
  }, [result, selected, toggleItem, onDeleteTemplate, showEditTemplateModal, fetchFuelCodes]);

  const columns = [
    {
      key: "checkBox",
      text: (
        <div>
          <Checkbox name={"selectAll"} onChange={toggleSelectAll} value={isAll} />
        </div>
      ),
    },
    { key: "fuelCodeType", text: locale.fuelCodeType },
    { key: "assignedDriver", text: locale.assignedDriver },
    { key: "assignedVehicle", text: locale.assignedVehicle },
    { key: "product", text: locale.product },
    { key: "volumeRedeemLimit", text: locale.volumeRedeemLimit },
    { key: "duration", text: locale.durationInDays },
    { key: "sendSms", text: locale.sendSms },
    { key: "maxUsage", text: locale.oneTimeUse },
    { key: "autoRenew", text: locale.autoRenew },
    { key: "action", text: locale.action },
  ];

  return (
    <div>
      <div>
        <Intro
          title={locale.fuelCodeTemplates}
          actionText={locale.addTemplate}
          actionOnClick={() => {
            showAddTemplateModal(() => {
              fetchFuelCodes({ page: 1 });
            });
          }}
          actionOutlined={true}
          rounded={false}
          thin={true}
        />

        <div className={styles.table}>
          <DataTable
            loading={loading}
            page={page}
            pageSize={perPage}
            columns={columns}
            data={preparedMyFuelCodeTemplatesData}
            dataCount={result.count}
            onChangePage={(page) => {
              setPage(page);
              fetchFuelCodes({
                page,
              });
            }}
            errorTitle={locale.emptyTemplate}
            errorMessage={locale.emptyTemplateMessage}
            errorMessageSub={" "}
            onChangePageSize={(value) => {
              setPerPage(value);
              setPage(1);
              fetchFuelCodes({
                page: 1,
                perPage: value,
              });
            }}
          />
        </div>

        <Modal {...notifModal}>
          {notifModal.content}
          <ActionButton
            right
            items={[
              {
                onClick: () => {
                  close();
                },
                text: locale.cancel,
              },
              {
                onClick: () => {
                  notifModal.onSubmit();
                  notifModal.close();
                },
                text: notifModal.submitText,
              },
            ]}
          />
        </Modal>
        <ActionButton
          right
          items={[
            {
              onClick: () => {
                close();
              },
              text: locale.cancel,
            },
            {
              onClick: () => {
                notifModal.show({
                  title: `${locale.generalFuelCodesCapitalized}?`,
                  content: (
                    <div>
                      <locale.Populate
                        text={locale.youAreAboutToGenerateFuelCodeFromTemplate}
                        items={[
                          <span>
                            {selected.length}{" "}
                            {pluralize(
                              selected.length,
                              locale.fuelCodeLowerCase,
                              locale.fuelCodesLowerCase
                            )}
                          </span>,
                        ]}
                      />
                    </div>
                  ),
                  onSubmit: () => {
                    const items = [];
                    result.fuelCodeTemplates.forEach((template, index) => {
                      if (selected.includes(index)) {
                        items.push({
                          ...template,
                          period: {
                            startDate: moment().startOf("day"),
                            endDate: moment().add(template.durationDays - 1, "days").endOf("day"),
                          },
                          literVolume: template.volumeRedeemLimit,
                          sendAsSms: template.sendSms,
                          limitOneTime: template.maxUsage === 1,
                          driverName: `${template.driver?.firstName || ""} ${
                            template.driver?.lastName || ""
                          }`.trim(),
                          driverId: template.driver?.driverId,
                          vehicleName: template.vehicle
                            ? `${template.vehicle?.model} (${template.vehicle?.plateNumber})`.trim()
                            : "",
                          vehicleId: template.vehicle?.vehicleId,
                          driverMobileNumber: template.driver?.mobileNumber,
                        });
                      }
                    });

                    onAdd(items);
                  },
                  submitText: locale.generalFuelCodesCapitalized,
                });
              },
              disabled: !selected.length,
              text: locale.useTemplate,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default FuelCodeTemplateList;
