import { Modal, Image, Title, Text, ActionButton } from "components/commons";
import React from "react";
import { SuccessTransaction, FailedTransaction } from "images";
import locale from "localization";
import styles from "./result-buy-fuel.module.scss";
import { formatAmount, formatVolume } from "utils";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { Path } from "enums";
import { prettifyProduct } from "utils/pretty.utils";

const ResultBuyFuel = ({
  active,
  close,
  clear,
  purchase,
  status = "success",
  buyFuelId,
  productCode,
  loading,
  literVolume,
  total,
  message
}) => {
  const history = useHistory();

  return (
    <div>
      <Modal className={styles.modal} active={active} close={close}>
        <div className={styles.status}>
          {status === "success" ? (
            <>
              <Image src={SuccessTransaction} />
              <Title medium className={styles.title}>{`${locale.success}!`}</Title>
              <Text>{locale.paymentConfirmed}</Text>
            </>
          ) : (
            <>
              <Image src={FailedTransaction} />
              <Title medium className={styles.title}>{`${locale.buyFuelFailed}!`}</Title>
              <Text>{message}</Text>
            </>
          )}
        </div>
        <div className={styles.table}>
          {status === "success" && (
            <div className={styles.item}>
              <div className={styles.label}>{locale.referenceId}</div>
              <div>
                <Text strong>{buyFuelId}</Text>
              </div>
            </div>
          )}
          <div className={styles.item}>
            <div className={classNames(styles.label)}>{locale.productType}</div>
            <div>
              <Text strong>{prettifyProduct(productCode.value)}</Text>
            </div>
          </div>
          <div className={styles.item}>
            <div className={classNames(styles.label)}>{locale.volume}</div>
            <div>
              <Text strong>{formatVolume(literVolume)}</Text>
            </div>
          </div>
          <div className={styles.item}>
            <div className={classNames(styles.label)}>{locale.amount}</div>
            <div>
              <Text strong>{formatAmount(total)}</Text>
            </div>
          </div>
        </div>
        <ActionButton
          right
          items={
            status === "success"
              ? [
                  {
                    text: locale.goToBuyFuelHistory,
                    onClick: () => {
                      history.push(Path.FuelHistory);
                    },
                  },
                  {
                    text: locale.buyFuelAgain,
                    onClick: () => {
                      clear();
                    },
                  },
                ]
              : [
                  {
                    text: locale.goToBuyFuelHistory,
                    onClick: () => {
                      history.push(Path.FuelHistory);
                    },
                  },
                  {
                    text: locale.cancel,
                    primary: false,
                    onClick: () => {
                      close();
                    },
                  },
                  {
                    text: locale.tryAgain,
                    primary: true,
                    onClick: () => {
                      purchase();
                    },
                    loading,
                  },
                ]
          }
        />
      </Modal>
    </div>
  );
};

export default ResultBuyFuel;
