const env = {
  ID: "staging",
  API_URL: "https://staging.api.locq.com",
  WEB_SOCKET_URL: "wss://5jhgzsdcke.execute-api.ap-southeast-1.amazonaws.com/staging",
  PAYMAYA_API_URL: "https://pg-sandbox.paymaya.com/payments/v1/payment-tokens",
  PAYMAYA_PUBLIC_KEY: "pk-sUHFanNgBk5rKaAVQNew49Lk1tyccsqMsrx1mm5Vm9s",
  SEGMENT_WRITE_KEY: "wqAxIWUEX73CzsY2e0beguS405RCO62v",
  GOOGLE_MAP_API_KEY: "AIzaSyDLU0STkUo6d4ercAIxzhpIIN6pbf9xFCs",
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyCK-35X65kMYzHcOi8eVBJ4IU2N6HipG0g",
    authDomain: "pricelocq-business-dev.firebaseapp.com",
    databaseURL: "https://pricelocq-business-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "pricelocq-business-dev",
    storageBucket: "pricelocq-business-dev.appspot.com",
    messagingSenderId: "972496080406",
    appId: "1:972496080406:web:97f238c01b9e59d1e2af37",
  },
};

export default env;
