export const initialState = (state = {}) => {
  const {
    extremelyLike = {
      value: "",
    },
    veryLikely = {
      value: "",
    },
    someWhatLikely = {
      value: "",
    },
    notSoLikely = {
      value: "",
    },
    notAtAllLikely = {
      value: "",
    },
  } = state;
  return {
    extremelyLike: {
      name: "extremelyLike",
      ...extremelyLike,
    },
    veryLikely: {
      name: "veryLikely",
      ...veryLikely,
    },
    someWhatLikely: {
      name: "someWhatLikely",
      ...someWhatLikely,
    },
    notSoLikely: {
      name: "notSoLikely",
      ...notSoLikely,
    },
    notAtAllLikely: {
      name: "notAtAllLikely",
      ...notAtAllLikely,
    },
  };
};
