import axios from "axios";
import { handleErrorResponse } from "../utils/api.utils";
import env from "../environments/env";

export const createPaymentToken = async ({ ...body }) => {
  try {
    const res = await axios.post(env.PAYMAYA_API_URL, body, {
      headers: { Authorization: `Basic ${btoa(`${env.PAYMAYA_PUBLIC_KEY}:`)}` },
    });
    return res.data;
  } catch (err) {
    throw handleErrorResponse(err);
  }
};
